import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export class ProductCardIcon extends Component {
  render() {
    return (
      <div className="card-product">
          <NavLink
              to={this.props.link}
            >
            {this.props.name} 
          </NavLink>
          <div>
            {this.props.description}
          </div>
          <i className={this.props.icon+" icon-middle"} />
      </div>
    );
  }
}

export default ProductCardIcon;
