import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

const restLink_cluster = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const cluster_client = new ApolloClient({
  link: restLink_cluster,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_CLUSTER = gql`
{
  cluster_collection{
    clusters{
      id
      title
    }
  }
}
`;



class ClusterList extends Component {

  state = {
    search: '',
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC"
  }

  toggleOrder = (event) => {
    this.setState({
      order: (this.state.order == 'DESC'? 'ASC' : 'DESC')
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value
    });

  }

  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={"Zonas de Disponibilidade"}
                cluster="Gerenciar"
                ctTableFullWidth
                ctTableResponsive
                content={

                  <Table striped hover>
                    
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={cluster_client}
                          query={GET_CLUSTER}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.cluster_collection) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                              return data.cluster_collection.clusters.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                
                                <td>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                        
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ClusterList;
