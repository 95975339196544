import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

const restLink_costCustom = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const costCustom_client = new ApolloClient({
  link: restLink_costCustom,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateCostCustom($id: Int!,$account_id: Int!,$product_id: Int!, $cost: String!, $description: String!){
    createOrUpdateCostCustom(id:$id, input:{ account_id:$account_id, product_id:$product_id, cost:$cost, description:$description}){
      id
    }
  }
`;

const GET_COSTCUSTOM = gql`
  query getCostCustom($costCustom_id: Int!){
    costCustom(id:$costCustom_id){
      id
      product{
        value:id
        label:title
      }
      cost_format
      description
    }
  }`;


const GET_PRODUCTS = gql`{
  product_collection{
    products{
      value:id
      label:title
    }
  }
}`;


class CostCustomItem extends Component {

  costCustom_id;
  costCustom_edit;
  parent_id;
  back_button;

  state = {
    alertInfo: ""
  };

  constructor() {
    super();

    this.start_is_blocked = true;
    
  }



  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    this.costCustom_id = (this.props.match.params.id ? this.props.match.params.id : 0);


    return (


      <Query
      client={costCustom_client}
      query={GET_COSTCUSTOM}
      variables={{costCustom_id:this.costCustom_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.costCustom_edit = data.costCustom;

        return (

      <Mutation mutation={ADD_TODO} client={costCustom_client}>
      {(createOrUpdateCostCustom, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

                <a
                  href='javascript:history.go(-1)'
                  className="btn btn-info btn-fill"
                >
                VOLTAR
              </a>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Conta"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateCostCustom({variables: { 
                      account_id: this.props.match.params.account_id, 
                      id: this.costCustom_id, 
                      product_id: e.target.elements.product_id.value,
                      description: e.target.elements.description.value,
                      cost: e.target.elements.cost.value
                    } });
                  }}
                  >

                  <FormGroup>
                    <ControlLabel>Produto</ControlLabel>
                    <div>
                      <Query
                          client={costCustom_client}
                          query={GET_PRODUCTS}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                            return (
                              <Select
                                required={true}
                                name="product_id"
                                options={data.product_collection ? data.product_collection.products : []}
                                className="basic-select"
                                classNamePrefix="select"
                                defaultValue={this.costCustom_edit && this.costCustom_edit.product ? this.costCustom_edit.product : ""}
                              />
                              );
                            }}
                      </Query>
                    </div>
                  </FormGroup>

                  
                  <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          name: "cost",
                          label: "Valor customizado (R$)",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Valor customizado (R$)",
                          defaultValue: (this.costCustom_edit && this.costCustom_edit.cost_format ? this.costCustom_edit.cost_format : "0,00")
                        }
                      ]}
                    />



                  <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          name: "description",
                          label: "Descrição",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Descrição",
                          defaultValue: (this.costCustom_edit && this.costCustom_edit.description ? this.costCustom_edit.description : "")
                        }
                      ]}
                    />

                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default CostCustomItem;
