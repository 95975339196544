import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel,
  Table
} from "react-bootstrap";

import { Navbar } from "react-bootstrap";


import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import InputMask from 'react-input-mask';

import { ProductCard } from "../../components/ProductCard/ProductCard.jsx";


import logo_thumb from "../../assets/img/logo-thumb.png";
import background1 from "../../assets/img/background1.png";

const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});


const GET_AUTH_USER = gql`
{
  get_authenticated_user {
    id
    mail
    account {
      id
      name
    }
  }
}
`;


const REGISTER_STEP_3 = gql`
mutation createAccountByUserStep3( $code: String!){
  createAccountByUserStep3( code:$code){
      id
      token
      msg_error
    }
  }
`;


// ######### Fazer o fluxo de recuperar a senha


class RegisterStep3 extends Component {
  
  state = {
    code: "",
    msg: "",
    msg_type: "info"
  }


  register_step_3 = async () => {
    
      const mutationReturn = (await account_client.mutate({
        variables: {
          code: this.state.code
        },
        mutation: REGISTER_STEP_3,
        fetchPolicy: 'no-cache'
      })).data.createAccountByUserStep3;


      if(mutationReturn && typeof mutationReturn.token != "undefined" && mutationReturn.token !== null && mutationReturn.token !== "") {
          document.cookie = "token_user="+mutationReturn.token;// Grava o coookie
          this.setState({
            msg_type: "info",
            msg: "Registro feito com sucesso, aguarde, você será redirecionado."
          });
          window.location.replace('/#/registro-passo-5');
          window.location.reload();
      } else if(mutationReturn && typeof mutationReturn.msg_error != "undefined") {
        this.setState({
          msg_type: "warning",
          msg: mutationReturn.msg_error
        });
      }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.register_step_3();
      
  }

  onChange_code = (event) => {
    this.setState({code: event.target.value});
  }

  render() {


    return (
      <div>
      <div className="content content-login">
      <Grid fluid>

      
      <Query
      client={account_client}
      fetchPolicy='no-cache'
      query={GET_AUTH_USER}
      variables={{dam_id:this.dam_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro. Atualize a página.</div>;
           

        var user = data.get_authenticated_user;
        return (

                <div>
                    <h1 className="title-center">Confirme sua identidade</h1>

                  <div className="login-box">
                    <form 
                        onSubmit={e => {
                          this.handleSubmit(e);
                          e.preventDefault();
                        }}
                        >
                          <Row>
                            <Col md={12}>
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    onChange:this.onChange_code,
                                    name: "code",
                                    label: "Digite o código que enviamos para: "+user.mail,
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Código de confirmação",
                                    defaultValue: ""
                                  }
                                ]}
                              />

                                <Button className="btn-full btn-fill btn btn-info" type="submit">
                                  CONFIRMAR CONTA
                                </Button>

                              </Col>

                        

                          {
                            this.state.msg &&
                              <Col md={12}>
                                <Alert bsStyle={this.state.msg_type}>
                                  {this.state.msg}
                                </Alert>
                              </Col>
                          }
                      </Row>
                          
                  </form>
                </div>
              </div>

        );
        }}
        </Query>

                 
        </Grid>
      </div>
      </div>
    );
  }
}

export default RegisterStep3;
