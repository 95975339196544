import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";




class HeaderLinks extends Component {
  render() {

    const notification = (
      <div>
        <i className="pe-7s-bell" />
        <b className="caret" />
        <span className="notification">1</span>
        <p className="hidden-lg hidden-md">Notificações</p>
      </div>
    );
   
    return (
      <div>
        <Nav>

        
        </Nav>
            
                  <Nav pullRight>
                    <NavDropdown
                      eventKey={2}
                      title={notification}
                      noCaret
                      id="basic-nav-dropdown"
                    >
                      <MenuItem eventKey={2.1}>Seja Bem Vindo!</MenuItem>
                    </NavDropdown>
                    <NavDropdown
                      title={this.props.user.first_name}
                      id="basic-nav-dropdown-right"
                    >
                      <NavItem 
                        data-gtm-type="click" 
                        data-gtm-clicktype="menu" 
                        data-gtm-name="Admin"
                        key={1} 
                        className="nav-link"
                        activeClassName="active"
                        href={"/#/home"}>
                          Primeira Página
                      </NavItem>
                      <MenuItem divider />
                      <NavItem 
                        data-gtm-type="click" 
                        data-gtm-clicktype="menu" 
                        data-gtm-name="Admin"
                        key={1} 
                        className="nav-link"
                        activeClassName="active"
                        href={"/#/my-account"}>
                          Minha Conta
                      </NavItem>
                      <NavItem 
                        data-gtm-type="click" 
                        data-gtm-clicktype="menu" 
                        data-gtm-name="Admin"
                        key={1} 
                        className="nav-link"
                        activeClassName="active"
                        href={"/#/dashboard"}>
                          Meu Faturamento
                      </NavItem>
                      <MenuItem divider />
                      <NavItem 
                          data-gtm-type="click" 
                          data-gtm-clicktype="menu" 
                          data-gtm-name="Admin"
                          key={2} 
                          className="nav-link"
                          activeClassName="active"
                          href={"/api/v1/account/logout"}>
                            Encerrar Sessão
                        </NavItem>
                    </NavDropdown>
                    <NavDropdown
                      title="Suporte"
                      id="basic-nav-dropdown-right"
                    >
                      <NavItem 
                        data-gtm-type="click" 
                        data-gtm-clicktype="menu" 
                        data-gtm-name="Admin"
                        key={1} 
                        className="nav-link"
                        activeClassName="active"
                        href={"/#/contact"}>
                          Enviar uma solicitação
                      </NavItem>
                      <NavItem 
                        data-gtm-type="click" 
                        data-gtm-clicktype="menu" 
                        data-gtm-name="Admin"
                        key={1} 
                        className="nav-link"
                        activeClassName="active"
                        href={"/#/new-service"}>
                          Adicionar um novo serviço
                      </NavItem>
                      <NavItem 
                        data-gtm-type="click" 
                        data-gtm-clicktype="menu" 
                        data-gtm-name="Admin"
                        key={1} 
                        className="nav-link"
                        activeClassName="active"
                        href={"/#/new-service"}>
                          Solicitar aumento de recursos
                      </NavItem>
                    </NavDropdown>
                  </Nav>
                
          
      </div>
    );
  }
}

export default HeaderLinks;
