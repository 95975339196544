import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";

const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: "include",
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({
  uri: "/api/v1/cloud/",
  credentials: "include",
});
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

const GET_COSTS = gql`
  query getCosts($year: Int!, $month: Int!) {
    cost_collection(year: $year, month: $month) {
      costs {
        id
        description
        product {
          id
          title
        }
        cost_unit_format
        num
        cost
        cost_format
        details {
          id
          day
          month
          year
          description
          product {
            id
            title
          }
          cost_unit_format
          num
          cost_format
        }
      }
    }
  }
`;

const GET_VM_COSTS = gql`
  query cost_vm_collection($year: Int!, $month: Int!) {
    cost_vm_collection(year: $year, month: $month) {
      costs {
        num_days
        vm_title
        vm_ip
        num_1
        cost_1
        cost_1_format
        cost_uniq_1_format
        num_2
        cost_2
        cost_2_format
        cost_uniq_2_format
        num_3
        cost_3
        cost_3_format
        cost_uniq_3_format
        cost_total
        cost_total_format
        cost_forecast
        cost_forecast_format
        details {
          day
          month
          year
          vm_title
          vm_power_state
          vm_ip
          num_1
          cost_1_format
          cost_uniq_1_format
          num_2
          cost_2_format
          cost_uniq_2_format
          num_3
          cost_3_format
          cost_uniq_3_format
          cost_total
          cost_total_format
        }
      }
    }
  }
`;

const GET_COMBO = gql`
  {
    combo_collection {
      combos {
        id
        title
        cost_format
        comboHasProducts {
          id
          product {
            id
            title
          }
          num
        }
      }
    }
  }
`;

const GET_COST_TOTAL = gql`
  query cost_total($year: Int!, $month: Int!) {
    cost_total(year: $year, month: $month) {
      cost
      cost_format
      cost_forecast_format
    }
  }
`;

// Commvault

const GET_COMM_COST_STORAGE = gql`
  {
    commCostStorage {
      commCostStorages {
        copy {
          id
          title
          type
          size
          oldestJobStartTime_format
        }
        subclient {
          id
          title
          type
          application_id
        }
        storagePolicy {
          id
          title
        }
        cost
        cost_format
        cost_unit
        cost_unit_format
      }
    }
  }
`;

const GET_COMM_COST_AGENT = gql`
  {
    commCostAgent {
      commCostAgents {
        agent {
          id
          title
          type
          application_id
        }
        contents {
          id
          path
          name
          display_name
        }
      }
    }
  }
`;

const GET_COMM_COST_TOTAL = gql`
  {
    commCostTotal {
      commCostTotal {
        title
        type
        num
        cost_unit_format
        cost_format
      }
    }
  }
`;

class Dashboard extends Component {
  state = {
    show_details: [],
    cur_month_year: false,
    graph_page_views: {
      dates_page_views: {
        labels: [],
        series: [[]],
      },
    },
  };

  onChange_calendar_start = (dateCalendar_start) =>
    this.setState({ dateCalendar_start });
  onChange_calendar_end = (dateCalendar_end) =>
    this.setState({ dateCalendar_end });

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  componentDidMount() {
    this.getReports();
  }

  getReports = async () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    var labels = [];
    var series = [];
    var max = 0;
  };

  changeMonth = (e) => {
    this.setState((state) => ({
      ...state,
      cur_month_year: e.value,
    }));
  };

  changeDetails(id) {
    if (typeof this.state["show_details_" + id] == "undefined") {
      var show = true;
    } else {
      var show = !this.state["show_details_" + id];
    }

    this.setState((state) => ({
      ...state,
      ["show_details_" + id]: show,
    }));
  }

  render() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    var total_cost = 0;

    return (
      <Query
        client={account_client}
        query={gql`
          {
            get_authenticated_user {
              id
              first_name
              account {
                id
              }
            }
          }
        `}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Olá</div>;
          if (error) return <div>Erro :(</div>;

          var account = data.account;

          return (
            <Query
              client={cloud_client}
              query={gql`
                {
                  cost_months {
                    months {
                      label
                      value
                    }
                  }
                }
              `}
              fetchPolicy="no-cache"
            >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                var months = data.cost_months;
                if (this.state.cur_month_year) {
                  var cur_month_year = this.state.cur_month_year;
                } else if (months && months.months.length) {
                  var cur_month_year = months.months[0].value;
                } else {
                  var cur_month_year = false;
                }

                if (cur_month_year) {
                  var cur_month_year_split = cur_month_year.split("-");
                  var cur_month = cur_month_year_split[0];
                  var cur_year = cur_month_year_split[1];
                } else {
                  var cur_month = 0;
                  var cur_year = 0;
                }

                return (
                  <div className="content">
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <Card
                            title="Fatura"
                            category=""
                            stats="Atualizado"
                            statsIcon="fa fa-history"
                            content={
                              <div className="table-full-width">
                                <a href="/#/dashboard" className="float-right">
                                  Voltar para fatura
                                </a>
                                <div className="both"></div>
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Select
                                          name="profile_id"
                                          options={months ? months.months : []}
                                          className="basic-select"
                                          classNamePrefix="select"
                                          isClearable={true}
                                          defaultValue={
                                            months && months.months.length
                                              ? months.months[0]
                                              : {}
                                          }
                                          onChange={this.changeMonth}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <br />

                                <table className="table table-billing">
                                  <Query
                                    client={cloud_client}
                                    query={GET_COMBO}
                                    fetchPolicy="no-cache"
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading) return <div>Olá</div>;
                                      if (error) return <div>Erro :(</div>;

                                      return data.combo_collection.combos.map(
                                        (combo) => (
                                          <tbody key={combo.id}>
                                            <tr>
                                              <td colSpan={4}>
                                                <strong>{combo.title}</strong>
                                              </td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            {combo.comboHasProducts.map(
                                              (comboProduct) => (
                                                <tr key={combo.id}>
                                                  <td></td>
                                                  <td>
                                                    {comboProduct.product.title}
                                                  </td>
                                                  <td>{comboProduct.num}</td>
                                                  <td colSpan={5}></td>
                                                </tr>
                                              )
                                            )}
                                            <tr key={combo.id}>
                                              <td></td>
                                              <td>
                                                <strong>Valor</strong>
                                              </td>
                                              <td>R$ {combo.cost_format}</td>
                                              <td colSpan={5}></td>
                                            </tr>
                                          </tbody>
                                        )
                                      );
                                    }}
                                  </Query>
                                </table>

                                <table className="table table-billing">
                                  <thead>
                                    <th colSpan={3}>Instância</th>
                                    <th>IP</th>
                                    <th>vCPU</th>
                                    <th>Memory</th>
                                    <th>Disk</th>
                                    <th>Total parcial</th>
                                    <th width={100}>Previsão do mês</th>
                                    <th></th>
                                  </thead>

                                  <Query
                                    client={cloud_client}
                                    query={GET_VM_COSTS}
                                    variables={{
                                      year: cur_year,
                                      month: cur_month,
                                    }}
                                    fetchPolicy="no-cache"
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return (
                                          <tr>
                                            <td>Carregando...</td>
                                          </tr>
                                        );
                                      if (error)
                                        return (
                                          <tr>
                                            <td>Erro :(x</td>
                                          </tr>
                                        );

                                      if (
                                        data.cost_vm_collection.costs.length ==
                                        0
                                      ) {
                                        return (
                                          <tr>
                                            <td>(Vazio)</td>
                                          </tr>
                                        );
                                      }

                                      var subtotal_vcpu = 0;
                                      var subtotal_num_vcpu = 0;

                                      var subtotal_memory = 0;
                                      var subtotal_num_memory = 0;

                                      var subtotal_disk = 0;
                                      var subtotal_num_disk = 0;

                                      var subtotal_cost = 0;
                                      var subtotal_forecast = 0;

                                      data.cost_vm_collection.costs.map(
                                        (item) => {
                                          subtotal_vcpu += parseFloat(
                                            item.cost_1
                                          );
                                          subtotal_num_vcpu += parseInt(
                                            item.num_1
                                          );

                                          subtotal_memory += parseFloat(
                                            item.cost_2
                                          );
                                          subtotal_num_memory += parseInt(
                                            item.num_2
                                          );

                                          subtotal_disk += parseFloat(
                                            item.cost_3
                                          );
                                          subtotal_num_disk += parseInt(
                                            item.num_3
                                          );
                                          if (
                                            item &&
                                            typeof item.cost_total !==
                                              "undefined"
                                          ) {
                                            subtotal_cost += parseFloat(
                                              item.cost_total
                                            );
                                          }

                                          subtotal_forecast += parseFloat(
                                            item.cost_forecast
                                          );
                                        }
                                      );

                                      return (
                                        <React.Fragment>
                                          {data.cost_vm_collection.costs.map(
                                            (item) => (
                                              <tbody key={item.id}>
                                                <tr>
                                                  <td colSpan={3}>
                                                    {item.vm_title}
                                                  </td>
                                                  <td>{item.vm_ip}</td>
                                                  <td>
                                                    {item.num_1}
                                                    <br />
                                                    R$ {item.cost_1_format}
                                                  </td>
                                                  <td>
                                                    {item.num_2} GB
                                                    <br />
                                                    R$ {item.cost_2_format}
                                                  </td>
                                                  <td>
                                                    {item.num_3} GB
                                                    <br />
                                                    R$ {item.cost_3_format}
                                                  </td>
                                                  <td>
                                                    R$ {item.cost_total_format}
                                                  </td>
                                                  <td>
                                                    R${" "}
                                                    {item.cost_forecast_format}
                                                  </td>
                                                  <td width={100}>
                                                    {item.details &&
                                                      item.details.length && (
                                                        <a
                                                          className="btn btn-info"
                                                          onClick={() =>
                                                            this.changeDetails(
                                                              "vm_" +
                                                                item.vm_title
                                                            )
                                                          }
                                                        >
                                                          Detalhe diário
                                                        </a>
                                                      )}
                                                  </td>
                                                </tr>
                                                {typeof this.state[
                                                  "show_details_vm_" +
                                                    item.vm_title
                                                ] !== "undefined" &&
                                                  this.state[
                                                    "show_details_vm_" +
                                                      item.vm_title
                                                  ] &&
                                                  item.details.map(
                                                    (sub_item) => (
                                                      <tr>
                                                        <td> - </td>
                                                        <td>
                                                          <div
                                                            className={
                                                              sub_item.vm_power_state ==
                                                              "OFF"
                                                                ? "color-red"
                                                                : sub_item.vm_power_state ==
                                                                  "PAUSED"
                                                                ? "color-orange"
                                                                : "color-green"
                                                            }
                                                          >
                                                            {
                                                              sub_item.vm_power_state
                                                            }
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {sub_item.day}/
                                                          {sub_item.month}/
                                                          {sub_item.year}
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                          {sub_item.num_1}
                                                          <br />
                                                          R${" "}
                                                          {
                                                            sub_item.cost_1_format
                                                          }
                                                        </td>
                                                        <td>
                                                          {sub_item.num_2}
                                                          <br />
                                                          R${" "}
                                                          {
                                                            sub_item.cost_2_format
                                                          }
                                                        </td>
                                                        <td>
                                                          {sub_item.num_3}
                                                          <br />
                                                          R${" "}
                                                          {
                                                            sub_item.cost_3_format
                                                          }
                                                        </td>
                                                        <td>
                                                          R${" "}
                                                          {
                                                            sub_item.cost_total_format
                                                          }
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                      </tr>
                                                    )
                                                  )}
                                              </tbody>
                                            )
                                          )}
                                          <tbody>
                                            <tr>
                                              <td colSpan={3}>Subtotal</td>
                                              <td></td>
                                              <td>{subtotal_num_vcpu}</td>
                                              <td>{subtotal_num_memory} GB</td>
                                              <td>{subtotal_num_disk} GB</td>
                                              <td>
                                                {subtotal_cost.toLocaleString(
                                                  "pt-br",
                                                  {
                                                    style: "currency",
                                                    currency: "BRL",
                                                  }
                                                )}
                                              </td>
                                              <td>
                                                {subtotal_forecast.toLocaleString(
                                                  "pt-br",
                                                  {
                                                    style: "currency",
                                                    currency: "BRL",
                                                  }
                                                )}
                                              </td>
                                              <td width={100}></td>
                                            </tr>
                                          </tbody>
                                        </React.Fragment>
                                      );
                                    }}
                                  </Query>
                                  <tbody>
                                    <tr>
                                      <th colSpan={3}></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </tbody>
                                </table>

                                <table className="table table-billing">
                                  <thead>
                                    <th colSpan={2}>Produto</th>
                                    <th>Quantidade</th>
                                    <th>Valor Unitário</th>
                                    <th width={100}>Total Item</th>
                                  </thead>
                                  <tbody>
                                    <Query
                                      client={cloud_client}
                                      query={GET_COSTS}
                                      variables={{
                                        year: cur_year,
                                        month: cur_month,
                                      }}
                                      fetchPolicy="no-cache"
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading)
                                          return (
                                            <tr>
                                              <td>Carregando...</td>
                                            </tr>
                                          );
                                        if (error)
                                          return (
                                            <tr>
                                              <td>Erro :(x</td>
                                            </tr>
                                          );

                                        if (
                                          data.cost_collection.costs.length == 0
                                        ) {
                                          return (
                                            <tr>
                                              <td>(Vazio)</td>
                                            </tr>
                                          );
                                        }

                                        var subtotal_products = 0;
                                        data.cost_collection.costs.map(
                                          (item) => {
                                            subtotal_products += parseFloat(
                                              item.cost
                                            );
                                          }
                                        );

                                        return (
                                          <React.Fragment>
                                            {data.cost_collection.costs.map(
                                              (item) => (
                                                <React.Fragment>
                                                  <tr key={item.id}>
                                                    <td>
                                                      {item.product.title}
                                                    </td>
                                                    <td>{item.description}</td>
                                                    <td>{item.num}</td>
                                                    <td>
                                                      R$ {item.cost_unit_format}
                                                    </td>
                                                    <td>
                                                      R$ {item.cost_format}
                                                    </td>
                                                    <td width={100}>
                                                      <a
                                                        className="btn btn-info"
                                                        onClick={() =>
                                                          this.changeDetails(
                                                            "product_" +
                                                              item.description
                                                          )
                                                        }
                                                      >
                                                        Detalhe diário
                                                      </a>
                                                    </td>
                                                  </tr>
                                                  {typeof this.state[
                                                    "show_details_product_" +
                                                      item.description
                                                  ] !== "undefined" &&
                                                    this.state[
                                                      "show_details_product_" +
                                                        item.description
                                                    ] &&
                                                    item.details.map(
                                                      (sub_item) => (
                                                        <tr>
                                                          <td> - </td>
                                                          <td colSpan={7}>
                                                            {sub_item.day}/
                                                            {sub_item.month}/
                                                            {sub_item.year}
                                                          </td>
                                                          <td>
                                                            {item.description}
                                                          </td>
                                                          <td>{item.num}</td>
                                                          <td>
                                                            R${" "}
                                                            {
                                                              item.cost_unit_format
                                                            }
                                                          </td>
                                                          <td>
                                                            R${" "}
                                                            {item.cost_format}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                </React.Fragment>
                                              )
                                            )}
                                            {
                                              <tr>
                                                <td>Subtotal</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                  {subtotal_products.toLocaleString(
                                                    "pt-br",
                                                    {
                                                      style: "currency",
                                                      currency: "BRL",
                                                    }
                                                  )}
                                                </td>
                                                <td width={100}></td>
                                              </tr>
                                            }
                                          </React.Fragment>
                                        );
                                      }}
                                    </Query>
                                  </tbody>
                                </table>
                              </div>
                            }
                          />
                        </Col>
                      </Row>
                    </Grid>
                  </div>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export default Dashboard;
