import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateVmImage($id: Int!, $uuid: String!, $cluster_id: Int!, $vm_image_id: Int!){
    createOrUpdateVmImage(id:$id, input:{ uuid:$uuid, cluster_id:$cluster_id, vm_image_id:$vm_image_id}){
      id
    }
  }
`;

const GET_VMIMAGE = gql`
  query getVmClusterImage($vmImage_id: Int!){
    vmImageCluster(id:$vmImage_id){
      id, uuid, cluster{value:id, label:title}
    }
  }`;

  const GET_CLUSTERS = gql`
  {
    cluster_collection{
      clusters{
        value:id
        label:title
      }
    }
  }
  `;
  

class VmImageClusterItem extends Component {

  vmImage_id;
  vmImage_edit;
  cluster_id;
  parent_id;
  back_button;

  state = {
    alertInfo: ""
  };

  constructor() {
    super();

    this.start_is_blocked = true;
    
  }



  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    this.vmImage_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.uuid = 0;



    return (


      <Query
      client={cloud_client}
      query={GET_VMIMAGE}
      variables={{vmImage_id:this.vmImage_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) {console.log(error); return <div>Erro :(</div>}
          
          this.vmImage_edit = data.vmImageCluster;

          if(this.vmImage_edit && this.vmImage_edit.uuid) {
            this.uuuid = this.vmImage_edit.uuid;
          }


        return (

          <Query
              client={cloud_client}
              query={GET_CLUSTERS}
              >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) {console.log(error); return <div>Erro :(</div>}
                  
                  var clusters = data.cluster_collection.clusters;
                  
    
                return (

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateVmImage, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/Image"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Conta"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateVmImage({variables: { 
                      id: this.vmImage_id, 
                      uuid: e.target.elements.uuid.value,
                      cluster_id: e.target.elements.cluster_id.value,
                      vm_image_id: this.props.match.params.vm_image_id
                    } });
                  }}
                  >

                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          name: "uuid",
                          label: "UUID",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "UUID",
                          defaultValue: (this.vmImage_edit && this.vmImage_edit.uuid ? this.vmImage_edit.uuid : "")
                        }
                      ]}
                    />


                    <FormGroup>
                    <ControlLabel>Cluster</ControlLabel>
                    <div>
                     
                      <Select
                        name="cluster_id"
                        options={clusters ? clusters : []}
                        className="basic-select"
                        classNamePrefix="select"
                        isClearable={true}
                        defaultValue={this.vmImage_edit && this.vmImage_edit.cluster && this.vmImage_edit.cluster  ? this.vmImage_edit.cluster : ""}
                      />
                              
                    </div>
                  </FormGroup>

                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

            
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

          );
        }}
        </Query>
      );
      }}
      </Query>
    );
  }
}

export default VmImageClusterItem;
