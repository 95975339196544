import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel,
  Table
} from "react-bootstrap";import { NavLink } from "react-router-dom";

import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";



import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';


import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import InputMask from 'react-input-mask';



const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
  });
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({
  uri: "/api/v1/cloud/",
  credentials: 'include'
  });
const cloud_client = new ApolloClient({
 link: restLink_cloud,
 cache: new InMemoryCache(),
});

const COUNT_VM = gql`
{
  vms_count{
    count
  }
}
`;

class Account extends Component {


  render() {


    return (
      <div className="content">
        <Grid fluid>

        <Card
          title="Minha Conta"
          content={
          
        
              <Query
                    client={account_client}
                    fetchPolicy='no-cache'
                      query={gql`{
                        get_authenticated_user {
                          id
                          name
                          uid
                          mail
                          cellphone
                          title
                          account {
                            id
                            cnpj
                            name
                            phone
                          }
                        }
                      }
                      
                      `}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return <div>Olá</div>;
                      if (error) return <div>Erro :(</div>;

                        var get_authenticated_user = data.get_authenticated_user;
                        
                      return  (


                        <div>
                        <div>
                          <h2>Dados</h2>
                        </div>


                        <div>
                          <Row>
                          <Col md={6}>

                        
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  //onChange:this.onChange_name,
                                  name: "name",
                                  label: "Nome Completo",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Nome Completo",
                                  defaultValue: get_authenticated_user.name
                                }
                              ]}
                            />
                            <FormGroup>
                              <ControlLabel>CPF</ControlLabel>
                              <div>
                              <InputMask 
                                className="form-control"
                                mask="999.999.999-99" 
                                value={get_authenticated_user.uid} 
                                //onChange={this.onChange_cpf} 
                              />
                              </div>
                            </FormGroup>

                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_mail,
                                  name: "mail",
                                  label: "E-mail",
                                  type: "mail",
                                  bsClass: "form-control",
                                  placeholder: "E-mail",
                                  defaultValue: get_authenticated_user.mail
                                }
                              ]}
                            />

                            <FormGroup>
                              <ControlLabel>Celular</ControlLabel>
                              <div>
                              <InputMask 
                                className="form-control"
                                mask="(99) 9 9999-9999" 
                                value={get_authenticated_user.cellphone} 
                                //onChange={this.onChange_cellphone} 
                              />
                              </div>
                            </FormGroup>

                            </Col>
                            <Col md={6}>

                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_company,
                                  name: "company",
                                  label: "Empresa",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Empresa",
                                  defaultValue: get_authenticated_user.account.name
                                }
                              ]}
                            />


                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_title,
                                  name: "title",
                                  label: "Cargo",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Cargo",
                                  defaultValue: get_authenticated_user.title
                                }
                              ]}
                            />

                            <FormGroup>
                              <ControlLabel>CNPJ</ControlLabel>
                              <div>
                              <InputMask 
                                className="form-control"
                                mask="99.999.999/9999-99" 
                                value={get_authenticated_user.account.cnpj} 
                                //onChange={this.onChange_cnpj} 
                              />
                              </div>
                            </FormGroup>

                              <FormGroup>
                                  <ControlLabel>Telefone da empresa</ControlLabel>
                                  <div>
                                  <InputMask 
                                    className="form-control"
                                    mask="(99) 9999-9999" 
                                    value={get_authenticated_user.account.phone} 
                                    //onChange={this.onChange_phone} 
                                  />
                                  </div>
                                </FormGroup>

                            </Col>

                            <Col md={12} className="text-right">
                                <Button bsStyle="info" fill type="submit">
                                  ATUALIZAR DADOS
                                </Button>
                            </Col>
                            </Row>
                        </div>


                        {
                          !get_authenticated_user.account.ContaAzulCustomerId && get_authenticated_user.account.id != 1 &&
                          <div>
                            <br /><br />
                            <div>
                              <h2>Dados de pagamento</h2>
                            </div>
                            <Table>
                                <Query
                                  client={cloud_client}
                                  fetchPolicy='no-cache'
                                    query={gql`{
                                      creditCard_collection{
                                        creditCards{
                                          id
                                          title
                                        }
                                      }
                                    }
                                    `}
                                >
                                  {({ loading, error, data }) => {
                                    if (loading) return <div>Olá</div>;
                                    if (error) return <div>Erro :(</div>;

                                      var creditCards = data.creditCard_collection.creditCards;
                                      if(creditCards.length == 0) {
                                        return  <tbody>
                                                  <tr>
                                                    <td>
                                                        Você ainda não adicionou um meio de pagamento:
                                                        <div>
                                                          <NavLink
                                                              to={'/CreditCard-new/'}
                                                              className="btn btn-info btn-fill"
                                                              activeClassName=""
                                                            >
                                                            Adicionar Meio de Pagamento 
                                                          </NavLink>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                </tbody>

                                      }

                                      return (
                                        <tbody>
                                          {
                                            creditCards.map((item) => (
                                              <tr key={item.id}>
                                                <td>Cartão de crédito</td>
                                                <td>{item.title}</td>
                                              </tr>
                                            )
                                            )
                                          }
                                          <tr>
                                            <td colSpan={2}>
                                                <div>
                                                  <NavLink
                                                      to={'/payment-methods/'}
                                                      className="btn btn-info btn-fill"
                                                      activeClassName=""
                                                    >
                                                    Gerenciar meios de pagamento 
                                                  </NavLink>
                                                </div>
                                            </td>
                                          </tr>
                                        
                                        </tbody>
                                      )
                                    }}
                                  </Query>

                            </Table>

                            <br /><br />
                            <div>
                              <h2>Dados de financeiro</h2>
                            </div>
                            <Table>
                              <tbody>
                                <tr>
                                  <td>
                                    Nenhum histórico finceiro 
                                  </td>
                                </tr>
                              </tbody>
                            </Table>

                          </div>
                        }
                  


                    </div>
                );
              }}
            </Query>
                  
        }
        />
        </Grid>
      </div>
    );
  }
}

export default Account;
