import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { confirmAlert } from 'react-confirm-alert'; 


const restLink_account = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'
 });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_CLOUDLIMIT = gql`
{
  cloudLimit_collection{
    cloudLimits{
      id
      ram
      vcpu
      storage
      type
    }
  }
}
`;

const COUNT_CLOUDLIMIT = gql`query countCloudLimit($search: String!){
  cloudLimit_count(search:$search){
    count
  }
}
`;


const DELETE_CLOUDLIMIT = gql`
   mutation deleteCloudLimit($id: Int!){
     deleteCloudLimit(id:$id) {
       id
     }
   }
 `;

 const CLOUDLIMIT_OFF = gql`
  mutation cloudLimitOFF($id: Int!){
    cloudLimitOFF(id:$id) {
      id
    }
  }
`;

const CLOUDLIMIT_ON = gql`
    mutation cloudLimitON($id: Int!){
      cloudLimitON(id:$id) {
        id
      }
    }
  `;


class CloudLimitList extends Component {


  state = {
    search: '',
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC"
  }

  toggleOrder = (event) => {
    this.setState({
      order: (this.state.order == 'DESC'? 'ASC' : 'DESC')
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value
    });

  }

  render() {
    
    return (




                        <div className="content">
                          <Grid fluid>
                            <Row>
                              <Col md={12}>
                                <Card
                                  title={"Limites"}
                                  cloudLimit="Gerenciar Limites"
                                  ctTableFullWidth
                                  ctTableResponsive
                                  content={
                                    
                                    <Table striped hover>
                                      <thead>
                                        <tr>
                                          <th>vCPU</th>
                                          <th>RAM</th>
                                          <th>Storage</th>
                                          <th>Tipo</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>

                                          <Query
                                            client={cloud_client}
                                            query={GET_CLOUDLIMIT}
                                            fetchPolicy='no-cache'
                                            /*
                                            variables={{
                                              search: this.state.search, 
                                              limit: this.state.limit, 
                                              page: this.state.page, 
                                              order: this.state.order, 
                                              orderby: this.state.orderby
                                            }}
                                            */
                                          >
                                            {({ loading, error, data }) => {
                                              if (loading) return <tr><td>Carregando...</td></tr>;
                                              if (error) return <tr><td>Erro :(</td></tr>;
                                                
                                                if(!data.cloudLimit_collection.cloudLimits.length) {
                                                  return (<tr><td colSpan={12}><strong>(Você ainda não criou Limites)</strong></td></tr>);
                                                } 

                                                return data.cloudLimit_collection.cloudLimits.map((item) => (
                                                
                                                <tr key={item.id}>
                                                  <td>{item.vcpu}</td>
                                                  <td>{item.ram} GB</td>
                                                  <td>{item.storage} GB</td>
                                                  <td>{item.type == 1 ? "Global" : "Por conta"}</td>
                                                  <td> 
                                                    <a className="btn btn-info " href={getUrl('CloudLimit-edit',item.id)}>Editar</a>
                                                  </td>
                                                  
                                                </tr>
                                              ));
                                            }}
                                          </Query>
                                        
                                      </tbody>
                                    </Table>
                                  }
                                />
                              </Col>
                            </Row>
                          </Grid>
                  </div>

    );
  }
}

export default CloudLimitList;
