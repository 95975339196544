import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateVmImage($id: Int!, $title: String!, $size_bytes: String!){
    createOrUpdateVmImage(id:$id, input:{title:$title,  size_bytes:$size_bytes}){
      id
    }
  }
`;

const GET_VMIMAGE = gql`
  query getVmImage($vmImage_id: Int!){
    vmImage(id:$vmImage_id){
      id, title, size_bytes, thumb
    }
  }`;


  

class VmImageItem extends Component {

  vmImage_id;
  vmImage_edit;
  cluster_id;
  parent_id;
  back_button;

  state = {
    alertInfo: ""
  };

  constructor() {
    super();

    this.start_is_blocked = true;
    
  }



  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    this.vmImage_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.uuid = 0;



    return (


      <Query
      client={cloud_client}
      query={GET_VMIMAGE}
      variables={{vmImage_id:this.vmImage_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) {console.log(error); return <div>Erro :(</div>}
          
          this.vmImage_edit = data.vmImage;

          if(this.vmImage_edit && this.vmImage_edit.uuid) {
            this.uuuid = this.vmImage_edit.uuid;
          }



                  
    
                return (

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateVmImage, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/Image"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Conta"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateVmImage({variables: { 
                      id: this.vmImage_id, 
                      title: e.target.elements.title.value,
                      size_bytes: e.target.elements.size_bytes.value
                    } });
                  }}
                  >

                <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Nome da Imagem",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Nome da Imagem",
                        defaultValue: (this.vmImage_edit ? this.vmImage_edit.title : "")
                      }
                    ]}
                  />


                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "size_bytes",
                        label: "Tamanho em bytes",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Tamanho em bytes",
                        defaultValue: (this.vmImage_edit ? this.vmImage_edit.size_bytes : "")
                      }
                    ]}
                  />

                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

              <Card
                title={this.vmImage_edit && this.vmImage_edit.id ?"Thumbnail":"Salve, depois edite novamente para alterar o Thumbnail"}
                content={this.vmImage_edit && this.vmImage_edit.id ?
                  <form 
                  action="/api/v1/cloud/vmimage_thumb" method="post" enctype='multipart/form-data'
                  >
                  
                  <input type="hidden" name="returnUrl" value={"/#/Image-edit/"+this.vmImage_edit.id} />
                  <input type="hidden" name="id" value={this.vmImage_edit.id} />

                  <input
                  type="file"
                  name="file"
                  required
                  />

                  <Row>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Upload
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
              : <div></div>}
              />

              <Card
                title=""
                content={this.vmImage_edit && this.vmImage_edit.thumb ?
                  <div><img alt="thumb" src={"/cloud/assets/"+this.vmImage_edit.thumb} height="100px" /></div>
              : <div></div>}
              />
              
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default VmImageItem;
