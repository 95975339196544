import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

const restLink_combo = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const combo_client = new ApolloClient({
  link: restLink_combo,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateCombo($id: Int!, $account_id: Int!, $title: String!, $description: String!, $cost: String!){
    createOrUpdateCombo(id:$id, input:{title:$title, account_id:$account_id, description:$description,  cost:$cost}){
      id
    }
  }
`;

const GET_COMBO = gql`
  query getCombo($combo_id: Int!){
    combo(id:$combo_id){
      id, title, description, cost_format
    }
  }`;



class ComboItem extends Component {

  combo_id;
  combo_edit;
  parent_id;
  back_button;

  state = {
    alertInfo: ""
  };

  constructor() {
    super();

    this.start_is_blocked = true;
    
  }



  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    this.combo_id = (this.props.match.params.id ? this.props.match.params.id : 0);


    return (


      <Query
      client={combo_client}
      query={GET_COMBO}
      variables={{combo_id:this.combo_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.combo_edit = data.combo;


        return (

      <Mutation mutation={ADD_TODO} client={combo_client}>
      {(createOrUpdateCombo, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to={"/Combo/"+this.props.match.params.account_id}
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Conta"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateCombo({variables: { 
                      id: this.combo_id, 
                      account_id: this.props.match.params.account_id, 
                      title: e.target.elements.title.value,
                      description: e.target.elements.description.value,
                      cost: e.target.elements.cost.value
                    } });
                  }}
                  >

                <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Nome do combo",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Nome do combo",
                        defaultValue: (this.combo_edit ? this.combo_edit.title : "")
                      }
                    ]}
                  />


                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "description",
                        label: "Descrição",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Descrição",
                        defaultValue: (this.combo_edit ? this.combo_edit.description : "")
                      }
                    ]}
                  />

                  <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          name: "cost",
                          label: "Valor unitário do combo (R$)",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Valor unitário do combo (R$)",
                          defaultValue: (this.combo_edit && this.combo_edit.cost_format ? this.combo_edit.cost_format : "0,00")
                        }
                      ]}
                    />

                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default ComboItem;
