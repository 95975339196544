import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import ReactLoading from "react-loading";

import logo from "../../assets/img/logo-thumb.png";


const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const LOGIN_RESET = gql`
  query login_reset($email: String!){
    login_reset(email:$email){
      msg
      msg_error
    }
  }
`;

const PASS_RESET = gql`
query pass_reset($token: String!, $pass: String!, $repass: String!){
  pass_reset(token:$token, pass:$pass, repass:$repass){
    msg
    msg_error
  }
}
`;

class Login extends Component {

  state = {
    loading: false,
    email: null,
    token: null,
    pass: null,
    repass: null,
    msg: null,
    msg_type: "info"
  }

  login_reset = async () => {

    this.setState({
      loading: true
    });

    const user = (await account_client.query({
      variables: { email: this.state.email },
      query: LOGIN_RESET,
      fetchPolicy: 'no-cache'
    })).data.login_reset;
    if (user.msg_error !== null) {
      document.cookie = "token_user=" + user.token;// Grava o coookie
      this.setState({
        loading: false,
        msg_type: "warning",
        msg: user.msg_error
      });
      window.location.replace('/');
    } else {
      this.setState({
        loading: false,
        msg_type: "info",
        msg: user.msg
      });
    }
  }

  pass_reset = async () => {
    const user = (await account_client.query({
      variables: { token: this.state.token, pass: this.state.pass, repass: this.state.repass, token: this.props.match.params.token },
      query: PASS_RESET,
      fetchPolicy: 'no-cache'
    })).data.pass_reset;
    if (user.msg_error !== null) {
      document.cookie = "token_user=" + user.token;// Grava o coookie
      this.setState({
        msg_type: "warning",
        msg: user.msg_error
      });
    } else {
      this.setState({
        msg_type: "info",
        msg: user.msg
      });
    }
  }


  handleSubmit_loginReset = (event) => {
    event.preventDefault();
    this.login_reset();
  }

  handleSubmit_passReset = (event) => {
    event.preventDefault();
    this.pass_reset();
  }

  //onChange_email = email => this.setState({ email })

  onChange_email = (event) => {
    this.setState({ email: event.target.value });
  }
  onChange_pass = (event) => {
    this.setState({ pass: event.target.value });
  }
  onChange_repass = (event) => {
    this.setState({ repass: event.target.value });
  }



  render() {


    this.token = (this.props.match.params.token ? this.props.match.params.token : 0);


    return (


      <div>
        <div className="content content-login">
          <Grid fluid>

            <Row>

              <Col md={6}>
                <h2 className="title-big">Movti Cloud</h2>
                <p>A transformação digital da sua empresa começa aqui</p>
                <p>Comece e acelere a sua jornada de transformação digital para soluções cloud, que prepara a sua empresa para o futuro.</p>
              </Col>
              <Col md={6}>

                {
                  this.token !== 0
                    ?

                    <div>
                      <h1 className="title-center">Digite a nova senha</h1>
                      <div className="login-box">
                        <form
                          onSubmit={e => {
                            this.handleSubmit_passReset(e);
                            e.preventDefault();
                          }}
                        >

                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                onChange: this.onChange_pass,
                                name: "pass",
                                label: "Nova Senha",
                                type: "password",
                                bsClass: "form-control",
                                placeholder: "Nova Senha",
                                defaultValue: ""
                              }
                            ]}
                          />

                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                onChange: this.onChange_repass,
                                name: "repass",
                                label: "Repetir Nova Senha",
                                type: "password",
                                bsClass: "form-control",
                                placeholder: "Repetir Nova Senha",
                                defaultValue: ""
                              }
                            ]}
                          />



                          <Button className="btn-full btn-fill btn btn-info" type="submit">
                            CONFIRMAR
                          </Button>


                          <a href="/#/login">Fazer Login</a>

                          <Row>
                            {
                              this.state.msg &&
                              <Col md={12}>
                                <Alert bsStyle={this.state.msg_type}>
                                  {this.state.msg}
                                </Alert>
                              </Col>
                            }
                          </Row>

                        </form>
                      </div>
                    </div>

                    :

                    <div>
                      <h1 className="title-center">Recuperar Senha</h1>
                      <div className="login-box">
                        <form
                          onSubmit={e => {
                            this.handleSubmit_loginReset(e);
                            e.preventDefault();
                          }}
                        >
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                onChange: this.onChange_email,
                                name: "email",
                                label: "E-mail",
                                type: "email",
                                bsClass: "form-control",
                                placeholder: "E-mail",
                                defaultValue: ""
                              }
                            ]}
                          />

                          {
                            this.state.loading
                              ?
                              <ReactLoading className="center" type="bubbles" color="#1DC7EA" />
                              :
                              <Button className="btn-full btn-fill btn btn-info" type="submit">
                                RECUPERAR SENHA
                                          </Button>
                          }


                          <a href="/#/login">Fazer Login</a>

                          <Row>
                            {
                              this.state.msg &&
                              <Col md={12}>
                                <Alert bsStyle={this.state.msg_type}>
                                  {this.state.msg}
                                </Alert>
                              </Col>
                            }
                          </Row>

                        </form>
                      </div>
                    </div>
                }



              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Login;
