import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { confirmAlert } from 'react-confirm-alert'; 


const restLink_vmDisk = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const vmDisk_client = new ApolloClient({
  link: restLink_vmDisk,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_VM_DISK = gql`
query vmDisk_collection($vm_id: Int!){
  vmDisk_collection(vm_id:$vm_id) {
    vm_disks{
      id
      vmdisk_uuid
      device_uuid
      device_index
      storage_container_uuid
      size
    }
  }
}
`;


const DELETE_VM_DISK = gql`
   mutation deleteVmDisk($vm_id: Int!, $vmdisk_uuid: String!){
     deleteVmDisk(vm_id:$vm_id, vmdisk_uuid:$vmdisk_uuid) {
      vmdisk_uuid
     }
   }
 `;


class VmDiskList extends Component {


  state = {
    search: '',
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC"
  }

  toggleOrder = (event) => {
    this.setState({
      order: (this.state.order == 'DESC'? 'ASC' : 'DESC')
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value
    });

  }

  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to="/Vm/"
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
              VOLTAR
              </NavLink>
              <NavLink
                  to={'/VmDisk-new/'+this.props.match.params.vm_id}
                  className="btn btn-info btn-fill pull-right"
                  activeClassName=""
                >
                Adicionar Disco
              </NavLink>
              <Card
                title={"Discos"}
                vmDisk="Gerenciar Discos"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    
                    <thead>
                      <tr>
                        <th>Index</th>
                        <th>Tamanho</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={vmDisk_client}
                          query={GET_VM_DISK}
                          fetchPolicy='no-cache'
                          variables={{
                            vm_id: this.props.match.params.vm_id
                          }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.vmDisk_collection) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                              return data.vmDisk_collection.vm_disks.map((item) => (
                              
                              <tr key={item.device_index}>
                                <td>{item.device_index}</td>
                                <td>
                                  {
                                  //item.size/1073741824
                                  item.size
                                  } 
                                  GB
                                  </td>
                                
                                <td> 
                                  <a className="btn btn-info " href={getUrl('VmDisk-edit/'+this.props.match.params.vm_id,item.id)}>Editar</a>
                                </td>
                                <td>
                                  {
                                    item.device_index !== 0 &&
                                      <Button bsStyle="danger" onClick={() => {
                                            var r = confirmAlert({
                                            title: '',
                                            message: "Tem certeza que deseja excluir o disco de index "+item.device_index,
                                            buttons: [
                                              {
                                                label: 'Sim',
                                                onClick: () => {
                                                  // Delete item
                                                  vmDisk_client.mutate({
                                                    mutation: DELETE_VM_DISK,
                                                    variables: {
                                                    vm_id: this.props.match.params.vm_id,
                                                    vmdisk_uuid: item.vmdisk_uuid
                                                    }
                                                  }).then(
                                                  result => {
                                                      window.location.reload();
                                                  });
                                                }
                                              },
                                              {
                                                label: 'Não'
                                              }
                                            ]
                                          });
                                        
                                      }}>
                                      Excluir
                                    </Button>
                                  }
                                
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                       
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default VmDiskList;
