import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel,
  Table
} from "react-bootstrap";

import { Navbar } from "react-bootstrap";


import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import InputMask from 'react-input-mask';

import { ProductCard } from "../../components/ProductCard/ProductCard.jsx";


import logo_thumb from "../../assets/img/logo-thumb.png";
import background1 from "../../assets/img/background1.png";

const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});


const GET_AUTH_USER = gql`
{
  get_authenticated_user {
    id
    mail
    account {
      id
      name
    }
  }
}
`;


const REGISTER_STEP_3 = gql`
mutation createAccountByUserStep3( $code: String!){
  createAccountByUserStep3( code:$code){
      id
      token
      msg_error
    }
  }
`;


// ######### Fazer o fluxo de recuperar a senha


class RegisterStep3 extends Component {

  state = {
    code: "",
    msg: "",
    msg_type: "info"
  }


  register_step_3 = async () => {

    const mutationReturn = (await account_client.mutate({
      variables: {
        code: this.state.code
      },
      mutation: REGISTER_STEP_3,
      fetchPolicy: 'no-cache'
    })).data.createAccountByUserStep3;


    if (mutationReturn && typeof mutationReturn.token != "undefined" && mutationReturn.token !== null && mutationReturn.token !== "") {
      document.cookie = "token_user=" + mutationReturn.token;// Grava o coookie
      this.setState({
        msg_type: "info",
        msg: "Registro feito com sucesso, aguarde, você será redirecionado."
      });
      window.location.replace('/#/welcome');
      window.location.reload();
    } else if (mutationReturn && typeof mutationReturn.msg_error != "undefined") {
      this.setState({
        msg_type: "warning",
        msg: mutationReturn.msg_error
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.register_step_3();

  }

  onChange_code = (event) => {
    this.setState({ code: event.target.value });
  }

  render() {


    return (
      <div>
        <div className="content content-login">
          <Grid fluid>


            <div>
              <h1 className="title-center">Selecione um plano de suporte</h1>

              <div className="login-box">
                <p>
                  Oferecemos uma variedade de planos para atender às suas necessidades. Selecione um plano de suporte que melhor se alinhe ao seu uso da Movti. Para saber mais sobre comparações de planos e exemplos de definição de preço, clique aqui. Você pode alterar o plano de suporte a qualquer momento no Console.
                    </p>
                <Row>
                  <Col md={4} >
                    <div className="text-center">
                      <i className={"pe-7s-box1 icon-middle"} />
                      <p><strong>Plano Básico</strong></p>
                      <p className="text-gray">
                        Recomendado para novos usuários que estão começando a usar a Movti
                          </p>
                      <a href="/#/welcome" className="btn-full btn-fill btn btn-success">
                        Gratuito
                          </a>
                    </div>
                    <ul className="text-gray">
                      <li>
                        Acesso por autoatendimento 24 horas, todos os dias a fóruns e recursos
                          </li>
                      <li>
                        Verificações de melhores práticas para ajudar a aprimorar a segurança e o desempenho.
                          </li>
                      <li>
                        Acesso a status de integridade e notificações
                          </li>
                    </ul>
                  </Col>
                  <Col md={4} >
                    <div className="text-center">
                      <i className={"pe-7s-plugin icon-middle"} />
                      <p><strong>Plano Desenvolvedor</strong></p>
                      <p className="text-gray">
                        Recomendado para desenvolvedores que estão avaliando a Movti
                          </p>
                      <a href="/#/welcome" className="btn-full btn-fill btn btn-info">
                        A partir de R$ 200,00
                          </a>
                    </div>
                    <ul className="text-gray">
                      <li>
                        Acesso por e-mail ao Movti Support durante o horário comercial
                          </li>
                      <li>
                        Tempo de resposta de 12 horas (horário comercial)
                          </li>
                    </ul>
                  </Col>
                  <Col md={4} >
                    <div className="text-center">
                      <i className={"pe-7s-portfolio icon-middle"} />
                      <p><strong>Plano Business</strong></p>
                      <p className="text-gray">
                        Recomendado para executar cargas de trabalho de produção na Movti
                          </p>
                      <a href="/#/welcome" className="btn-full btn-fill btn btn-info">
                        A partir de R$ 600,00
                          </a>
                    </div>
                    <ul className="text-gray">
                      <li>
                        Suporte técnico 24 horas, 7 dias da semana, por e-mail, telefone e chat
                          </li>
                      <li>
                        Tempo de resposta de 1 hora
                          </li>
                      <li>
                        Conjunto completo de recomendações de melhores práticas do Trusted Advisor
                          </li>
                    </ul>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={2}>
                  </Col>
                  <Col md={2}>
                    <i className={"pe-7s-culture icon-middle"} />
                  </Col>
                  <Col md={6}>
                    <p>
                      <strong>Precisa de atendimento nível empresarial?</strong>
                    </p>
                    <p>
                      <a href="#">Saiba Mais</a>
                    </p>
                  </Col>
                  <Col md={2}>
                  </Col>
                </Row>
              </div>
            </div>

          </Grid>
        </div>
      </div>
    );
  }
}

export default RegisterStep3;
