import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateProduct($id: Int!, $title: String!, $ContaAzulProductId: String!, $description: String!, $cost: String!){
    createOrUpdateProduct(id:$id, input:{title:$title, ContaAzulProductId:$ContaAzulProductId, description:$description,  cost:$cost}){
      id
    }
  }
`;

const GET_PRODUCT = gql`
  query getProduct($product_id: Int!){
    product(id:$product_id){
      id, title, ContaAzulProductId, description, cost_format
    }
  }`;

  const GET_CONTA_AZUL_PRODUCTS = gql`
  {
    contaazul_products_collection{
      products{
        value:id
        label:name
      }
    }
  }
  `;
  
  const GET_CONTA_AZUL_PRODUCT = gql`
    query contaazul_product($id: String!){
      contaazul_product(id:$id) {
        value:id
        label:name
      }
    }`;

class ProductItem extends Component {

  product_id;
  product_edit;
  parent_id;
  back_button;

  state = {
    alertInfo: ""
  };

  constructor() {
    super();

    this.start_is_blocked = true;
    
  }



  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    this.product_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.ContaAzulProductId = 0;

    console.log(this.product_id);

    return (


      <Query
      client={cloud_client}
      query={GET_PRODUCT}
      variables={{product_id:this.product_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) {console.log(error); return <div>Erro :(</div>}
          
          this.product_edit = data.product;

          if(this.product_edit && this.product_edit.ContaAzulProductId) {
            this.ContaAzulProductId = this.product_edit.ContaAzulProductId;
          }


        return (

          <Query
              client={cloud_client}
              query={GET_CONTA_AZUL_PRODUCTS}
              >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) {console.log(error); return <div>Erro :(</div>}
                  
                  var contaazul_products_collection = data.contaazul_products_collection.products;
                  
    
                return (

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateProduct, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/Product"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Conta"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateProduct({variables: { 
                      id: this.product_id, 
                      title: e.target.elements.title.value,
                      description: e.target.elements.description.value,
                      cost: e.target.elements.cost.value,
                      ContaAzulProductId: e.target.elements.ContaAzulProductId.value
                    } });
                  }}
                  >

                <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Nome do Produto",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Nome do Produto",
                        defaultValue: (this.product_edit ? this.product_edit.title : "")
                      }
                    ]}
                  />


                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "description",
                        label: "Descrição",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Descrição",
                        defaultValue: (this.product_edit ? this.product_edit.description : "")
                      }
                    ]}
                  />

                  <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          name: "cost",
                          label: "Valor unitário do produto (R$)",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Valor unitário do produto (R$)",
                          defaultValue: (this.product_edit && this.product_edit.cost_format ? this.product_edit.cost_format : "0,00")
                        }
                      ]}
                    />

                    <FormGroup>
                    <ControlLabel>Vincular com Produto da Conta Azul</ControlLabel>
                    <div>
                      <Query
                          client={cloud_client}
                          query={GET_CONTA_AZUL_PRODUCT}
                          variables={{id:this.ContaAzulProductId}}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;

                              var contaazul_product = data.contaazul_product;
                            return (
                              <Select
                                name="ContaAzulProductId"
                                options={contaazul_products_collection ? contaazul_products_collection : []}
                                className="basic-select"
                                classNamePrefix="select"
                                isClearable={true}
                                defaultValue={contaazul_product ? contaazul_product : ""}
                              />
                              );
                            }}
                      </Query>
                    </div>
                  </FormGroup>

                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

          );
        }}
        </Query>
      );
      }}
      </Query>
    );
  }
}

export default ProductItem;
