import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Card from "../../components/Card/Card.jsx";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import 'react-confirm-alert/src/react-confirm-alert.css'; //
import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 


const restLink_user = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const user_client = new ApolloClient({
  link: restLink_user,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_USERS = gql`query users_collections($account_id: Int!, $search: String!, $limit: Int!, $page: Int!, $order: String!, $orderby: String!){
  users_collections(account_id: $account_id, search: $search, limit: $limit, page: $page, order: $order, orderby: $orderby){
      users{
        id, name, token, profile{title}
      }
    }
  }
`;

const COUNT_USERS = gql`query countUsers($account_id: Int!, $search: String!){
  users_count(account_id: $account_id, search:$search){
    count
  }
}
`;
const DELETE_USER = gql`
   mutation deleteUser($id: Int!){
     deleteUser(id:$id) {
       id
     }
   }
`;


const GET_ACCOUNT = gql`query get_account($account_id: Int!){
  account(id: $account_id){
      id
      name
    }
  }
`;


class UserList extends Component {

  state = {
    search: "",
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC"
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value
    });
    

  }

  render() {

    this.account_id = (this.props.match.params.account_id ? this.props.match.params.account_id : 0);

    return (

      <div className="content">

        <Query
          client={user_client}
          query={GET_ACCOUNT}
          variables={{
            account_id: this.account_id
          }}
          fetchPolicy='no-cache'
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;
              
              if(!data.account) {
                return (<div></div>);
              } 

            return (
              <Grid fluid>
                <Row>
                  <Col md={12}>
                  <Card
                      title={"Você esstá editando a conta: " +data.account.name }
                      ctTableFullWidth
                      ctTableResponsive
                      //content={
                      //}
                    />
                  </Col>
                </Row>
              </Grid>
            );
          }}
        </Query>

        <Grid fluid>
          <Row>
            <Col md={12}>

              <NavLink
                  to={'/User-new/' + (this.account_id ? this.account_id : "")}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                Novo Usuário
              </NavLink>

              <Card
                name={"Usuários"}
                title="Gerenciar usuários"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>

                    <thead>
                      <tr>
                        <th colSpan={9}>
                            <form 
                            onSubmit={e => {
                              this.handleSubmit(e);
                              e.preventDefault();
                            }}
                            >
                              <Row>
                                  <Col md={6} lg={3}>
                                      <input  type="text" name="search" className="form-control" />
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Buscar
                                    </Button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6} lg={1}>
                                      <select name="orderby" className="form-control">
                                        <option value="id">ID</option>
                                        <option value="name" selected={this.state.orderby == "name"}>Nome</option>
                                      </select>
                                  </Col>
                                  <Col md={6} lg={2}>
                                      <select name="order" className="form-control">
                                        <option value="DESC">Decrescente</option>
                                        <option value="ASC" selected={this.state.order == "ASC"}>Crescente</option>
                                      </select>
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Ordenar
                                    </Button>
                                  </Col>
                                </Row>
                            </form>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Perfil</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={user_client}
                          query={GET_USERS}
                          variables={{
                            account_id: this.account_id,
                            search: this.state.search, 
                            limit: this.state.limit, 
                            page: this.state.page, 
                            order: this.state.order, 
                            orderby: this.state.orderby
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.users_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                            return data.users_collections.users.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.profile ? item.profile.title : ""}</td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('User-edit',item.id)}>Editar</a>
                                </td>
                                <td>
                                  <a className="btn btn-success" target="_blank" rel="noopener noreferrer" href={"/api/v1/account/authtoken?token="+item.token} >Entrar com esse usuário</a>
                                </td>
                                <td>
                                <Button bsStyle="danger" onClick={() => {
                                       var r = confirmAlert({
                                       title: '',
                                       message: "Tem certeza que deseja excluir "+item.name,
                                       buttons: [
                                         {
                                           label: 'Sim',
                                           onClick: () => {
                                             // Delete item
                                             user_client.mutate({
                                               mutation: DELETE_USER,
                                               variables: {
                                                 id:item.id
                                               }
                                             }).then(
                                              result => {
                                                 window.location.reload();
                                             });
                                           }
                                         },
                                         {
                                           label: 'Não'
                                         }
                                       ]
                                     });
                                    
                                  }}>
                                  Excluir
                                </Button>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                        <Query
                          client={user_client}
                          query={COUNT_USERS}
                          variables={{
                            account_id: this.account_id,
                            search: this.state.search
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;

                              var pagination_buttons = [];
                              var total_pages = data.users_count.count/this.state.limit;
                              for(var page_num = 1; Math.ceil(total_pages) >= page_num; page_num++){
                                pagination_buttons.push(<spam><spam page_num={page_num} className={"btn_page "+(this.state.page==page_num ? 'btn_page_active' : '')} onClick={e => {this.setState({page: e.target.getAttribute('page_num')})}} >{page_num}</spam> </spam>);
                              }
                            return (
                              <tr key={1}>
                                <td colSpan={6}>
                                  {pagination_buttons}
                                </td>
                              </tr>
                            );
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserList;
