import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";

import dashboardRoutes from "../../routes/dashboard.jsx";

class Dashboard extends Component {
  render() {
    return (
      <div className="wrapper">
          <Header {...this.props} />
          <Switch>
            {dashboardRoutes.map((prop, key) => {
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />;
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
          {
          //<Footer />
          }
      </div>
    );
  }
}

export default Dashboard;
