import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

const restLink_account = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'
 });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation contact($subject: String!, $message: String!){
    contact(type:1, subject: $subject, message: $message){
      id
    }
  }
`;



class ContactNew extends Component {

  parent_id;
  back_button;

  state = {
    alertInfo: "",
    send: false
  };

  constructor() {
    super();

    
  }



  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Mensagem enviada com sucesso!",
      send : true
    });

  }

  
  render() {

    

    return (


      <Mutation mutation={ADD_TODO} client={account_client}>
      {(contact, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

         
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Conta"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    contact({variables: { 
                      subject: e.target.elements.subject.value,
                      message: e.target.elements.message.value
                    } });
                  }}
                  >

                    {
                      !this.state.send &&
                      <div>
                        <FormGroup>
                          <ControlLabel>Assunto</ControlLabel>
                            <Select
                              name="subject"
                              options={[{'label': "Comercial", 'value': 'Comercial'}, { 'label': "Suporte", 'value': 'Suporte'}, { 'label': "Outro", 'value': 'Outro'}]}
                              className="basic-select"
                              classNamePrefix="select"
                              isClearable={true}
                              defaultValue={""}
                            />
                        </FormGroup>

                        <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                name: "message",
                                label: "Mensagem",
                                type: "textarea",
                                bsClass: "form-control",
                                placeholder: "Mensagem",
                                //defaultValue: 
                              }
                            ]}
                          />

                        
                        <Row>
                          <Col md={10}>
                          </Col>
                          <Col md={2}>
                            <Button bsStyle="info" pullRight fill type="submit">
                              Enviar
                            </Button>
                          </Col>
                          </Row>

                      </div>
                    }

                    <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />


                  
                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

       
    );
  }
}

export default ContactNew;
