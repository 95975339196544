import Auth from "../views/Auth/Auth";
import Login from "../views/Auth/Login";
import ResetPass from "../views/Auth/ResetPass";
import Register from "../views/Auth/Register";
import RegisterStep2 from "../views/Auth/RegisterStep2";
import RegisterStep3 from "../views/Auth/RegisterStep3";
import RegisterStep4 from "../views/Auth/RegisterStep4";
import RegisterStep5 from "../views/Auth/RegisterStep5";
import Welcome from "../views/Auth/Welcome";

import Home from "../views/Home/Home";
import MyAccount from "../views/Home/MyAccount";
import MyServices from "../views/Home/MyServices";
import ListProducts from "../views/Home/ListProducts";
import Support from "../views/Home/Support";
import Contact from "../views/Home/Contact";
import ContactService from "../views/Home/ContactService";

import DashboardV1 from "../views/Dashboard/DashboardV1";
import Dashboard from "../views/Dashboard/Dashboard";
import UserProfile from "../views/UserProfile/UserProfile";

import Segmentation from "../views/Segmentation/Segmentation";
import SegmentationImport from "../views/Segmentation/SegmentationImport";

import Account from "../views/Account/Account";
import AccountItem from "../views/Account/AccountItem";

import UserList from "../views/User/UserList";
import UserItem from "../views/User/UserItem";

import FlavorList from "../views/Flavor/FlavorList";
import FlavorItem from "../views/Flavor/FlavorItem";
import ClusterHasFlavor from "../views/Flavor/ClusterHasFlavor";

import VmImageList from "../views/VmImage/VmImageList";
import VmImageItem from "../views/VmImage/VmImageItem";

import VmImageClusterList from "../views/VmImageCluster/VmImageClusterList";
import VmImageClusterItem from "../views/VmImageCluster/VmImageClusterItem";

import VmList from "../views/Vm/VmList";
import VmSelect from "../views/Vm/VmSelect";
import VmSelectTrail from "../views/Vm/VmSelectTrail";
import VmItem from "../views/Vm/VmItem";
import VmSelectSO from "../views/Vm/VmSelectSO";
import VmSelectSize from "../views/Vm/VmSelectSize";
import VmSelectConfig from "../views/Vm/VmSelectConfig";

import VmDiskList from "../views/VmDisk/VmDiskList";
import VmDiskItem from "../views/VmDisk/VmDiskItem";

import ProductList from "../views/Product/ProductList";
import ProductItem from "../views/Product/ProductItem";

import ContactList from "../views/Contact/ContactList";
import ContactServiceList from "../views/Contact/ContactServiceList";

import PayMethodList from "../views/PayMethod/PayMethodList";
import PayMethodSelect from "../views/PayMethod/PayMethodSelect";
import CreditCardItem from "../views/CreditCard/CreditCardItem";
import BoletoItem from "../views/Boleto/BoletoItem";

import CloudLimitList from "../views/CloudLimit/CloudLimitList";
import CloudLimitItem from "../views/CloudLimit/CloudLimitItem";

import ComboList from "../views/Combo/ComboList";
import ComboItem from "../views/Combo/ComboItem";

import ComboHasProductList from "../views/ComboHasProduct/ComboHasProductList";
import ComboHasProductItem from "../views/ComboHasProduct/ComboHasProductItem";

import AccountHasProductList from "../views/AccountHasProduct/AccountHasProductList";
import AccountHasProductItem from "../views/AccountHasProduct/AccountHasProductItem";

import SubnetipamHasAccount from "../views/Subnet/SubnetipamHasAccount";
import SubnetnutanixHasAccount from "../views/Subnet/SubnetnutanixHasAccount";

import CommClientGroupHasAccount from "../views/CommClientGroup/CommClientGroupHasAccount";

import StorageContainerNutanixHasAccount from "../views/StorageContainer/StorageContainerNutanixHasAccount";

import CostCustomList from "../views/CostCustom/CostCustomList";
import CostCustomItem from "../views/CostCustom/CostCustomItem";

import Profile from "../views/Profile/Profile";
import ProfileItem from "../views/Profile/ProfileItem";
import ProfileHasModule from "../views/Profile/ProfileHasModule";

import Cluster from "../views/Cluster/Cluster";

import TableList from "../views/TableList/TableList";
import Typography from "../views/Typography/Typography";
import Icons from "../views/Icons/Icons";
import Maps from "../views/Maps/Maps";
import Notifications from "../views/Notifications/Notifications";

const dashboardRoutes = [
  {
    showSidebar: false,
    path: "/login",
    name: "Login",
    icon: "pe-7s-users",
    component: Login,
  },
  {
    showSidebar: false,
    path: "/recuperar-senha/:token",
    name: "ResetPass",
    icon: "pe-7s-users",
    component: ResetPass,
  },
  {
    showSidebar: false,
    path: "/recuperar-senha",
    name: "ResetPass",
    icon: "pe-7s-users",
    component: ResetPass,
  },

  {
    showSidebar: false,
    path: "/registro",
    name: "Register",
    icon: "pe-7s-users",
    component: Register,
  },
  {
    showSidebar: false,
    path: "/registro-passo-2",
    name: "Register",
    icon: "pe-7s-users",
    component: RegisterStep2,
  },
  {
    showSidebar: false,
    path: "/registro-passo-3",
    name: "Register",
    icon: "pe-7s-users",
    component: RegisterStep3,
  },
  {
    showSidebar: false,
    path: "/registro-passo-4",
    name: "Register",
    icon: "pe-7s-users",
    component: RegisterStep4,
  },
  {
    showSidebar: false,
    path: "/registro-passo-5",
    name: "Register",
    icon: "pe-7s-users",
    component: RegisterStep5,
  },

  {
    showSidebar: false,
    path: "/welcome",
    name: "Register",
    icon: "pe-7s-users",
    component: Welcome,
  },

  {
    default: true,
    showSidebar: true,
    path: "/home",
    name: "Início",
    icon: "pe-7s-home",
    component: Home,
  },
  {
    showSidebar: true,
    path: "/my-account",
    name: "Minha Conta",
    icon: "pe-7s-id",
    component: MyAccount,
    module: "Dashboard",
  },

  /* USUÁRIOS */
  {
    showSidebar: false,
    path: "/User/:account_id",
    name: "Usuários",
    icon: "pe-7s-users",
    component: UserList,
    module: "User",
  },
  {
    showSidebar: true,
    path: "/User",
    name: "Usuários",
    icon: "pe-7s-users",
    component: UserList,
    module: "User",
  },
  {
    showSidebar: false,
    path: "/User-edit/:id",
    name: "User Edit",
    icon: "pe-7s-users",
    component: UserItem,
  },
  {
    showSidebar: false,
    path: "/User-new/:account_id",
    name: "User New",
    icon: "pe-7s-users",
    component: UserItem,
  },
  {
    showSidebar: false,
    path: "/User-new",
    name: "User New",
    icon: "pe-7s-users",
    component: UserItem,
  },

  /* PRODUTOS */

  {
    showSidebar: true,
    path: "/my-services",
    name: "Meus Serviços",
    icon: "pe-7s-box2",
    component: MyServices,
    module: "Dashboard",
  },
  {
    showSidebar: true,
    path: "/list-products",
    name: "Produtos",
    icon: "pe-7s-science",
    component: ListProducts,
    module: "Dashboard",
  },
  {
    showSidebar: true,
    path: "/support",
    name: "Suporte",
    icon: "pe-7s-headphones",
    component: Support,
    module: "Dashboard",
  },
  {
    showSidebar: true,
    path: "/contact",
    name: "Contato",
    icon: "pe-7s-phone",
    component: Contact,
    module: "Dashboard",
  },
  {
    showSidebar: false,
    path: "/new-service",
    name: "Contratar Serviço",
    icon: "pe-7s-phone",
    component: ContactService,
    module: "Dashboard",
  },

  {
    showSidebar: false,
    path: "/dashboard",
    name: "Faturamento",
    icon: "pe-7s-graph",
    component: Dashboard,
    module: "Dashboard",
  },
  {
    showSidebar: false,
    path: "/dashboardv1",
    name: "Faturamento",
    icon: "pe-7s-graph",
    component: DashboardV1,
    module: "Dashboard",
  },

  /* Limites */
  {
    showSidebar: true,
    path: "/cloud-limits",
    name: "Limites",
    icon: "pe-7s-config",
    component: CloudLimitList,
    module: "CloudLimit",
  },
  {
    showSidebar: false,
    path: "/CloudLimit-edit/:id",
    name: "Limites",
    icon: "pe-7s-config",
    component: CloudLimitItem,
  },

  /* Meios de pagamento */
  {
    showSidebar: false,
    path: "/payment-methods",
    name: "Meios de Pagamento",
    icon: "pe-7s-credit",
    component: PayMethodList,
    module: "PayMethod",
  },
  {
    showSidebar: false,
    path: "/payment-methods-edit/:id",
    name: "Editar Meio de Pagamento",
    icon: "pe-7s-credit",
    component: PayMethodSelect,
  },
  {
    showSidebar: false,
    path: "/payment-methods-new",
    name: "Novo Meio de Pagamento",
    icon: "pe-7s-credit",
    component: PayMethodSelect,
  },
  {
    showSidebar: false,
    path: "/CreditCard-new",
    name: "Novo Meio de Pagamento",
    icon: "pe-7s-credit",
    component: CreditCardItem,
  },
  {
    showSidebar: false,
    path: "/Boleto-new",
    name: "Novo Meio de Pagamento",
    icon: "pe-7s-credit",
    component: BoletoItem,
  },

  /* Contas */
  // {
  //   showSidebar: true,
  //   path: "/Account",
  //   name: "Clientes",
  //   icon: "pe-7s-users",
  //   component: Account,
  //   module: "Account",
  // },
  // {
  //   showSidebar: false,
  //   path: "/Account-edit/:id",
  //   name: "Account Edit",
  //   icon: "pe-7s-server",
  //   component: AccountItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/Account-new",
  //   name: "Account New",
  //   icon: "pe-7s-server",
  //   component: AccountItem,
  // },

  // {
  //   showSidebar: false,
  //   path: "/auth/:userid",
  //   name: "Auth",
  //   icon: "pe-7s-graph",
  //   component: Auth,
  // },
  // {
  //   showSidebar: false,
  //   path: "/profile",
  //   name: "User Profile",
  //   icon: "pe-7s-user",
  //   component: UserProfile,
  // },

  // {
  //   showSidebar: false,
  //   path: "/segmentation/:module_name/:lawsuit_id/:id",
  //   name: "Segmentation",
  //   icon: "pe-7s-user",
  //   component: Segmentation,
  // },
  // {
  //   showSidebar: false,
  //   path: "/segmentation/:module_name/:id",
  //   name: "Segmentation",
  //   icon: "pe-7s-user",
  //   component: Segmentation,
  // },
  // {
  //   showSidebar: false,
  //   path: "/segmentation-import/:module_name/:id",
  //   name: "SegmentationImport",
  //   icon: "pe-7s-user",
  //   component: SegmentationImport,
  // },

  // {
  //   showSidebar: false,
  //   path: "/subnetsIpam/:account_id",
  //   name: "SubnetipamHasAccount",
  //   icon: "pe-7s-user",
  //   component: SubnetipamHasAccount,
  // },
  // {
  //   showSidebar: false,
  //   path: "/subnetsNutanix/:account_id",
  //   name: "SubnetnutanixHasAccount",
  //   icon: "pe-7s-user",
  //   component: SubnetnutanixHasAccount,
  // },
  // {
  //   showSidebar: false,
  //   path: "/StorageContainerNutanixHasAccount/:account_id",
  //   name: "StorageContainerNutanixHasAccount",
  //   icon: "pe-7s-user",
  //   component: StorageContainerNutanixHasAccount,
  // },

  // /* Client Group Commvault */
  // {
  //   showSidebar: false,
  //   path: "/CommClientGroupHasAccount/:account_id",
  //   name: "CommClientGroupHasAccount",
  //   icon: "pe-7s-user",
  //   component: CommClientGroupHasAccount,
  // },

  // /* Cluster */
  // {
  //   showSidebar: true,
  //   path: "/Cluster",
  //   name: "Zonas de Disp.",
  //   icon: "pe-7s-server",
  //   component: Cluster,
  //   module: "Cluster",
  // },

  /* PERFIS */
  // {
  //   showSidebar: true,
  //   path: "/Profiles",
  //   name: "Perfis Admin",
  //   icon: "pe-7s-id",
  //   component: Profile,
  //   module: "Profile",
  // },
  // {
  //   showSidebar: false,
  //   path: "/Profile-new",
  //   name: "Profile Item",
  //   icon: "pe-7s-id",
  //   component: ProfileItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/Profile-edit/:id",
  //   name: "Profile Edit",
  //   icon: "pe-7s-id",
  //   component: ProfileItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/Profile-has-module/:id",
  //   name: "Profile",
  //   icon: "pe-7s-id",
  //   component: ProfileHasModule,
  // },

  /* Produtos */
  // {
  //   showSidebar: true,
  //   path: "/Product",
  //   name: "Produtos",
  //   icon: "pe-7s-science",
  //   component: ProductList,
  //   module: "Product",
  // },
  // {
  //   showSidebar: false,
  //   path: "/Product-edit/:id",
  //   name: "Produtos Edit",
  //   icon: "pe-7s-cloud",
  //   component: ProductItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/Product-new",
  //   name: "Produtos New",
  //   icon: "pe-7s-cloud",
  //   component: ProductItem,
  // },

  // /* Combos */
  // {
  //   showSidebar: false,
  //   path: "/Combo/:account_id",
  //   name: "Combos",
  //   icon: "pe-7s-science",
  //   component: ComboList,
  //   module: "Combo",
  // },
  // {
  //   showSidebar: false,
  //   path: "/Combo-edit/:account_id/:id",
  //   name: "Combo Edit",
  //   icon: "pe-7s-cloud",
  //   component: ComboItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/Combo-new/:account_id",
  //   name: "Combo New",
  //   icon: "pe-7s-cloud",
  //   component: ComboItem,
  // },

  // /* Combo Has Products */
  // {
  //   showSidebar: false,
  //   path: "/ComboHasProduct/:account_id/:combo_id",
  //   name: "Produtos do Combo",
  //   icon: "pe-7s-science",
  //   component: ComboHasProductList,
  //   module: "Combo",
  // },
  // {
  //   showSidebar: false,
  //   path: "/ComboHasProduct-edit/:account_id/:combo_id/:id",
  //   name: "Combo Edit",
  //   icon: "pe-7s-cloud",
  //   component: ComboHasProductItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/ComboHasProduct-new/:account_id/:combo_id",
  //   name: "Combo New",
  //   icon: "pe-7s-cloud",
  //   component: ComboHasProductItem,
  // },

  // /* Account Has Products */
  // {
  //   showSidebar: false,
  //   path: "/AccountHasProduct/:account_id",
  //   name: "Produtos do Account",
  //   icon: "pe-7s-science",
  //   component: AccountHasProductList,
  //   module: "Account",
  // },
  // {
  //   showSidebar: false,
  //   path: "/AccountHasProduct-edit/:account_id/:id",
  //   name: "Account Edit",
  //   icon: "pe-7s-cloud",
  //   component: AccountHasProductItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/AccountHasProduct-new/:account_id",
  //   name: "Account New",
  //   icon: "pe-7s-cloud",
  //   component: AccountHasProductItem,
  // },

  // /* Cost Custom */
  // {
  //   showSidebar: false,
  //   path: "/CostCustom/:account_id",
  //   name: "Produtos do Account",
  //   icon: "pe-7s-science",
  //   component: CostCustomList,
  //   module: "Account",
  // },
  // {
  //   showSidebar: false,
  //   path: "/CostCustom-edit/:account_id/:id",
  //   name: "Account Edit",
  //   icon: "pe-7s-cloud",
  //   component: CostCustomItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/CostCustom-new/:account_id",
  //   name: "Account New",
  //   icon: "pe-7s-cloud",
  //   component: CostCustomItem,
  // },

  // /* Sabores */
  // {
  //   showSidebar: true,
  //   path: "/Flavor",
  //   name: "Modelos",
  //   icon: "pe-7s-star",
  //   component: FlavorList,
  //   module: "Flavor",
  // },
  // {
  //   showSidebar: false,
  //   path: "/Flavor-edit/:id",
  //   name: "Sabor Edit",
  //   icon: "pe-7s-star",
  //   component: FlavorItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/Flavor-new",
  //   name: "Sabor New",
  //   icon: "pe-7s-star",
  //   component: FlavorItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/ClusterHasFlavor/:id",
  //   name: "Edit",
  //   icon: "pe-7s-star",
  //   component: ClusterHasFlavor,
  // },

  // /* Sabores */
  // {
  //   showSidebar: true,
  //   path: "/Image",
  //   name: "Imagens",
  //   icon: "pe-7s-disk",
  //   component: VmImageList,
  //   module: "VmImage",
  // },
  // {
  //   showSidebar: false,
  //   path: "/Image-edit/:id",
  //   name: "VmImagem Edit",
  //   icon: "pe-7s-disk",
  //   component: VmImageItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/Image-new",
  //   name: "VmImage New",
  //   icon: "pe-7s-disk",
  //   component: VmImageItem,
  // },

  // {
  //   showSidebar: false,
  //   path: "/ImageCluster/:vm_image_id",
  //   name: "Imagens",
  //   icon: "pe-7s-disk",
  //   component: VmImageClusterList,
  //   module: "VmImage",
  // },
  // {
  //   showSidebar: false,
  //   path: "/ImageCluster-edit/:vm_image_id/:id",
  //   name: "VmImagem Edit",
  //   icon: "pe-7s-disk",
  //   component: VmImageClusterItem,
  // },
  // {
  //   showSidebar: false,
  //   path: "/ImageCluster-new/:vm_image_id",
  //   name: "VmImage New",
  //   icon: "pe-7s-disk",
  //   component: VmImageClusterItem,
  // },

  /* Instâncias */
  {
    showSidebar: false,
    path: "/Vm",
    name: "Instâncias",
    icon: "pe-7s-cloud",
    component: VmList,
    module: "VM",
  },
  {
    showSidebar: false,
    path: "/VM-edit/:id",
    name: "VM Edit",
    icon: "pe-7s-cloud",
    component: VmItem,
  },
  {
    showSidebar: false,
    path: "/VM-new",
    name: "VM New",
    icon: "pe-7s-cloud",
    component: VmItem,
  },

  {
    showSidebar: false,
    path: "/VM-Select-Flavor/:flavor_id/:ram/:vcpu",
    name: "VM Select",
    icon: "pe-7s-cloud",
    component: VmSelect,
  },
  {
    showSidebar: false,
    path: "/VM-Select-Trail",
    name: "VM Select Trail",
    icon: "pe-7s-cloud",
    component: VmSelectTrail,
  },
  {
    showSidebar: false,
    path: "/VM-Select",
    name: "VM Select",
    icon: "pe-7s-cloud",
    component: VmSelect,
  },
  {
    showSidebar: false,
    path: "/VM-Select-SO",
    name: "VM Select",
    icon: "pe-7s-cloud",
    component: VmSelectSO,
  },
  {
    showSidebar: false,
    path: "/VM-Select-Size",
    name: "VM Select",
    icon: "pe-7s-cloud",
    component: VmSelectSize,
  },
  {
    showSidebar: false,
    path: "/VM-Select-Config",
    name: "VM Config",
    icon: "pe-7s-cloud",
    component: VmSelectConfig,
  },

  {
    showSidebar: false,
    path: "/VmDisk/:vm_id",
    name: "VM Disks",
    icon: "pe-7s-cloud",
    component: VmDiskList,
  },
  {
    showSidebar: false,
    path: "/VmDisk-new/:vm_id",
    name: "VM Disks",
    icon: "pe-7s-cloud",
    component: VmDiskItem,
  },
  {
    showSidebar: false,
    path: "/VmDisk-edit/:vm_id/:vmdisk_id",
    name: "VM Disks",
    icon: "pe-7s-cloud",
    component: VmDiskItem,
  },

  /* Contatos */

  {
    showSidebar: true,
    path: "/Contacts-List",
    name: "Contatos Enviados",
    icon: "pe-7s-phone",
    component: ContactList,
    module: "ContactList",
  },
  {
    showSidebar: true,
    path: "/ContactsServices-List",
    name: "Solicitações",
    icon: "pe-7s-phone",
    component: ContactServiceList,
    module: "ContactList",
  },

  /* OUTROS */
  {
    showSidebar: false,
    path: "/table",
    name: "Table List",
    icon: "pe-7s-note2",
    component: TableList,
  },
  {
    showSidebar: false,
    path: "/typography",
    name: "Typography",
    icon: "pe-7s-news-paper",
    component: Typography,
  },
  {
    showSidebar: false,
    path: "/icons",
    name: "Icons",
    icon: "pe-7s-science",
    component: Icons,
  },
  {
    showSidebar: false,
    path: "/maps",
    name: "Maps",
    icon: "pe-7s-map-marker",
    component: Maps,
  },
  {
    showSidebar: false,
    path: "/notifications",
    name: "Notifications",
    icon: "pe-7s-bell",
    component: Notifications,
  },
  { redirect: true, path: "/", to: "/home", name: "Home" },
];

export default dashboardRoutes;
