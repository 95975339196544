import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Alert
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';


const restLink_cloudLimit = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
});
const cloud_client = new ApolloClient({
  link: restLink_cloudLimit,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateCloudLimit($id: Int!, $vcpu: Int!, $ram: Int!, $storage: Int!){
    createOrUpdateCloudLimit(id:$id,input:{vcpu: $vcpu, ram:$ram, storage:$storage}) {
      id
    }
  }
`;

const GET_CLOUDLIMIT = gql`
  query getCloudLimit($cloudLimit_id: Int!){
    cloudLimit(id:$cloudLimit_id){
      id
      vcpu
      ram
      storage
    }
  }
`;

class CloudLimitItem extends Component {

  cloudLimit_id;
  cloudLimit_edit;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      datePublish: new Date(),
      dateUnpublish: null
    };


    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    this.start_blockcontent = true;
    
    
  }

  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }

  handleCheckboxChange_blockcontent = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_blockcontent: !this.state.checkboxValue_blockcontent
    }));

  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    if(this.props.match.params.id) {
      this.cloudLimit_id = this.props.match.params.id;
    } else {
      this.cloudLimit_id = 0;
    }

    return (


      <Query
      client={cloud_client}
      query={GET_CLOUDLIMIT}
      variables={{cloudLimit_id:this.cloudLimit_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.cloudLimit_edit = data.cloudLimit;

        return (

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateCloudLimit, { data }) => {
      

      return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
            
              <NavLink
                  to="/CloudLimit"
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                VOLTAR
              </NavLink>
              <NotificationSystem ref="notificationSystem" style={style} />

            
              <Card
                title={"Instância: "+this.cloudLimit_edit.title}
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateCloudLimit({variables: { 
                      id: this.cloudLimit_id, 
                      ram: e.target.elements.ram.value,
                      vcpu: e.target.elements.vcpu.value,
                      storage: e.target.elements.storage.value
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "ram",
                        label: "Memória RAM em GB",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Memória RAM em GB",
                        defaultValue: (this.cloudLimit_edit ? this.cloudLimit_edit.ram : "")
                      }
                    ]}
                  />

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "vcpu",
                        label: "Número de VCPUs",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Número de VCPUs por socket",
                        defaultValue: (this.cloudLimit_edit ? this.cloudLimit_edit.vcpu : "")
                      }
                    ]}
                  />

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "storage",
                        label: "Storage em GB",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Storage em GB",
                        defaultValue: (this.cloudLimit_edit ? this.cloudLimit_edit.storage : "")
                      }
                    ]}
                  />

                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

            </Col>
            
          </Row>
        </Grid>
      </div>
      )}}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default CloudLimitItem;
