import React, { Component } from "react";
import edu_cover from "../../assets/img/edu.jpg";

import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';

import Button from "../CustomButton/CustomButton.jsx";


export class Branching extends Component {


  render() {

    const parseHtml = htmlParser({
      isValidNode: node => node.type !== 'script',
      processingInstructions: [/* ... */]
    })

    return (
      <div className="chat_message_admin">
        <div className="chat_message">
          <div className="chat_answer">
            <div className="chat_thumb"><img src={edu_cover} alt="" /></div>
            <b>Edu</b>
              <div>
                <p>{this.props.message}</p>
                <p>Clique em uma opção abaixo, ou digite sua dúvida.</p>
                <p>
                  <Button bsStyle="info" onClick={() => { this.props.chatElement.newUserMessage_click("Esclarecer Dúvida") }}>Esclarecer Dúvida</Button>
                  <Button bsStyle="info" onClick={() => { this.props.chatElement.newUserMessage_click("Realizar uma Consulta") }}>Realizar uma Consulta</Button>
                  <Button bsStyle="info" onClick={() => { this.props.chatElement.newUserMessage_click("Buscar Informações") }}>Buscar Informações</Button>
                  <Button bsStyle="info" onClick={() => { this.props.chatElement.newUserMessage_click("Registrar uma Solicitação") }}>Registrar uma Solicitação</Button>
                  <Button bsStyle="info" onClick={() => { this.props.chatElement.newUserMessage_click("Informar um Erro/Problema") }}>Informar um Erro/Problema</Button>
                  <Button bsStyle="info" onClick={() => { this.props.chatElement.newUserMessage_click("Registrar uma Sugestão/crítica/elogio") }}>Registrar uma Sugestão/crítica/elogio</Button>
                </p>
              </div>
              
          </div>
        </div>
      </div>
    );
  }
}

export default Branching;
