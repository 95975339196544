import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel,
  Table
} from "react-bootstrap";

import { Navbar } from "react-bootstrap";


import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import InputMask from 'react-input-mask';

import { ProductCard } from "../../components/ProductCard/ProductCard.jsx";

import Recaptcha from 'react-google-invisible-recaptcha';

import logo_thumb from "../../assets/img/logo-thumb.png";
import background1 from "../../assets/img/background1.png";


const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});


const REGISTER = gql`
mutation createAccountByUserStep1($mail: String!, $company: String!, $pass: String!, $repass: String!){
    createAccountByUserStep1( mail:$mail, company:$company, pass:$pass, repass:$repass){
      id
      token
      msg_error
    }
  }
`;

// ######### Fazer o fluxo de recuperar a senha


class Register extends Component {
  
  state = {
    validRecaptcha: false,
    mail: "",
    company: "",
    pass: "",
    repass: "",
    msg: "",
    msg_type: "info"
  }


  register_step_1 = async () => {


      if(!this.state.validRecaptcha) {
        this.setState({
          msg_type: "warning",
          msg: "Verifique todos os campos."
        });
      }
      else {
        const mutationReturn = (await account_client.mutate({
          variables: {
            mail: this.state.mail, 
            company:this.state.company,
            pass:this.state.pass, 
            repass:this.state.repass
          },
          mutation: REGISTER,
          fetchPolicy: 'no-cache'
        })).data.createAccountByUserStep1;
  
  
        if(mutationReturn && typeof mutationReturn.token != "undefined" && mutationReturn.token !== null && mutationReturn.token !== "") {
           
            document.cookie = "token_user="+mutationReturn.token;// Grava o coookie
            this.setState({
              msg_type: "info",
              msg: "Registro feito com sucesso, aguarde, você será redirecionado."
            });
            window.location.replace('/');
          
        } else if(mutationReturn && typeof mutationReturn.msg_error != "undefined") {
          this.setState({
            msg_type: "warning",
            msg: mutationReturn.msg_error
          });
        }
      }
    
      
  }

  handleSubmit = (event) => {

    event.preventDefault();
    this.register_step_1();
    
  }
   

  onChange_mail = (event) => {
    this.recaptcha.execute();
    this.setState({mail: event.target.value});
  }
  onChange_company = (event) => {
      this.recaptcha.execute();
      this.setState({company: event.target.value});
  }
  onChange_pass = (event) => {
    this.recaptcha.execute();
    this.setState({pass: event.target.value});
  }
  onChange_repass = (event) => {
    this.recaptcha.execute();
    this.setState({repass: event.target.value});
  }


  onResolved = () => {
      this.setState({validRecaptcha: true})
  }
  
  render() {


    return (
      <div>
      <div className="content content-login">
      <Grid fluid>

      

          <Row>

            <Col md={6}>
                <h2 className="title-big">Movti Cloud</h2>
                <p>Melhoramos o desempenho das empresas</p>
                <p>Somos especialistas em transformação digital com soluções cloud, entregamos produtos e serviços de alta disponibilidade que proporcionam mais colaboração e aumento de produtividade das empresas.</p>
              </Col>
              <Col md={6}>
                <h1 className="title-center">Criar uma conta Movti</h1>
                <div className="login-box">

                <form 
                    onSubmit={e => {
                      this.handleSubmit(e);
                      e.preventDefault();
                    }}
                    >
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              onChange:this.onChange_mail,
                              name: "mail",
                              label: "E-mail",
                              type: "mail",
                              bsClass: "form-control",
                              placeholder: "E-mail",
                              defaultValue: ""
                            }
                          ]}
                        />

                        <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                onChange:this.onChange_pass,
                                name: "pass",
                                label: "Senha",
                                type: "password",
                                bsClass: "form-control",
                                placeholder: "Senha",
                                defaultValue: ""
                              }
                            ]}
                          />

                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                onChange:this.onChange_repass,
                                name: "repass",
                                label: "Repetir Senha",
                                type: "password",
                                bsClass: "form-control",
                                placeholder: "Repetir Senha",
                                defaultValue: ""
                              }
                            ]}
                          />
                        
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              onChange:this.onChange_company,
                              name: "company",
                              label: "Nome da conta ou empresa",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Nome da conta ou empresa",
                              defaultValue: ""
                            }
                          ]}
                        />

                          
                          <Button className="btn-full btn-fill btn btn-info" type="submit">
                            CONTINUAR
                          </Button>
                          {
                            this.state.msg &&
                              <Col md={12}>
                                <Alert bsStyle={this.state.msg_type}>
                                  {this.state.msg}
                                </Alert>
                              </Col>
                          }
                          <a href="/#/login">Faça login em uma conta existente</a>
                        <br />

                          

                        <Recaptcha
                            ref={ ref => this.recaptcha = ref }
                            sitekey="6LfYJzwaAAAAAIUc38SzvhfItaAIecJqk8zZwOE3"
                            onResolved={ this.onResolved }
                        />


                      
                      
              </form>
            </div>
            </Col>
        </Row>

       
            
                     

                

        </Grid>
      </div>
      </div>
    );
  }
}

export default Register;
