import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { confirmAlert } from 'react-confirm-alert'; 

import Select from 'react-select';



const restLink_account = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'
 });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_VM = gql`
query vms_collections($search: String!){
  vms_collections(search:$search){
    vms{
      id
      uuid
      title
      description
      state
      memory_size_mib
      num_vcpus_per_socket
      num_sockets
      last_update_time_format_br
      creation_time_format_br
      power_state
      ip
    }
  }
}
`;

const COUNT_VM = gql`query countVm($search: String!){
  vm_count(search:$search){
    count
  }
}
`;


const DELETE_VM = gql`
   mutation deleteVm($id: Int!){
     deleteVm(id:$id) {
       id
     }
   }
 `;

 const VM_OFF = gql`
  mutation vmOFF($id: Int!){
    vmOFF(id:$id) {
      id
    }
  }
`;

const VM_ON = gql`
    mutation vmON($id: Int!){
      vmON(id:$id) {
        id
      }
    }
  `;


class VmList extends Component {


  state = {
    search: '',
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC",
    vm_id: 0,
    actions: false
  }

  toggleOrder = (event) => {
    this.setState({
      order: (this.state.order == 'DESC'? 'ASC' : 'DESC')
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      search: event.target.elements.search.value
    });

  }

  onChange_action = async (event, i) => {
    console.log('-------');
    console.log(this.state.vm_id);
    console.log(event.value);
    var action = event.value;
    var vm_id = this.state.vm_id;
    if(vm_id) {
      switch(action) {
        case "edit" :
          window.location.replace("/#/VM-edit/"+vm_id);
          break;
        case "disks" :
          window.location.replace("/#/VmDisk/"+vm_id);
          break;
          case "vm_on" :
            var poweerStateOn = (await cloud_client.mutate({
              mutation: VM_ON,
              variables: {
                id: vm_id
              }
            })).data;
            window.location.reload();
            break;
        case "vm_off" :
          var poweerStateOff = (await cloud_client.mutate({
            mutation: VM_OFF,
            variables: {
              id: vm_id
            }
          })).data;
          window.location.reload();
          break;
      }
    }
  }

  render() {

    var actionValuesDefault = [
      {"value": "", "label": "Conectar", isDisabled: true},
      {"value": "", "label": "Editar Instância", isDisabled: true},
      {"value": "", "label": "Gerenciar Discos", isDisabled: true},
      {"value": "", "label": "Redes", isDisabled: true},
      {"value": "", "label": "Ligar/Desligar Instância", isDisabled: true}
    ];

    
    return (

          <div>
                <div className="content">
                  <Grid fluid>
                    <Row>
                      <Col md={12}>

                            <Row>
                              <Col md={5} >
                                <h2>Instâncias</h2>
                                <form 
                                  onSubmit={e => {
                                    this.handleSubmit(e);
                                    e.preventDefault();
                                  }}
                                  >
                                    <input placeholder="Filtrar instâncias" type="text" name="search" className="search-input" />
                                  </form>
                              </Col>
                              <Col md={7} >
                                <NavLink
                                  to={'/VM-Select-Trail/'}
                                  className="btn btn-orange btn-fill pull-right"
                                  activeClassName=""
                                >
                                Executar instância
                              </NavLink>
                              <Select
                                  onChange={this.onChange_action}
                                  name="action"
                                  options={this.state.actions ? this.state.actions : actionValuesDefault}
                                  className="basic-select select-action pull-right"
                                  classNamePrefix="select"
                                  isClearable={false}
                                  defaultValue={{"value": "", "label": "Ações"}}
                                />
                              </Col>
                            </Row>
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Nome</th>
                                  <th>ID</th>
                                  <th>Tipo de instância</th>
                                  <th>PowerState</th>
                                  <th>Estado da Instância</th>
                                  <th>Endereço de IP</th>
                                  <th>Criação</th>
                                  <th>Última atualização</th>
                                </tr>
                              </thead>
                              <tbody>

                                  <Query
                                    client={cloud_client}
                                    query={GET_VM}
                                    fetchPolicy='no-cache'
                                    variables={{
                                      search: this.state.search
                                    }}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading) return <tr><td>Carregando...</td></tr>;
                                      if (error) return <tr><td>Erro :(</td></tr>;
                                        
                                        if(!data.vms_collections.vms.length) {
                                          return <tr><td colSpan={9}><strong>Nenhuma instância</strong></td></tr>;
                                        } 

                                        return data.vms_collections.vms.map((item) => (
                                        
                                        <tr key={item.id}>
                                        <td>
                                          <Button className={"btn btn-box btn-info "+(this.state.vm_id === item.id ? "btn-fill" : "")}  onClick={() => {
                                            var actions = [
                                              {"value": "", "label": "Conectar"},
                                              {"value": "edit", "label": "Editar Instância"},
                                              {"value": "disks", "label": "Gerenciar Discos"},
                                              //{"value": "network", "label": "Redes"}
                                            ];
                                            if(item.power_state === "ON") {
                                              actions.push({"value": "vm_off", "label": "Desligar Instância"});
                                            }
                                            else {
                                              actions.push({"value": "vm_on", "label": "Ligar Instância"});
                                            }
                                            this.setState({ 
                                              vm_id: item.id,
                                              actions: actions
                                            });
                                          }}> 
                                          </Button>
                                        </td>
                                        <td>{item.title}</td>
                                          <td>{item.id}</td>
                                          <td>
                                          {item.memory_size_mib / 1024} GB RAM  {item.num_vcpus_per_socket}vCPU
                                          </td>
                                          {
                                          //<td><div className={item.state == 'COMPLETE' ? 'color-green' : ''}>{item.state}</div></td>
                                          }
                                          <td><div className={item.power_state == 'OFF' ? 'color-red' : (item.power_state == 'PAUSED' ? 'color-orange' : 'color-green')}>{item.power_state}</div></td>
                                          <td><div className={item.power_state == 'OFF' ? 'color-red' : (item.power_state == 'PAUSED' ? 'color-orange' : 'color-green')}>{item.state}</div></td>
                                          <td>{item.ip}</td>
                                          <td>{item.creation_time_format_br}</td>
                                          <td>{item.last_update_time_format_br}</td>

                                        </tr>
                                      ));
                                    }}
                                  </Query>
                                
                              </tbody>
                            </Table>
                          
                      </Col>
                    </Row>
                  </Grid>
                </div>
          </div>

    );
  }
}

export default VmList;
