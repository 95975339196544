import React, { Component } from "react";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import Button from "../CustomButton/CustomButton.jsx";
import Message from "./Message.jsx";
import Branching from "./Branching.jsx";
import Branching_contents from "./Branching_contents.jsx";
import Branching_digitais_category from "./Branching_digitais_category.jsx";
import Branching_cursos_category from "./Branching_cursos_category.jsx";
import Branching_sala_category from "./Branching_sala_category.jsx";
import Branching_trilha_category from "./Branching_trilha_category.jsx";
import Branching_newchat from "./Branching_newchat.jsx";
import Branching_suport from "./Branching_suport.jsx";

import Nps from "./Nps.jsx";

import logoEscolaProducao from "../../assets/img/logo-white.png";
import logoPortoEduc from "../../assets/img/logo-white.png";

import writingIcon from "../../assets/img/writing.gif";

                                          
const restLink_account = new HttpLink({
   uri: "/api/v1/account/",
   credentials: 'include'
   });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const restLink_chat = new HttpLink({
   uri: "/api/v1/chat/",
   credentials: 'include'
  });
const chat_client = new ApolloClient({
  
  link: restLink_chat,
  cache: new InMemoryCache(),
});

const SAVE_CHAT_MSG = gql`
  mutation AddTodo($user_id: Int!, $user_name: String!, $message: String!, $response_to_user_id: Int!){
    createChatMessage(input:{user_id:$user_id,user_name:$user_name,message:$message, response_to_user_id:$response_to_user_id }){
      id
    }
  }
`;

const SAVE_HEAD_COMMUNICATION = gql`
mutation readCommunication($user_id: Int!, $communication_id: Int!){
  readCommunication(user_id:$user_id,communication_id:$communication_id){
    id
  }
}
`;

const GET_CHAT_MSG = gql`
  query getChatMgs($user_id: Int!, $limit: Int!, $offset: Int!, $show_my_msgs: Int!, $chat_id: Int!,$previous_messages: Int!){
    chat_message_collections_by_user(user_id:$user_id, limit:$limit,offset:$offset,show_my_msgs:$show_my_msgs,chat_id:$chat_id,previous_messages:$previous_messages){
      messages{
        is_admin,
        is_answered,
        user_name,
        message,
        show_nps,
        iframe,
        communication{
          id
        },
        response_from{
          user_name,
          message
        },
        chat_message_relateds{
          id,
          conversation{
            id,
            title
          }
        }
      }
    }
  }
`;

// const GET_USER = gql`
//   query getUser($user_id: Int!){
//     user(id:$user_id){
//       id,
//       name
//     }
//   }
// `;

const GET_AUTH_USER = gql`
{
  get_authenticated_user{
    id,
    first_and_last_name
  }
}
`;


export class Chat extends Component {


  user_auth;
  start_msgs;
  offset_messages;
  communications_read = [];

  constructor() {
    super();
    this.state = {
      messages: [],
      messages_start: [],
      endNps: false,
    };

    this.start_msgs = true;
    this.loading = false;

    // Contabiliza as msgs iniciais, para quando chegar no total, finalizar o timeout
    this.number_of_msgs_start = 0;
    

  }


  endNps() {
    this.setState(state => ({
      ...state,
      endNps: true
    }));
    this.scrollMessages();
  }

  scrollMessages() {
    setTimeout(() =>{
      var chat_body = document.getElementById("chat_body");
      chat_body.scrollTop = chat_body.scrollHeight;
    },100);
  }

  newUserMessage_click(newMessage, user_name = null) {
    if(newMessage) {
      this.loading = true;
    }
    this.newUserMessage(newMessage, user_name);
  }

  newUserMessage(newMessage, user_name = null) {

    
    // Se não for passado o nome do usuário no param, pega o o nome do usuário logado
    var $user_name = (user_name !== null ? user_name : this.user_auth.first_and_last_name);
    
    var all_messages = this.state.messages;
    all_messages.push({user_name:this.user_auth.first_and_last_name,  message:newMessage});

    this.offset_messages++;
    this.setState(state => ({
      ...state,
      messages: all_messages
    }));

    this.scrollMessages();


    // Salva a mensagem no banco
    chat_client.mutate({
      mutation: SAVE_CHAT_MSG,
      variables: {
        user_id:this.user_auth.id, user_name:$user_name, message:newMessage, response_to_user_id: 0
      }
    });
    
  }

  start_messages() {

    if(this.number_of_msgs_start < 3) {
      if(!this.props.enable){

        setTimeout(() => {
          this.start_messages();
        },1000);
  
      } else {
        console.log("Function: start_messages ()");

        var user_name = (this.user_auth && this.user_auth.first_and_last_name ? this.user_auth.first_and_last_name : "");
  
        var new_message_start = [
          {
            "is_admin": 1,
            "is_answered": null,
            "user_name": "Edu",
            "message": "Olá "+user_name+", sou o Edu, assistente virtual da Porto Educ.",
            "response_from": null
          },
          {
            "is_admin": 1,
            "is_answered": null,
            "user_name": "Edu",
            "message": "Seja bem-vindo à Porto Educ, às próximas perguntas são importantes para te atendermos da melhor forma!",
            "response_from": null
          },
          {
            "is_admin": 1,
            "is_answered": null,
            "user_name": "Edu",
            "message": "Sobre o que deseja falar hoje?",
            "response_from": null
          }
        ];

        var new_message = new_message_start[this.number_of_msgs_start];
        this.number_of_msgs_start++;
  
        var messages_concat_start = this.state.messages_start.concat(new_message);
        this.setState(state => ({
          ...state,
          messages_start: messages_concat_start
        }));
        this.scrollMessages();
  
        setTimeout(() => {
          this.start_messages();
        },2500);
      }
    }
    
  }

  previous_messages () {
    console.log("Function: previous_messages ()");
    
    chat_client.query({
      query: GET_CHAT_MSG,
      fetchPolicy: 'no-cache',
      variables: {
        user_id:this.user_auth.id, 
        limit:2000, 
        offset:0, 
        show_my_msgs:1, 
        chat_id: parseInt(this.props.chat_id),
        previous_messages: 1
      }
    }).then(result => {
        this.setState(state => ({
          ...state,
          messages: result.data.chat_message_collections_by_user.messages
        }));
        this.offset_messages = (result.data.chat_message_collections_by_user.messages.length);
        if(result.data.chat_message_collections_by_user.messages.length){
          // Só roda o scroll da conversa, se não for um comunicado
          if(!result.data.chat_message_collections_by_user.messages[0].communication) {
            this.scrollMessages(result.data.chat_message_collections_by_user.messages);
          }
        }
        this.next_messages();
        // Só da exibe as msgs iniciais se o chat estiver vazio
        if(!result.data.chat_message_collections_by_user.messages.length) {
          this.start_messages();
        }
        
    });
  }

  next_messages () {
    if(!this.props.enable){
      //console.log("Chat Inativo");
      this.setState(state => ({
        ...state,
        messages: [],
        endNps: false
      }));
      setTimeout(() => {
        this.next_messages();
      },2500);
    } else {
      console.log("Function: next_messages ()");

      // Definie como visto os comunicados gerais gerais
      if(this.state.messages && this.state.messages.length) {
        this.state.messages.map((message, i) => {
          // Verifica se a mensagem é um comunicado geral
          if(message.communication && message.communication.id) {
            // Verifica se o comunicado ainda não foi removido, para remover
            if(!this.communications_read.includes(message.communication.id)) {
              console.log('Remover '+ message.communication.id);
              // Define a mensagem de comunicação geral como vista
              chat_client.mutate({
                mutation: SAVE_HEAD_COMMUNICATION,
                variables: {
                  user_id:this.user_auth.id, communication_id: message.communication.id
                }
              });
              // Adiciona o ID da comunicação geral para não tentar salvar novamente
              this.communications_read.push(message.communication.id);
            }
            
          }
        });
      }

      chat_client.query({
        query: GET_CHAT_MSG,
        fetchPolicy: 'no-cache',
        variables: {
          user_id:this.user_auth.id, 
          limit:1, 
          offset:this.offset_messages, 
          show_my_msgs:1, 
          chat_id: parseInt(this.props.chat_id),
          previous_messages: 0
        }
      }).then(result => {
          
          var new_messages = result.data.chat_message_collections_by_user.messages;
          if(new_messages.length) {
            console.log("Next Message");
              this.loading = false;
              var messages_concat = this.state.messages.concat(new_messages);
              this.setState(state => ({
                ...state,
                messages: messages_concat
              }));
              this.offset_messages = (this.state.messages.length);
              this.scrollMessages();
          }
          // tenta pegar mais mensagens após alguns segundos
          setTimeout(() => {
            this.next_messages();
          },2500);
      });
    }
  }

  _handleKeyDown = (e) => {
    if(e.key == 'Enter') {
      this.sendMessage();
    }
  }

  sendMessage() {
    var message = document.getElementById("input_message").value;
    if(message) {
      // Remove a mensagem do NPS se o usuário enviar outra por cima
      if(this.state.endNps){
        this.setState(state => ({
          ...state,
          endNps: false
        }));
      }
      this.loading = true;
      this.newUserMessage(message);
      document.getElementById("input_message").value = "";
    }
  }

  
  render() {


    return (
      <Query
          client={account_client}
          query={GET_AUTH_USER}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;
              
            this.user_auth = data.get_authenticated_user;

            // Printar as mensagens existentes
            if(this.start_msgs) {
              this.start_msgs = false;
              setTimeout(() => {
                this.previous_messages();
              },500);
            }

            //<Message chatElement={this} user_name="Edu" message={"Olá, "+this.user_auth.first_and_last_name+". Sou o Edu, assistente virtual da Porto Educ."} response_from={null} is_answered={false} is_admin={true} />
            //<Message chatElement={this} user_name="Edu" message="Seja bem-vindo à Porto Educ, às próximas perguntas são importantes para te atendermos da melhor forma!" response_from={null} is_answered={false} is_admin={true}  />
            //<Branching chatElement={this} message="Sobre o que deseja falar hoje?" />
            
            //<Branching_newchat chatElement={this} key={i} message={message.message} />

            var msgs_start = this.state.messages_start;
            var all_messages = msgs_start.concat(this.state.messages);

            return (

                <div className="chat">
                  <div className="chat_header">
                    <img src={logoPortoEduc} height={40} />
                  </div>
                  <div id="chat_body" className="chat_body">
                    <div id="chat_messages">
                      {all_messages.map((message, i) => (

                          message.is_admin && message.message == "Sobre o que deseja falar hoje?" ?
                          <Branching chatElement={this} key={i} message={message.message} />
                          :
                            message.show_nps && message.show_nps == "true" ?
                            <div>
                              <Message chatElement={this} key={i} user_name={message.user_name} message={message.message} iframe={message.iframe} response_from={message.response_from ? message.response_from : null} is_admin={message.is_admin ? message.is_admin : null} is_answered={message.is_answered ? message.is_answered : false} />
                              <Nps chatElement={this} key={i} chat_id={this.props.chat_id} />
                            </div>
                            :
                              message.is_admin && message.message == "Pode escrever de outra forma, por favor?" ?
                              <Branching_suport chatElement={this} key={i} message={message.message} />
                              :
                                message.is_admin && message.message == "Desculpe, ainda não sei responder essa pergunta, Pode escrever de outra forma, por favor?" ?
                                <Branching_suport chatElement={this} key={i} message={message.message} />
                                :
                                  message.is_admin && message.message == "Sobre qual conteúdo?" ?
                                  <Branching_contents chatElement={this} key={i} message={message.message} />
                                  :
                                  message.is_admin && message.message == "Qual Categoria dos Conteúdos Digitais?" ?
                                    <Branching_digitais_category chatElement={this} key={i} message={message.message} />
                                    :
                                    message.is_admin && message.message == "Qual Categoria dos Cursos?" ?
                                      <Branching_cursos_category chatElement={this} key={i} message={message.message} />
                                      :
                                      message.is_admin && message.message == "Qual Categoria da Sala Virtual?" ?
                                        <Branching_sala_category chatElement={this} key={i} message={message.message} />
                                        :
                                        message.is_admin && message.message == "Qual Categoria de Trilha?" ?
                                          <Branching_trilha_category chatElement={this} key={i} message={message.message} />
                                          :
                                          <Message chatElement={this} key={i} user_name={message.user_name} message={message.message} messagesRelated={message.chat_message_relateds} iframe={message.iframe} response_from={message.response_from ? message.response_from : null} is_admin={message.is_admin ? message.is_admin : null} is_answered={message.is_answered ? message.is_answered : false} />
                          
                      ))}

                      {this.state.endNps &&
                          <Message chatElement={this} user_name="Edu" message="Agradeço o seu contato e participação. Continue conosco, boa navegação!" response_from={null} is_admin={true} is_answered={false} />

                      }

                      {this.loading &&
                        <div class="chat_loading"><img src={writingIcon} /></div>
                      }
                    </div>
                  </div>
                  <div className="chat_footer">
                    <input type="text" placeholder="Como posso ajudar?" id="input_message" onKeyDown={this._handleKeyDown} />
                    <a class="chatSendMessage" onClick={() => this.sendMessage()}>
                      <i class="material-icons">send</i>
                    </a>
                  </div>
                </div>
        );

        }}
        </Query>
    );
  }
}

export default Chat;
