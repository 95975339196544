import React, { Component } from "react";
import { NavLink } from "react-router-dom";


import { Query } from "react-apollo";
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import getCookie from "../Cookie/Cookie.jsx";

import HeaderLinks from "../Header/HeaderLinks.jsx";

import imagine from "../../assets/img/sidebar-4.jpg";
import logo from "../../assets/img/logo-white.png";

import dashboardRoutes from "../../routes/dashboard.jsx";


const token_user = getCookie("token_user");

const account_client = new ApolloClient({
  uri: "/api/v1/account/"
});


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + imagine + ")"
    };
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color="black"
        data-image={imagine}
      >
        <div className="sidebar-background" style={sidebarBackground} />
        <div className="logo">
          <img alt="logo" id="logo_top" src={logo} height="40px" />
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <HeaderLinks /> : null}

            <Query
            client={account_client}
            variables={{token:token_user}}
            query={gql`
                    query modulesByUser($token: String!){
                      modules_by_user(token:$token) {
                        modules{
                          name
                        }
                      }
                    }`}
            >
            {({ loading, error, data }) => {
              if (loading) return <div>Carregando...</div>;
              if (error) return <div>Erro :(</div>;
                
                var modules_by_user_names = [];
                data.modules_by_user.modules.forEach(function(i) {
                  modules_by_user_names.push(i.name);
                });

              return (

                      dashboardRoutes.map((prop, key) => {
                        // Verifica se o é um item do menu Default, ou se é um módulo que o usuário tem acesso
                        if ((prop.default || (prop.module && modules_by_user_names.indexOf(prop.module))) !== -1 && !prop.redirect && prop.showSidebar)
                          return (
                            <li
                              className={
                                prop.upgrade
                                  ? "active active-pro"
                                  : this.activeRoute(prop.link !== undefined ? prop.link : prop.path)
                              }
                              key={key}
                            >
                              <NavLink
                                to={prop.link !== undefined ? prop.link : prop.path}
                                className="nav-link"
                                activeClassName="active"
                              >
                                <i className={prop.icon} />
                                <p>{prop.name}</p>
                              </NavLink>
                            </li>
                          );
                        return null;
                      })

            );
            }}
            </Query>
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;