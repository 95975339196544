import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

const restLink_account = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'
 });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateAccount($id: Int!, $name: String!,  $nutanix_project_id: String! $payday: Int!, $is_blocked: Int!, $discount: Int!, $blocked_description: String!){
    createOrUpdateAccount(id:$id, input:{name:$name, nutanix_project_id:$nutanix_project_id, payday:$payday, is_blocked:$is_blocked, discount:$discount, blocked_description:$blocked_description}){
      id
    }
  }
`;

const GET_ACCOUNT = gql`
  query getAccount($account_id: Int!){
    account(id:$account_id){
      id, name, nutanix_project_id, payday, discount, is_blocked, blocked_description
    }
  }`;

const GET_NUTANIX_PROJECTS = gql`
{
  nutanixproject_colecction {
    projects {
      value: id
      label: title
    }
  }
}
`;

const GET_NUTANIX_PROJECT = gql`
  query nutanixproject($id: String!){
    nutanixproject(id:$id) {
      value:id
      label:title
    }
  }`;




class AccountItem extends Component {

  account_id;
  account_edit;
  parent_id;
  back_button;

  state = {
    alertInfo: "",
    checkboxValue_is_blocked: false
  };

  constructor() {
    super();

    this.start_is_blocked = true;
    
  }

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_is_blocked: !this.state.checkboxValue_is_blocked
    }));

  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    this.account_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.nutanix_project_id = 0;

    return (


      <Query
      client={account_client}
      query={GET_ACCOUNT}
      variables={{account_id:this.account_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :( x</div>;
          
          this.account_edit = data.account;


          if(this.account_edit && this.account_edit.nutanix_project_id) {
            this.nutanix_project_id = this.account_edit.nutanix_project_id;
          }

          if(this.start_is_blocked && this.account_edit && this.account_edit.is_blocked) {
            this.start_is_blocked = false;
            this.setState({
              checkboxValue_is_blocked: (this.account_edit.is_blocked === 1 ? true : false)
            })
          }

        return (

          <Query
          client={account_client}
          query={GET_NUTANIX_PROJECTS}
          >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :( Y</div>;
              
              var nutanix_projects = data.nutanixproject_colecction.projects;


            return (

          
    

      <Mutation mutation={ADD_TODO} client={account_client}>
      {(createOrUpdateAccount, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/Account"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Conta"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateAccount({variables: { 
                      id: this.account_id, 
                      name: e.target.elements.name.value,
                      nutanix_project_id: e.target.elements.nutanix_project_id.value,
                      payday: parseInt(e.target.elements.payday.value),
                      discount: (e.target.elements.discount.value == "" ? 0 : e.target.elements.discount.value),
                      is_blocked: (e.target.elements.is_blocked.checked ? 1 : 0),
                      blocked_description: e.target.elements.blocked_description.value
                    } });
                  }}
                  >

                <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "name",
                        label: "Nome",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Nome",
                        defaultValue: (this.account_edit ? this.account_edit.name : "")
                      }
                    ]}
                  />

                <FormGroup>
                    <ControlLabel>Projeto Nutanix</ControlLabel>
                    <div>
                      <Query
                          client={account_client}
                          query={GET_NUTANIX_PROJECT}
                          variables={{id:this.nutanix_project_id}}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;

                              var nutanixproject = data.nutanixproject;
                            return (
                              <Select
                                name="nutanix_project_id"
                                options={nutanix_projects ? nutanix_projects : []}
                                className="basic-select"
                                classNamePrefix="select"
                                isClearable={true}
                                defaultValue={nutanixproject ? nutanixproject : ""}
                              />
                              );
                            }}
                      </Query>
                    </div>
                  </FormGroup>




                <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "payday",
                        label: "Dia de faturamento",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Dia de faturamento",
                        defaultValue: (this.account_edit ? this.account_edit.payday : "")
                      }
                    ]}
                  />

                  
                  <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          name: "discount",
                          label: "Desconto (%)",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Desconto (%)",
                          defaultValue: (this.account_edit ? this.account_edit.discount : "")
                        }
                      ]}
                    />

                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="is_blocked"
                        type="checkbox"
                        checked={this.state.checkboxValue_is_blocked}
                        onChange={this.handleCheckboxChange}
                      /> Bloquear conta
                    </ControlLabel>
                  </FormGroup>

                  <div class="form-group" className={this.state.checkboxValue_is_blocked ? '' : 'hidden'}>
                    <label class="control-label">Motivo do bloqueio</label>
                    <textarea rows="8" className="form-control" name="blocked_description">{(this.account_edit ? this.account_edit.blocked_description : "")}</textarea>
                  </div>
                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>



        );
        }}
        </Query>
      );
      }}
      </Query>
    );
  }
}

export default AccountItem;
