import React, { Component } from "react";
import edu_cover from "../../assets/img/edu.jpg";

import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';

import Button from "../CustomButton/CustomButton.jsx";


export class Branching_digitais_category extends Component {


  render() {

    const parseHtml = htmlParser({
      isValidNode: node => node.type !== 'script',
      processingInstructions: [/* ... */]
    })

    return (
      <div className="chat_message_admin">
        <div className="chat_message">
          <div className="chat_answer">
            <div className="chat_thumb"><img src={edu_cover} alt="" /></div>
            <b>Edu</b>
              <div>
                <p>{this.props.message}</p>
                <p>
                  <Button bsStyle="info" onClick={() => { this.props.chatElement.newUserMessage_click("Videoteca") }}>Videoteca</Button>
                  <Button bsStyle="info" onClick={() => { this.props.chatElement.newUserMessage_click("Biblioteca") }}>Biblioteca</Button>
                </p>
              </div>
              
          </div>
        </div>
      </div>
    );
  }
}

export default Branching_digitais_category;
