import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { confirmAlert } from 'react-confirm-alert'; 

import Select from 'react-select';


const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_PROFILE = gql`{
  profiles_collections{
    profiles{
      id
      title
      is_admin
      is_superadmin
    }
  }
}
`;


const DELETE_PROFILE = gql`
   mutation deleteProfile($id: Int!){
    deleteProfile(id:$id) {
       id
     }
   }
 `;



 const GET_CATEGORY_HAS_PROFILE = gql`
  query categoryHasProfile_collection($profile_id: Int!){
    categoryHasProfile_collection(
      profile_id: $profile_id
    ){
      category_has_profile{
        id
        category_id
      }
    }
  }
`;

const CREATE_CATEGORY_HAS_PROFILE = gql`
  mutation createCategoryHasProfile($profile_id: Int!, $category_id: Int!){
    createCategoryHasProfile(
      profile_id: $profile_id,
      category_id:$category_id
    ) {
      id
    }
  }
`;


const DELETE_CATEGORY_HAS_PROFILE = gql`
mutation deleteCategoryHasProfile($profile_id: Int!, $category_id: Int!){
  deleteCategoryHasProfile(
    profile_id: $profile_id,
    category_id:$category_id
  ) {
      id
    }
  }
`;



class ProfileList extends Component {


  state = {
    search: '',
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC"
  }

  toggleOrder = (event) => {
    this.setState({
      order: (this.state.order == 'DESC'? 'ASC' : 'DESC')
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value
    });

  }

  onChange = (inputValue, action, item_id ) => {

    // Adicionar
    if(action.action === 'select-option') {
      account_client.mutate({
        mutation: CREATE_CATEGORY_HAS_PROFILE,
        variables: {
          profile_id: item_id, category_id: action.option.value, 
        }
      });
    }
    // Remover
    else if(action.action === 'remove-value') {
      account_client.mutate({
        mutation: DELETE_CATEGORY_HAS_PROFILE,
        variables: {
          profile_id: item_id, category_id: action.removedValue.value
        }
      });
    }
 
  } 

  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to={'/Profile-new/'}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                Novo Perfil
              </NavLink>
              <Card
                title={"Perfis ativos"}
                profile="Gerenciar profiles ativos"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th>Tipo</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                    <Query
                      client={account_client}
                      query={GET_PROFILE}
                      fetchPolicy='no-cache'
                    >
                      {({ loading, error, data }) => {
                        if (loading) return <tr><td>Carregando...</td></tr>;
                        if (error) return <tr><td>Erro :(</td></tr>;
                          
                          if(!data.profiles_collections) {
                            return (<tr><td>(Vazio)</td></tr>);
                          } 

                          return data.profiles_collections.profiles.map((item) => (
                          
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.title}</td>
                            <td>{item.is_superadmin ? "Super Administrador" : "Administrador"}</td>
                            <td>
                              {
                                !item.is_superadmin &&
                                <a className="btn btn-info" href={getUrl('Profile-has-module',item.id)}>Módulos</a>
                              }
                            </td>
                            <td>
                              {
                                !item.is_superadmin &&
                                <a className="btn btn-info" href={getUrl('Profile-edit',item.id)}>Editar</a>
                              }
                            </td>
                            <td>
                              {
                                !item.is_superadmin &&
                                  <Button bsStyle="danger" onClick={() => {
                                      var r = confirmAlert({
                                      title: '',
                                      message: "Tem certeza que deseja excluir "+item.title,
                                      buttons: [
                                        {
                                          label: 'Sim',
                                          onClick: () => {
                                            // Delete item
                                            account_client.mutate({
                                              mutation: DELETE_PROFILE,
                                              variables: {
                                                id:item.id
                                              }
                                            }).then(
                                              result => {
                                                window.location.reload();
                                            });
                                          }
                                        },
                                        {
                                          label: 'Não'
                                        }
                                      ]
                                    });
                                    
                                  }}>
                                  Excluir
                                </Button>
                              }
                            
                            </td>
                          </tr>
                        ));
                      }}
                    </Query>
                        
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ProfileList;
