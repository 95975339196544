import React, { Component } from "react";
import edu_cover from "../../assets/img/edu.jpg";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';

import Button from "../CustomButton/CustomButton.jsx";

import NpsImg1 from "../../assets/img/smile-1.png";
import NpsImg2 from "../../assets/img/smile-2.png";
import NpsImg3 from "../../assets/img/smile-3.png";

const restLink_chat = new HttpLink({
  uri: "/api/v1/chat/",
  credentials: 'include'
 });
const chat_client = new ApolloClient({
 
 link: restLink_chat,
 cache: new InMemoryCache(),
});

const SAVE_NPS = gql`
  mutation AddTodo($nps: Int!, $chat_id: Int!){
    nps(nps:$nps, chat_id: $chat_id){
      id
    }
  }
`;

export class Nps extends Component {

  constructor() {
    super();
    this.state = {
      nps_value: 0,
    };

    
  }

  nps(nps_vote) {

    this.setState({
      nps_value: nps_vote,
    });

    //Salva a mensagem no banco
    chat_client.mutate({
      mutation: SAVE_NPS,
      variables: {
        nps:nps_vote, chat_id: parseInt(this.props.chat_id)
      }
    });
    
  }

  render() {

    const parseHtml = htmlParser({
      isValidNode: node => node.type !== 'script',
      processingInstructions: [/* ... */]
    })

    return (
      <div className="chat_message_admin">
        <div className="chat_message">
          <div className="chat_answer">
            <div className="chat_thumb"><img src={edu_cover} alt="" /></div>
            <b>Edu</b>
              <div>
                <p>Como você se sente com o atendimento?</p>
                <p>
                  <a className={"nps-item "+(this.state.nps_value == 1 ? "active" : "inactive")} bsStyle="info" onClick={() => { this.nps(1);this.props.chatElement.endNps();  }}><img height={60} src={NpsImg1} /></a>
                  <a className={"nps-item "+(this.state.nps_value == 2 ? "active" : "inactive")} bsStyle="info" onClick={() => { this.nps(2);this.props.chatElement.endNps();  }}><img height={60} src={NpsImg2} /></a>
                  <a className={"nps-item "+(this.state.nps_value == 3 ? "active" : "inactive")} bsStyle="info" onClick={() => { this.nps(3);this.props.chatElement.endNps();  }}><img height={60} src={NpsImg3} /></a>
                </p>
              </div>
              
          </div>
        </div>
      </div>
    );
  }
}

export default Nps;
