import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Table
} from "react-bootstrap";


import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';

import { ProductCard } from "../../components/ProductCard/ProductCard.jsx";

import PayMethod from "../../components/PayMethod/PayMethod.jsx";



import logo from "../../assets/img/logo-thumb.png";
import background1 from "../../assets/img/background1.png";

import windows_logo from "../../assets/img/windows-server-logo.png";
import ubuntu_logo from "../../assets/img/ubuntu_logo.png";
import centos_logo from "../../assets/img/centos-logo.png";
import mariadb_logo from "../../assets/img/mariadb-logo.png";
import sqlserver_logo from "../../assets/img/sqlserver-logo.png";
import lamp_logo from "../../assets/img/lamp-logo.png";


const restLink_vm = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
});
const cloud_client = new ApolloClient({
  link: restLink_vm,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateVm($id: Int!, $title: String!, $cluster_id: Int!, $description: String!, $memory_size_mib: Int!, $num_vcpus_per_socket: Int!, $num_sockets: Int!, $num_threads_per_core: Int!, $image_uuid: String!){
    createOrUpdateVm(id:$id,input:{title: $title, cluster_id:$cluster_id, description:$description, memory_size_mib:$memory_size_mib, num_vcpus_per_socket:$num_vcpus_per_socket, num_sockets: $num_sockets, num_threads_per_core: $num_threads_per_core, image_uuid: $image_uuid}) {
      id
    }
  }
`;

const GET_FLAVORS = gql`
{
  flavors_collections{
    flavors{
      id
      title
      description
      ram
      vcpu
    }
  }
}
`;

const GET_CLUSTERS = gql`
query clusterHasFlavor_collection($flavor_id: Int!){
  clusterHasFlavor_collection(flavor_id: $flavor_id) {
    ClusterHasFlavor{
      id
      cluster{
        value:id
        label:title
      }
    }
  }
}
`;

const GET_IMAGES = gql`
{
  vms_images_collections{
    vm_images{
      value:uuid
      label:name
    }
  }
}
`;

class VmItem extends Component {

  constructor() {
    super();

    this.state = {
      pay_method: false,
      alertInfo: "",
      flavor_id: null,
      image_id: null,
      blueprint_id: null,
      ram: null,
      vcpu: null
    };
    
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      pay_method: true
    });
    
    /*
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
    */
  }

  
  render() {


    // Se estiver na fase do meio de pagamento
    if(this.state.pay_method) {
      return <PayMethod />
    }

    // Se estiver na fase de montar a VM


    if(this.state.flavor_id == null && this.props.match.params.flavor_id) {
      this.setState({ 
        flavor_id: this.props.match.params.flavor_id,
        ram: this.props.match.params.ram,
        vcpu: this.props.match.params.vcpu
      })
    } 

    return (

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateVm, { data }) => (

        <form 
        onSubmit={e => {
          this.handleSubmit(e);
          e.preventDefault();
          
          createOrUpdateVm({variables: { 
            id: 0, 
            title: e.target.elements.title.value,
            description: "",
            memory_size_mib: (e.target.elements.memory_size_mib.value * 1024),
            num_vcpus_per_socket: parseInt(e.target.elements.num_vcpus_per_socket.value),
            num_threads_per_core: 1,
            cluster_id: parseInt(e.target.elements.cluster_id.value) ,
            num_sockets: 1,
            image_uuid: "fd30848b-b6e9-4858-b112-bd35615fae04"
          } });
        }}
        >
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col><br /> </Col>
              <div className="clearfix" />
            </Row>
          </Grid>
          <Grid fluid>

            <Row>
              <Query
                    client={cloud_client}
                    query={GET_FLAVORS}
                    >
                    {({ loading, error, data }) => {
                      if (loading) return <div>Carregando...</div>;
                      if (error) return <div>Erro :(</div>;
                        
                        return data.flavors_collections.flavors.map((flavor) => (
                          <Col md={3}>
                            <ProductCard
                              bgImage={background1}
                              avatar={logo}
                              name={flavor.title}
                              userName=""
                              description={
                                <span>
                                  <br />
                                  <Table className="text-center">
                                    <tbody>
                                      <tr>
                                        <td colSpan={2}>
                                        {flavor.description}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                        {flavor.ram / 1024} GB RAM
                                        </td>
                                        <td>
                                        {flavor.vcpu} vCPU
                                        </td>
                                      </tr>
                                    </tbody>

                                  </Table>
                                  
                                  <br />
                                  <br />

                                  <Button bsStyle={"success "+(this.state.flavor_id == flavor.id ? 'btn-fill' : '')}  onClick={() => {
                                    this.setState({ 
                                      flavor_id: flavor.id,
                                      ram: flavor.ram / 1024,
                                      vcpu: flavor.vcpu
                                    })
                                  }}> 
                                    Selecionar
                                  </Button>

                                </span>
                              }
                              
                            />
                          </Col>
                        ));
                }}
                </Query>

                <Col md={3}>
                  <ProductCard
                    bgImage={background1}
                    avatar={logo}
                    name="Personalizar"
                    userName=""
                    description={
                      <span >
                        <br />
                        <Table className="text-center">
                          <tbody>
                            <tr>
                              <td>
                              Instância para demandas específicas
                              </td>
                            </tr>
                            <tr>
                              <td>
                              Configure abaixo sua instância
                              </td>
                            </tr>
                          </tbody>

                        </Table>
                        
                        <br />
                        <br />

                        <Button bsStyle={"success "+(this.state.flavor_id == 0 ? 'btn-fill' : '')}  onClick={() => {
                          this.setState({ 
                            flavor_id: 0,
                            ram: 1,
                            vcpu: 1
                          })
                        }}> 
                          Selecionar
                        </Button>

                      </span>
                    }
                    
                  />
                </Col>
                

                <Col md={12}>
                <Card
                  title=""
                  content={
                    <div>

                    <div class="form-group" className={this.state.flavor_id !== null ? '' : 'hidden'}>
                      <FormGroup>
                        <ControlLabel>Zona de Disponibilidade</ControlLabel>
                        <div>
                          <Query
                              client={cloud_client}
                              query={GET_CLUSTERS}
                              variables={{flavor_id: this.state.flavor_id}}
                              >
                              {({ loading, error, data }) => {
                                if (loading) return <div>Carregando...</div>;
                                if (error) return <div>Erro :(</div>;

                                var options = [];
                                var defaultValues = null;
                                data.clusterHasFlavor_collection.ClusterHasFlavor.map((ClusterHasFlavor) => {
                                  var cluster = ClusterHasFlavor.cluster;
                                  if(cluster !== null) {
                                    options.push(cluster); 
                                  }
                                  if(this.user_edit && this.user_edit.cluster.id && this.user_edit.cluster.id === cluster.id) {
                                    defaultValues = cluster;
                                  }
                                });

                                return (
                                  <Select
                                    name="cluster_id"
                                    options={options}
                                    className="basic-select"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    defaultValue={defaultValues}
                                  />
                                  );
                                }}
                          </Query>
                        </div>
                      </FormGroup>
                    </div>

                      <div class="form-group" className={this.state.flavor_id == 0 ? '' : 'hidden'}>
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              name: "memory_size_mib",
                              label: "Memória RAM em GB",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Memória RAM em GB",
                              defaultValue: this.state.ram
                            }
                          ]}
                        />
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              name: "num_vcpus_per_socket",
                              label: "Número de VCPUs",
                              type: "number",
                              bsClass: "form-control",
                              placeholder: "Número de VCPUs",
                              defaultValue: this.state.vcpu
                            }
                          ]}
                        />
                      </div>
                     

                      <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                          {
                            required: true,
                            name: "title",
                            label: "Nome da Instância",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Nome da VM",
                            defaultValue: "Minha VM"
                          }
                        ]}
                      />
                      

                      <div className="clearfix" />
                             

                    </div>
                }/>

                <Row>
                 <Col md={12}>
                   <h4>Sistema 
                     :</h4>
                 </Col>
                </Row>

                <Row>
                 <Col md={4}>
                 <ProductCard
                    bgImage={centos_logo}
                    avatar={null}
                    name={null}
                    userName=""
                    description={
                      <span >
                        <Table className="text-center">
                            <tbody>
                              <tr>
                                <td>
                                Sistema Operacional CentOS
                                </td>
                              </tr>
                              <tr>
                                <td>
                                Perfeito para banco de dados<br /><br />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        <br />

                        <Button bsStyle={"success "+(this.state.image_id == 1 ? 'btn-fill' : '')}  onClick={() => {
                          this.setState({ 
                            image_id: 1
                          })
                        }}> 
                          Selecionar
                        </Button>

                      </span>
                    }
                    
                  />
                  </Col>
                  <Col md={4}>
                 <ProductCard
                    bgImage={ubuntu_logo}
                    avatar={null}
                    name={null}
                    userName=""
                    description={
                      <span >
                        <Table className="text-center">
                            <tbody>
                              <tr>
                                <td>
                                Sistema Operacional Unbuntu 20
                                </td>
                              </tr>
                              <tr>
                                <td>
                                Perfeito para aplicações em Apache e Nginx<br /><br />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        <br />

                        <Button bsStyle={"success "+(this.state.image_id == 2 ? 'btn-fill' : '')}  onClick={() => {
                          this.setState({ 
                            image_id: 2
                          })
                        }}> 
                          Selecionar
                        </Button>

                      </span>
                    }
                    
                  />
                  </Col>
                  <Col md={4}>
                 <ProductCard
                    bgImage={windows_logo}
                    avatar={null}
                    name={null}
                    userName=""
                    description={
                      <span >
                        <Table className="text-center">
                            <tbody>
                              <tr>
                                <td>
                                Sistema Operacional Windows
                                </td>
                              </tr>
                              <tr>
                                <td>
                                Perfeito para aplicações em ISS, .NET e banco de dados SQL Server
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        <br />

                        <Button bsStyle={"success "+(this.state.image_id == 3 ? 'btn-fill' : '')}  onClick={() => {
                          this.setState({ 
                            image_id: 3
                          })
                        }}> 
                          Selecionar
                        </Button>

                      </span>
                    }
                  />
                  </Col>
                </Row>

                <Row>
                 <Col md={12}>
                   <h4>Blueprint:</h4>
                 </Col>
                </Row>

                <Row>
                 <Col md={4}>
                 <ProductCard
                    bgImage={sqlserver_logo}
                    avatar={null}
                    name={null}
                    userName=""
                    description={
                      <span >
                        <Table className="text-center">
                            <tbody>
                              <tr>
                                <td>
                                Banco de dados SQL Server
                                </td>
                              </tr>
                              <tr>
                                <td>
                                Perfeito para qualquer plataforma
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        <br />

                        <Button bsStyle={"success "+(this.state.image_id == 4 ? 'btn-fill' : '')}  onClick={() => {
                          this.setState({ 
                            image_id: 4
                          })
                        }}> 
                          Selecionar
                        </Button>

                      </span>
                    }
                    
                  />
                  </Col>
                  <Col md={4}>
                 <ProductCard
                    bgImage={mariadb_logo}
                    avatar={null}
                    name={null}
                    userName=""
                    description={
                      <span >
                        <Table className="text-center">
                            <tbody>
                              <tr>
                                <td>
                                Banco de dados Maria DB
                                </td>
                              </tr>
                              <tr>
                                <td>
                                Perfeito para aplicações em Apache e Nginx
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        <br />

                        <Button bsStyle={"success "+(this.state.image_id == 5 ? 'btn-fill' : '')}  onClick={() => {
                          this.setState({ 
                            image_id: 5
                          })
                        }}> 
                          Selecionar
                        </Button>

                      </span>
                    }
                    
                  />
                  </Col>
                  <Col md={4}>
                 <ProductCard
                    bgImage={lamp_logo}
                    avatar={null}
                    name={null}
                    userName=""
                    description={
                      <span >
                        <Table className="text-center">
                            <tbody>
                              <tr>
                                <td>
                                Blueprint LAMP
                                </td>
                              </tr>
                              <tr>
                                <td>
                                Linux Ubuntu 20, Apache 2, MariaDB 12, PHP 7.3,
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        <br />

                        <Button bsStyle={"success "+(this.state.image_id == 6 ? 'btn-fill' : '')}  onClick={() => {
                          this.setState({ 
                            image_id: 6
                          })
                        }}> 
                          Selecionar
                        </Button>

                      </span>
                    }
                  />
                  </Col>
                </Row>


               <Card
                  title=""
                  content={
                    <Row>
                        <Col md={10}>
                          <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                        </Col>
                        <Col md={2}>
                          { this.state.flavor_id !== null && this.state.image_id &&
                              <Button bsStyle="info" pullRight fill type="submit">
                                Pŕoximo passo
                              </Button>
                          }
                          
                        </Col>
                      </Row>
                }/>
              </Col>
            </Row>

          </Grid>
        </div>
        </form>
      )}
      </Mutation>

      
    );
  }
}

export default VmItem;
