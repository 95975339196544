import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";



import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
  });
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({
  uri: "/api/v1/cloud/",
  credentials: 'include'
  });
const cloud_client = new ApolloClient({
 link: restLink_cloud,
 cache: new InMemoryCache(),
});




class ListProducts extends Component {


  render() {


    return (
      <div className="content">
        <Grid fluid>
                  <div>
                      <div>
                        <Row>
                          <Col lg={12}>
                            <h3>Selecione o serviço:</h3>
                          </Col>
                        </Row>
                        <Row>
                        <Col md={3}>
                            <div className="card card-stats">
                            <div className="content">
                                    <Row>
                                      <Col xs={5}>
                                        <div className="icon-big text-center icon-warning">
                                        <i className="pe-7s-cloud text-info" />
                                        </div>
                                      </Col>
                                      <Col xs={7}>
                                        <div className="numbers">
                                          <p>Movti Compute Cloud</p>
                                          MC2
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="footer">
                                      <hr />
                                      <div className="stats">
                                        Gerencie suas instâncias
                                      </div>
                                       <NavLink
                                              to={'/Vm-Select/'}
                                              className="btn btn-info btn-fill float-right" 
                                              activeClassName=""
                                            >
                                            Adicionar 
                                          </NavLink>
                                        <div className="clear"></div>
                                    </div>
                              </div>
                              </div>
                          </Col>
                        <Col md={3}>
                            <div className="card card-stats">
                            <div className="content">
                                    <Row>
                                      <Col xs={5}>
                                        <div className="icon-big text-center icon-warning">
                                        <i className="pe-7s-server text-info" />
                                        </div>
                                      </Col>
                                      <Col xs={7}>
                                        <div className="numbers">
                                          <p>Movti Backup</p>
                                          WBK
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="footer">
                                      <hr />
                                      <div className="stats">
                                        Serviço de backup
                                      </div>
                                       <NavLink
                                              to={'/new-service/'}
                                              className="btn btn-info btn-fill float-right" 
                                              activeClassName=""
                                            >
                                            Adicionar 
                                          </NavLink>
                                        <div className="clear"></div>
                                    </div>
                              </div>
                              </div>
                          </Col>
                        </Row>
                    </div>
            </div>

      
          
        </Grid>
      </div>
    );
  }
}

export default ListProducts;
