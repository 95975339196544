import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';


const restLink_vmDisk = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
});
const cloud_client = new ApolloClient({
  link: restLink_vmDisk,
  cache: new InMemoryCache(),
});

const ADD_TODO_x = gql`
  mutation createOrUpdateVmDisk($vm_id: Int!, $size: Int!, $vmdisk_uuid: String!){
    createOrUpdateVmDisk(vm_id:$vm_id, size:$size, vmdisk_uuid:$vmdisk_uuid) {
      vmdisk_uuid
      size
    }
  }
`;

const ADD_TODO = gql`
  mutation createOrUpdateVmDisk( $id: Int!, $size: Int!){
    createOrUpdateVmDisk(id: $id, input: {size: $size}) {
      id
    }
  }
`;

const GET_VM_DISK = gql`
  query getVmDisk($id: Int!){
    vmDisk(id: $id) {
      vmdisk_uuid
      device_uuid
      storage_container_uuid
      size
    }
  }
`;

class VmDiskItem extends Component {

  vmDisk_id;
  vmDisk_edit;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      datePublish: new Date(),
      dateUnpublish: null
    };

    this.checkboxValue_database = true;
    
  }

  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_database: !this.state.checkboxValue_database
    }));

  }

 


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    

    return (


      <Query
      client={cloud_client}
      query={GET_VM_DISK}
      variables={{
        id: this.props.match.params.vmdisk_id
      }}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.vmDisk_edit = data.vmDisk;

        return (

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateVmDisk, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
            
              <NavLink
                  to={"/VmDisk/"+this.props.match.params.vm_id}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                VOLTAR
              </NavLink>
              <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                title="Disco"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateVmDisk({variables: { 
                      id: this.props.match.params.vmdisk_id,
                      vm_id: this.props.match.params.vm_id,
                      size: e.target.elements.size.value,
                    } });
                  }}
                  >

                  {this.vmDisk_edit.size &&
                    <strong>Só é possível aumentar o tamanho</strong>
                  }
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "size",
                        label: "Tamanho em GB",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "GB",
                        defaultValue: (this.vmDisk_edit && this.vmDisk_edit.size ? this.vmDisk_edit.size : "") /// 1073741824
                      }
                    ]}
                  />

                  <hr />
                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="is_database"
                        type="checkbox"
                        checked={this.state.checkboxValue_database}
                        onChange={this.handleCheckboxChange}
                      /> Usar para banco de dados
                    </ControlLabel>
                  </FormGroup>

                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default VmDiskItem;
