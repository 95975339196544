import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import { ProductCardIcon } from "../../components/ProductCard/ProductCardIcon.jsx";

const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
  });
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({
  uri: "/api/v1/cloud/",
  credentials: 'include'
});
const cloud_client = new ApolloClient({
 link: restLink_cloud,
 cache: new InMemoryCache(),
});

const COUNT_VM = gql`
{
  vms_count{
    count
  }
}
`;


const COUNT_VM_BY_POWER_STATUS = gql`
  query vms_count($power_state: String!){
    vms_count(power_state: $power_state) {
      count
    }
  }`;

const GET_PRODUCTS = gql`
{
  accountHasProduct_collection {
    accountHasProducts{
      id
      num
      product{
        id
        title
      }
    }
  }
}
`;


const GET_FLAVORS = gql`
{
  flavors_collections{
    flavors{
      id
      title
      description
      ram
      vcpu
    }
  }
}
`;

class Home extends Component {

  state = {
    show_products: false
  };


  render() {


    return (
      <div className="content">
        <Grid fluid>
        
            
          <Query
              client={account_client}
              fetchPolicy='no-cache'
                query={gql`{
                  get_authenticated_user{
                    id
                    name
                    uid
                    account {
                      id
                      ContaAzulCustomerId
                    }
                  }
                }
                `}
            >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                  var get_authenticated_user = data.get_authenticated_user;
                  
                return  (

                  <div>

                  <Query
                    client={cloud_client}
                    fetchPolicy='no-cache'
                      query={gql`{
                        creditCard_collection{
                          creditCards{
                            id
                          }
                        }
                      }
                      `}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return <div>Carregando...</div>;
                      if (error) return <div>Erro :(</div>;

                        var creditCards = data.creditCard_collection.creditCards;

                        return  <div>
                                    <h1>Console de gerenciamento da Movti</h1>

                                    <Card
                                    title="Serviços da Movti"
                                    content={
                                      <div>
                                        <a className="link-icon" onClick={() => {this.setState({show_products: !this.state.show_products})}}>
                                          <strong>
                                            <i className={(this.state.show_products ? "pe-7s-angle-down" : "pe-7s-angle-right")+" icon-small"} />
                                            <span>Todos os serviços</span>
                                          </strong>
                                        </a>

                                        {
                                          this.state.show_products &&
                                          <div className="block-content">
                                            <p><a href="/#/Vm/">MC2 - Movti Compute Cloud</a></p>
                                            <p><a href="/#/new-service/">Solicite backup</a></p>
                                          </div>
                                        }
                                   
                                      </div>
                                    }/>

                                  <Card
                                    title="Criar uma solução"
                                    content={
                                      <div>
                                        
                                        <Row>
                                          <Col md={3} xs={6}>
                                            <ProductCardIcon
                                              icon="pe-7s-cloud"
                                              name="Executar uma máquina virtual"
                                              description="Com MC2"
                                              link='/VM-Select-Trail/'
                                            />
                                          </Col>
                                          <Col md={3} xs={6}>
                                            <ProductCardIcon
                                              icon="pe-7s-server"
                                              name="Movti Backup"
                                              description="Solicite backup"
                                              link='/new-service/'
                                            />
                                          </Col>
                                        </Row>

                                      </div>
                                    }/>

                                  <Card
                                    title="Aprenda a criar"
                                    category="Aprenda a implantar suas soluções com guias detalhados"
                                    content={
                                      <div>
                                        
                                        <Row>
                                          <Col md={3} xs={6}>
                                            <ProductCardIcon
                                              icon="pe-7s-notebook"
                                              name="Sites e aplicativos web"
                                              description="3 vídeos, 3 tutoriais"
                                              link='/home/'
                                            />
                                          </Col>
                                        </Row>

                                      </div>
                                    }/>
                            </div>
                    
                }}
              </Query>
            </div>

            );
          }}
        </Query>
                  
            

            
          
        </Grid>
      </div>
    );
  }
}

export default Home;
