import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel,
  Table
} from "react-bootstrap";

import { Navbar } from "react-bootstrap";


import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import InputMask from 'react-input-mask';

import { ProductCard } from "../../components/ProductCard/ProductCard.jsx";
import ReactLoading from "react-loading";


import logo_thumb from "../../assets/img/logo-thumb.png";
import background1 from "../../assets/img/background1.png";

const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});


const GET_AUTH_USER = gql`
{
  get_authenticated_user {
    id
    account {
      id
      name
    }
  }
}
`;


const REGISTER_STEP_2 = gql`
mutation createAccountByUserStep2($account_type: Int!, $name: String!, $zipcode: String!, $address: String!, $complement: String!, $city: String!, $state: String!, $cellphone: String!,  $company: String!){
  createAccountByUserStep2( account_type:$account_type, name:$name, zipcode:$zipcode, address:$address, complement:$complement, city:$city, state:$state, cellphone:$cellphone, company:$company){
      id
      token
      msg_error
    }
  }
`;


// ######### Fazer o fluxo de recuperar a senha


class RegisterStep2 extends Component {
  
  state = {
    loading: false,
    account_type: 1,
    accepted_terms: false,
    name: "",
    zipcode: "",
    address: "",
    complement: "",
    city: "",
    state: "",
    cellphone: "",
    company: "",
    msg: "",
    msg_type: "info"
  }


  register_step_2 = async () => {

    
      this.setState({
        loading: true
      });

      if(!this.state.accepted_terms) {
        this.setState({
          loading: false,
          msg_type: "warning",
          msg: "Você precisa concordar com os termos"
        });
      }
      else {

        const mutationReturn = (await account_client.mutate({
          variables: {
            account_type: this.state.account_type, 
            name:this.state.name, 
            zipcode:this.state.zipcode, 
            address:this.state.address, 
            complement:this.state.complement, 
            city:this.state.city, 
            state:this.state.state, 
            cellphone:this.state.cellphone, 
            company:this.state.company
          },
          mutation: REGISTER_STEP_2,
          fetchPolicy: 'no-cache'
        })).data.createAccountByUserStep2;


        if(mutationReturn && typeof mutationReturn.token != "undefined" && mutationReturn.token !== null && mutationReturn.token !== "") {
          
            document.cookie = "token_user="+mutationReturn.token;// Grava o coookie
            this.setState({
              loading: false,
              msg_type: "info",
              msg: "Registro feito com sucesso, aguarde, você será redirecionado."
            });
            window.location.replace('/#/registro-passo-3');
            window.location.reload();
          
        } else if(mutationReturn && typeof mutationReturn.msg_error != "undefined") {
          this.setState({
            loading: false,
            msg_type: "warning",
            msg: mutationReturn.msg_error
          });
        }
      }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.register_step_2();
      
  }

  onChange_name = (event) => {
    this.setState({name: event.target.value});
  }
  onChange_zipcode = (event) => {
    this.setState({zipcode: event.target.value});
  }
  onChange_address = (event) => {
    this.setState({address: event.target.value});
  }
  onChange_complement = (event) => {
    this.setState({complement: event.target.value});
  }
  onChange_city = (event) => {
    this.setState({city: event.target.value});
  }
  onChange_state = (event) => {
    this.setState({state: event.target.value});
  }
  onChange_cellphone = (event) => {
      this.setState({cellphone: event.target.value});
  }
  onChange_company = (event) => {
      this.setState({company: event.target.value});
  }

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      accepted_terms: !this.state.accepted_terms
    }));
  }

  handleAccountType = (e) => {
    var value = e.currentTarget.value;
    this.setState(state => ({
      ...state,
      account_type: parseInt(value)
    }));
  }
  

  render() {


    return (
      <div>
      <div className="content content-login">
      <Grid fluid>

      
      <Query
      client={account_client}
      fetchPolicy='no-cache'
      query={GET_AUTH_USER}
      variables={{dam_id:this.dam_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro. Atualize a página.</div>;
           

        var user = data.get_authenticated_user;
        return (

                <div>
                    <h1 className="title-center">Informações de contato</h1>

                  <div className="login-box">
                    <form 
                        onSubmit={e => {
                          this.handleSubmit(e);
                          e.preventDefault();
                        }}
                        >
                          <Row>
                            <Col md={12}>

                            <FormGroup>
                                <ControlLabel>
                                  Tipo de conta
                                </ControlLabel>
                                <div className="form-control">
                                  <input
                                      value={1}
                                      name="account_type"
                                      type="radio"
                                      checked={this.state.account_type === 1}
                                      onChange={this.handleAccountType}
                                    /> Pessoal
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                        value={2}
                                        name="account_type"
                                        type="radio"
                                        checked={this.state.account_type === 2}
                                        onChange={this.handleAccountType}
                                      /> Empresa
                                </div>
                              </FormGroup>

                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_name,
                                  name: "name",
                                  label: "Nome Completo",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Nome Completo",
                                  defaultValue: ""
                                }
                              ]}
                            />

                            {
                                this.state.account_type == 2 &&
                                <div>
                                      <FormInputs
                                    ncols={["col-md-12"]}
                                    proprieties={[
                                      {
                                        onChange:this.onChange_company,
                                        name: "company",
                                        label: "Nome da conta",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "Nome da conta ou empresa",
                                        defaultValue: user.account.name
                                      }
                                    ]}
                                  />
                                </div>
                              }

                          <FormGroup>
                              <ControlLabel>Celular</ControlLabel>
                              <div>
                              <InputMask 
                                className="form-control"
                                mask="(99) 9 9999-9999" 
                                value={this.state.cellphone} 
                                onChange={this.onChange_cellphone} 
                              />
                              </div>
                            </FormGroup>


                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_address,
                                  name: "address",
                                  label: "Endereço",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Rua, Avenida, nome da empresa, A/C",
                                  defaultValue: ""
                                }
                              ]}
                            />
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_complement,
                                  name: "complement",
                                  label: "",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Apartamento, quarto, unidade, edifício, andar etc.",
                                  defaultValue: ""
                                }
                              ]}
                            />
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_city,
                                  name: "city",
                                  label: "Cidade",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Cidade",
                                  defaultValue: ""
                                }
                              ]}
                            />
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_state,
                                  name: "state",
                                  label: "Estado",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Estado",
                                  defaultValue: ""
                                }
                              ]}
                            />

                            <FormGroup>
                              <ControlLabel>CEP</ControlLabel>
                              <div>
                              <InputMask 
                                className="form-control"
                                mask="99999-999" 
                                value={this.state.zipcode} 
                                onChange={this.onChange_zipcode} 
                              />
                              </div>
                            </FormGroup>


                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="accepted_terms"
                                    type="checkbox"
                                    checked={this.state.accepted_terms}
                                    onChange={this.handleCheckboxChange}
                                  /> Marque esta opção para indicar que você leu e concorda com os termos
                                </ControlLabel>
                              </FormGroup>

                              {
                                this.state.loading 
                                ?
                                <ReactLoading className="center" type="bubbles" color="#1DC7EA" />
                                :
                                <Button className="btn-full btn-fill btn btn-info" type="submit">
                                Criar conta e continuar
                                </Button>
                              }

                              
                              
                          </Col>

                        

                          {
                            this.state.msg &&
                              <Col md={12}>
                                <Alert bsStyle={this.state.msg_type}>
                                  {this.state.msg}
                                </Alert>
                              </Col>
                          }
                      </Row>
                          
                  </form>
                </div>
              </div>

        );
        }}
        </Query>

                 
        </Grid>
      </div>
      </div>
    );
  }
}

export default RegisterStep2;
