import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Alert
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

import InputMask from 'react-input-mask';

import amex_thumb from "../../assets/ebanx/amex.svg";
import diners_thumb from "../../assets/ebanx/diners.svg";
import elo_thumb from "../../assets/ebanx/elo.svg";
import hipercard_thumb from "../../assets/ebanx/hipercard.svg";
import master_thumb from "../../assets/ebanx/master.svg";
import visa_thumb from "../../assets/ebanx/visa.svg";

const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
  });
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateCreditCard($id: Int!, $title: String!, $code: String!, $number: String!, $date: String!){
    createOrUpdateCreditCard(id:$id, input:{title:$title, code:$code, number:$number,  date:$date}){
      id
    }
  }
`;

const GET_CREDITCARD = gql`
  query getCreditCard($creditCard_id: Int!){
    creditCard(id:$creditCard_id){
      id, title, code, number, date
    }
  }`;


  

class CreditCardItem extends Component {

  creditCard_id;
  creditCard_edit;
  parent_id;
  back_button;

  state = {
    alertInfo: "",
    cur_type: "company",
    creditCard_number: null,
    creditCard_title: null,
    creditCard_code: null,
    creditCard_date: null,
    creditCard_token: null,
    creditCard_msg: null,
    address_select: 1
  };

  constructor() {
    super();

    
    this.start_is_blocked = true;
    
  }



  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }


  changeType = (e) => {
    this.setState(state => ({
      ...state,
      cur_type: e.value
    }));
  }


  generate_token() {
    /*
    window.EBANX.config.setMode('test'); // Set mode. production/test
    window.window.EBANX.config.setPublishableKey('xjYh5U3MQB_dU5_BazzT5tyOWHkkWnXQ0mwQ3c-AOYJU'); // Set your Publishable key. To identify your site to EBANX API you must start by providing your [publishable key]
    window.EBANX.config.setCountry('br'); // Set your checkout country (see: https://en.wikipedia.org/wiki/ISO_3166-1).

    
    var createTokenCallback = (ebanxResponse) => {
      if (ebanxResponse.data.hasOwnProperty('status')) {
        this.setState({
          creditCard_token: ebanxResponse.data.token,
          creditCard_msg: null
        });

        window.location.replace('/#/registro-passo-4');
        window.location.reload();

      } else {
        var errorMessage = ebanxResponse.error.err.status_message || ebanxResponse.error.err.message;
        this.setState({
          creditCard_token: null,
          creditCard_msg: errorMessage
        });
      }
    }


    window.EBANX.card.createToken({
    card_number: this.state.creditCard_number,
    card_name: this.state.creditCard_title,
    card_due_date: this.state.creditCard_date,
    card_cvv: this.state.creditCard_code
    }, createTokenCallback);
    */

    this.setState({
        creditCard_token: "asdfasdfasdfasfd",
        creditCard_msg: null
    });

    window.location.replace('/#/registro-passo-4');
    window.location.reload();
  }

  onChange_creditCard_number = (event) => {
    this.setState({creditCard_number: event.target.value});
  }
  onChange_creditCard_title = (event) => {
    this.setState({creditCard_title: event.target.value});
  }
  onChange_creditCard_code = (event) => {
    this.setState({creditCard_code: event.target.value});
  }
  onChange_creditCard_date = (event) => {
    this.setState({creditCard_date: event.target.value});
  }


  handleAddressSelect = (e) => {
    var value = e.currentTarget.value;
    this.setState(state => ({
      ...state,
      address_select: parseInt(value)
    }));
  }
  handleCurType = (e) => {
    var value = e.currentTarget.value;
    this.setState(state => ({
      ...state,
      cur_type: value
    }));
  }

  
  
  render() {


    this.creditCard_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.code = 0;

    console.log(this.creditCard_id);

    return (
      <div>
      <div className="content content-login">
      <Grid fluid>
        <h1 className="title-center">Informações de pagamento</h1>

        <div className="login-box">

        <div className="label-title">
          Todos os campos são obrigatórios.
        </div>
        <div className="clear">
          Usamos suas informações de pagamento para verificar sua identidade e apenas para uso que exceda os limites do nível gratuito da Movti. Não cobraremos pelo uso abaixo dos limites do nível gratuito da Movti. Para saber mais sobre as opções de pagamento, consulte nossas perguntas frequentes.
        </div>
        <div className="box-border">
          Depois que você enviar suas informações de pagamento, faremos a cobrança de R$ 3 BRL em seu cartão de crédito para verificar a validade dele. O valor aparecerá como pendente na fatura do cartão de crédito por 3 a 5 dias até que a verificação seja concluída. Depois disso, a cobrança será removida. Você poderá ser redirecionado ao site do seu banco para autorizar essa cobrança de verificação
        </div>

      <Query
              client={account_client}
              fetchPolicy='no-cache'
                query={gql`{
                  get_authenticated_user{
                    id
                    name
                    mail
                    uid
                    account {
                      id
                      name
                      cnpj
                      phone
                      address
                      city
                      state
                      zipcode
                    }
                  }
                }
                `}
            >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                  var get_authenticated_user = data.get_authenticated_user;
                  
                return  (


      <Query
      fetchPolicy='no-cache'
      client={cloud_client}
      query={GET_CREDITCARD}
      variables={{creditCard_id:this.creditCard_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) {console.log(error); return <div>Erro :(</div>}
          
          this.creditCard_edit = data.creditCard;

          if(this.creditCard_edit && this.creditCard_edit.code) {
            this.code = this.creditCard_edit.code;
          }


        return (

         

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateCreditCard, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NotificationSystem ref="notificationSystem" style={style} />
            
              
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateCreditCard({variables: { 
                      id: this.creditCard_id, 
                      title: e.target.elements.title.value,
                      number: e.target.elements.number.value,
                      date: e.target.elements.date.value,
                      code: e.target.elements.code.value
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        onChange:this.onChange_creditCard_number,
                        name: "number",
                        label: "Número do cartão de crédito",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Número do cartão de crédito",
                        defaultValue: (this.state.creditCard_number ? this.state.creditCard_number : "")
                      }
                    ]}
                  />
                  <div>
                    <img className="thumb_ebanx" src={master_thumb} />
                    <img className="thumb_ebanx" src={visa_thumb} />
                    <img className="thumb_ebanx" src={amex_thumb} />
                    <img className="thumb_ebanx" src={elo_thumb} />
                    <img className="thumb_ebanx" src={diners_thumb} />
                    <img className="thumb_ebanx"src={hipercard_thumb} />
                  </div>

                  <FormGroup>
                    <ControlLabel>Data de validade</ControlLabel>
                    <div>
                    <InputMask 
                      name="date"
                      className="form-control"
                      mask="99/9999" 
                      value={this.state.creditCard_date} 
                      onChange={this.onChange_creditCard_date} 
                    />
                    </div>
                  </FormGroup>

                <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        onChange:this.onChange_creditCard_title,
                        name: "title",
                        label: "Nome impresso no cartão",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Nome impresso no cartão",
                        defaultValue: (this.state.creditCard_title ? this.state.creditCard_title : "")
                      }
                    ]}
                  />


                  <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          onChange:this.onChange_creditCard_code,
                          name: "code",
                          label: "Código de Segurança",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Código de Segurança",
                          defaultValue: (this.state.creditCard_code ? this.state.creditCard_code : "")
                        }
                      ]}
                    />

                      <div>
                        <input
                            value={1}
                            name="address_select"
                            type="radio"
                            checked={this.state.address_select === 1}
                            onChange={this.handleAddressSelect}
                          /> Usar meu endereço de contato
                          <div className="spotlight">
                            {get_authenticated_user.account.address} <br />
                            {get_authenticated_user.account.city} {get_authenticated_user.account.state} {get_authenticated_user.account.zipcode}<br />
                            BR
                          </div>
                          
                          <input
                              value={2}
                              name="address_select"
                              type="radio"
                              checked={this.state.address_select === 2}
                              onChange={this.handleAddressSelect}
                            /> Usar um novo endereço
                      </div>

                      {
                        this.state.address_select === 2 && 
                        <div>

                          <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_address,
                                  name: "address",
                                  label: "Endereço",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Rua, Avenida, nome da empresa, A/C",
                                  defaultValue: ""
                                }
                              ]}
                            />
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_complement,
                                  name: "complement",
                                  label: "",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Apartamento, quarto, unidade, edifício, andar etc.",
                                  defaultValue: ""
                                }
                              ]}
                            />
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_city,
                                  name: "city",
                                  label: "Cidade",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Cidade",
                                  defaultValue: ""
                                }
                              ]}
                            />
                            <FormInputs
                              ncols={["col-md-12"]}
                              proprieties={[
                                {
                                  onChange:this.onChange_state,
                                  name: "state",
                                  label: "Estado",
                                  type: "text",
                                  bsClass: "form-control",
                                  placeholder: "Estado",
                                  defaultValue: ""
                                }
                              ]}
                            />

                            <FormGroup>
                              <ControlLabel>CEP</ControlLabel>
                              <div>
                              <InputMask 
                                className="form-control"
                                mask="99999-999" 
                                value={this.state.zipcode} 
                                onChange={this.onChange_zipcode} 
                              />
                              </div>
                            </FormGroup>
                        </div>
                      }


                      
                  

                        
                            <hr />
                            <FormGroup>
                              <ControlLabel>Tipo de registro fiscal</ControlLabel>
                              <div className="form-control form-control-double">
                              <div>
                                <input
                                    value="company"
                                    name="cur_type"
                                    type="radio"
                                    checked={this.state.cur_type === "company" }
                                    onChange={this.handleCurType}
                                  /> CNPJ
                              </div>
                              <div>    
                                  <input
                                      value="person"
                                      name="cur_type"
                                      type="radio"
                                      checked={this.state.cur_type === "person"}
                                      onChange={this.handleCurType}
                                    /> CPF
                              </div>
                              </div>
                            </FormGroup>

                                {
                                  this.state.cur_type === "person" 
                                  ?
                                  <FormGroup>
                                    <ControlLabel>Número do registro fiscal</ControlLabel>
                                    <div>
                                    <InputMask 
                                      name="cpf"
                                      className="form-control"
                                      mask="999.999.999-99" 
                                      //value={get_authenticated_user.uid} 
                                      onChange={this.onChange_cpf} 
                                    />
                                    </div>
                                  </FormGroup>
                                  :
                                  <FormGroup>
                                    <ControlLabel>Número do registro fiscal</ControlLabel>
                                    <div>
                                    <InputMask 
                                      name="cnpj"
                                      className="form-control"
                                      mask="99.999.999/9999-99" 
                                      //value={get_authenticated_user.account.cnpj} 
                                      onChange={this.onChange_cnpj} 
                                    />
                                    </div>
                                  </FormGroup>
                                }
                            

                            <Button bsStyle="info" fill onClick={() => {
                                this.generate_token()
                              }}>
                              Verificar e adicionar
                            </Button>

                            {
                              this.state.creditCard_msg != null &&
                              <Alert  bsStyle="danger">
                                {this.state.creditCard_msg}
                              </Alert>
                            }

                    <div className="clearfix" />
                  </form>
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

        
          );
        }}
        </Query>
      );
      }}
      </Query>

      </div>
      </Grid>
      </div>
      </div>

    );
  }
}

export default CreditCardItem;
