import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import Select from 'react-select';
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";


const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'  
});
const account_cloud = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

const CREATE_STORAGE_CONTAINER_NUTANIX_HAS_ACCOUNT = gql`
  mutation createStorageContainerNutanixHasAccount($account_id: Int!, $storage_id: String!){
    createStorageContainerNutanixHasAccount(account_id:$account_id, storage_id:$storage_id){
      id
    }
  }
`;

const DELETE_STORAGE_CONTAINER_NUTANIX_HAS_ACCOUNT = gql`
mutation deleteStorageContainerNutanixHasAccount($storage_id: String!, $account_id: Int!){
  deleteStorageContainerNutanixHasAccount(storage_id:$storage_id, account_id: $account_id) {
    id
  }
}
`;
const GET_STORAGE_CONTAINER_NUTANIX_HAS_ACCOUNT = gql`
  query storageContainerNutanixHasAccount_collection($account_id: Int!, $cluster_id: Int!) {
    storageContainerNutanixHasAccount_collection(account_id:$account_id, cluster_id:$cluster_id) {
      storagesHasAccount{
        id
        storage{
          value:id
          label:name
        }
      }
    }
  }
`;


const GET_STORAGE_CONTAINER_NUTANIX = gql`
query storageContainerNutanixHasAccount_collection($cluster_id: Int!) {
  storageContainerNutanix_collection(cluster_id:$cluster_id) {
    storages{
      value:id
      label:name
    }
  }
}
`;

const GET_CLUSTERS = gql`
{
  cluster_collection{
    clusters{
      id
      title
    }
  }
}
`;




class StorageContainerNutanixHasAccount extends Component {

  controllerItens = [];
  urlBack;

  constructor() {
    super();
     this.state = {
       defaultOptions : [],
    }

  }

  
  onChange = (inputValue, action ) => {
    
    // Adicionar
    if(action.action === 'select-option') {
      account_cloud.mutate({
        mutation: CREATE_STORAGE_CONTAINER_NUTANIX_HAS_ACCOUNT,
        variables: {
          storage_id: action.option.value, 
          account_id: parseInt(this.props.match.params.account_id)
        }
      });
    } 
    // Remover
    else if(action.action === 'remove-value') {
      
      account_cloud.mutate({
        mutation: DELETE_STORAGE_CONTAINER_NUTANIX_HAS_ACCOUNT,
        variables: {
          storage_id: action.removedValue.value, 
          account_id: parseInt(this.props.match.params.account_id)
        }
      });
    }
 
  } 

  onInputChange = (inputValue, action ) => {

    
    /*
    account_cloud.mutate({
      mutation: CREATE_STORAGE_CONTAINER_NUTANIX_HAS_ACCOUNT,
      variables: {
        account_module_id: inputValue, profile_id: this.props.match.params.account_id
      }
    });
    */
    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    

  }

  render() {
    
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <a
                  href='javascript:history.go(-1)'
                  className="btn btn-info btn-fill"
                >
                VOLTAR
              </a>

              <Card
                title={"Storage Container NUTANIX"}
                category=""
                ctTableFullWidth
                ctTableResponsive
                content={

                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                  }}
                  >
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Cluster</th>
                        <th>Storage Container</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    <Query
                          client={account_cloud}
                          query={GET_CLUSTERS}
                        >
                    {({ loading, error, data }) => {
                      if (loading) return <tr><td>Carregando...</td></tr>;
                      if (error) return <tr><td>Erro :(</td></tr>;
                        
                        if(!data.cluster_collection) {
                          return (<tr><td>(Nenhum Cluster Registrado)</td></tr>);
                        } 

                        return data.cluster_collection.clusters.map((cluster) => (

                              <Query
                                  client={account_cloud}
                                  query={GET_STORAGE_CONTAINER_NUTANIX}
                                  variables={{
                                    cluster_id: cluster.id
                                  }}
                                >
                                  {({ loading, error, data }) => {

                                    if (loading) return <tr><td>Carregando...</td></tr>;
                                    if (error) return <tr><td>Erro :( x</td></tr>;

                                          var select_options = data.storageContainerNutanix_collection.storages;
                                        
                                          return (
                                  
                                          <Query
                                            client={account_cloud}
                                            variables={{
                                              account_id:this.props.match.params.account_id,
                                              cluster_id: cluster.id
                                            }}
                                            query={GET_STORAGE_CONTAINER_NUTANIX_HAS_ACCOUNT}
                                          >
                                            {({ loading, error, data }) => {

                                              if (loading) return <tr><td>Carregando...</td></tr>;
                                              if (error) return <tr><td>Erro :(</td></tr>;

                                                var defaultValues = [];
                                                data.storageContainerNutanixHasAccount_collection.storagesHasAccount.map((StorageContainerNutanixHasAccount) => {
                                                  var StorageContainerNutanixHasAccount = StorageContainerNutanixHasAccount.storage;
                                                  defaultValues.push(StorageContainerNutanixHasAccount); 
                                                });
                                                return (
                                                    <tr>
                                                      <td>
                                                        {cluster.title}
                                                      </td>
                                                      <td>
                                                        <Select
                                                              defaultValue={this.state.defaultValue && this.state.defaultValue ? this.state.defaultValue : defaultValues}
                                                              isMulti
                                                              isSearchable
                                                              name={"values"}
                                                              options={select_options}
                                                              className="basic-multi-select"
                                                              classNamePrefix="select"
                                                              //inputValue={inputValue}
                                                              onChange={this.onChange}
                                                              //onInputChange={this.onInputChange}
                                                              isClearable={false}
                                                            />
                                                      </td>
                                                    </tr>
                                                );
                                            }}
                                          </Query>
                                          );
                                        }}
                                      </Query>


                            ));
                          }}
                        </Query>
                          
                        <tr>
                          <td></td>
                          <td>
                          <Button bsStyle="info" pullRight fill onClick={() => {window.location.reload()}}>
                            Salvar
                          </Button>
                          </td>
                          <td></td>
                        </tr>
                    </tbody>
                  </Table>
                  

                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default StorageContainerNutanixHasAccount;
