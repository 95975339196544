import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Table
} from "react-bootstrap";


import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';


import boleto_thumb from "../../assets/ebanx/boleto.svg";
import amex_thumb from "../../assets/ebanx/amex.svg";
import diners_thumb from "../../assets/ebanx/diners.svg";
import elo_thumb from "../../assets/ebanx/elo.svg";
import hipercard_thumb from "../../assets/ebanx/hipercard.svg";
import master_thumb from "../../assets/ebanx/master.svg";
import visa_thumb from "../../assets/ebanx/visa.svg";


const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
});
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});



class PayMethodItem extends Component {



  constructor() {
    super();
    this.state = {
      alertInfo: "",
      type: null,
      blueprint_id: null,
      ram: null,
      vcpu: null
    };

    
  }



  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {


    return (



        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col><br /> </Col>
              <div className="clearfix" />
            </Row>
          </Grid>
          <Grid fluid>


          <Row>
            <Col md={12}>
              <h4>Forma de pagamento 
                :</h4>
            </Col>
          </Row>

            <Row>
                 <Col md={4}>
                 <Card
                  title="Cartão de crédito nacional"
                  content={
                      <span >
                        <Table className="text-center">
                            <tbody>
                              <tr>
                                <td>
                                  <img className="thumb_ebanx" src={master_thumb} />
                                  <img className="thumb_ebanx" src={visa_thumb} />
                                  <img className="thumb_ebanx" src={amex_thumb} />
                                  <img className="thumb_ebanx" src={elo_thumb} />
                                  <img className="thumb_ebanx" src={diners_thumb} />
                                  <img className="thumb_ebanx"src={hipercard_thumb} />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                <NavLink
                                    to="/CreditCard-new"
                                    className="btn btn-info btn-fill"
                                    activeClassName=""
                                  >
                                  SELECIONAR
                                </NavLink>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                      </span>
                   }/>
                  </Col>
                  <Col md={4}>
                 <Card
                  title="Boleto Bancário - Pré Pago"
                  content={
                      <span >
                        <Table className="text-center">
                            <tbody>
                              <tr>
                                <td>
                                  <img className="thumb_ebanx" src={boleto_thumb} />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                <NavLink
                                    to="/Boleto-new"
                                    className="btn btn-info btn-fill"
                                    activeClassName=""
                                  >
                                  SELECIONAR
                                </NavLink>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                      </span>
                   }/>
                  </Col>
                </Row>

         

          </Grid>
        </div>
    
      
    );
  }
}

export default PayMethodItem;
