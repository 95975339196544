import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';


const restLink_flavor = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
});
const cloud_client = new ApolloClient({
  link: restLink_flavor,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateFlavor($id: Int!, $title: String!, $description: String!, $ram: Int!, $vcpu: Int!){
    createOrUpdateFlavor(id:$id,input:{title: $title, ram:$ram, vcpu:$vcpu, description:$description}) {
      id
    }
  }
`;

const GET_FLAVOR = gql`
  query getFlavor($flavor_id: Int!){
    flavor(id:$flavor_id){
      id
      title
      description
      ram
      vcpu
    }
  }
`;

class FlavorItem extends Component {

  flavor_id;
  flavor_edit;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      datePublish: new Date(),
      dateUnpublish: null
    };


    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    this.start_blockcontent = true;
    
    
  }

  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }

  handleCheckboxChange_blockcontent = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_blockcontent: !this.state.checkboxValue_blockcontent
    }));

  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    if(this.props.match.params.id) {
      this.flavor_id = this.props.match.params.id;
    } else {
      this.flavor_id = 0;
    }

    return (


      <Query
      client={cloud_client}
      query={GET_FLAVOR}
      variables={{flavor_id:this.flavor_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.flavor_edit = data.flavor;

        return (

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateFlavor, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/Flavor"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                title="Modelo"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateFlavor({variables: { 
                      id: this.flavor_id, 
                      title: e.target.elements.title.value,
                      description: e.target.elements.description.value,
                      ram: e.target.elements.ram.value*1024,
                      vcpu: e.target.elements.vcpu.value
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Título",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Título",
                        defaultValue: (this.flavor_edit ? this.flavor_edit.title : "")
                      }
                    ]}
                  />


                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "description",
                        label: "Descrição",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Descrição",
                        defaultValue: (this.flavor_edit ? this.flavor_edit.description : "")
                      }
                    ]}
                  />

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "ram",
                        label: "RAM (GB)",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "RAM (GB)",
                        defaultValue: (this.flavor_edit ? this.flavor_edit.ram / 1024 : "")
                      }
                    ]}
                  />

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "vcpu",
                        label: "VCPUs",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "VCPUs",
                        defaultValue: (this.flavor_edit ? this.flavor_edit.vcpu : "")
                      }
                    ]}
                  />


                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

       
            
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default FlavorItem;
