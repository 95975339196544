import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Table
} from "react-bootstrap";


import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';

import { ProductCard } from "../../components/ProductCard/ProductCard.jsx";

import PayMethod from "../../components/PayMethod/PayMethod.jsx";

import logo from "../../assets/img/logo-thumb.png";
import background1 from "../../assets/img/background1.png";

import windows_logo from "../../assets/img/windows-server-logo.png";
import ubuntu_logo from "../../assets/img/ubuntu_logo.png";
import centos_logo from "../../assets/img/centos-logo.png";
import mariadb_logo from "../../assets/img/mariadb-logo.png";
import sqlserver_logo from "../../assets/img/sqlserver-logo.png";
import lamp_logo from "../../assets/img/lamp-logo.png";


const restLink_vm = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
});
const cloud_client = new ApolloClient({
  link: restLink_vm,
  cache: new InMemoryCache(),
});

const CREATE_VM = gql`
  mutation createOrUpdateVm($id: Int!, $title: String!, $cluster_id: Int!, $vm_image_id: Int!, $flavor_id: Int!){
    createOrUpdateVm(id:$id,input:{title: $title, cluster_id:$cluster_id, vm_image_id:$vm_image_id, flavor_id:$flavor_id}) {
      id
    }
  }
`;

const CREATE_VM_DISK_x = gql`
  mutation createOrUpdateVmDisk($vm_id: Int!, $size: Int!, $device_index: Int!){
    createOrUpdateVmDisk(vm_id: $vm_id, size: $size, device_index: $device_index) {
      id
      device_index
    }
  }
`;

const CREATE_VM_DISK = gql`
  mutation createOrUpdateVmDisk( $vm_id: Int!, $size: Int!, $device_index: Int!){
    createOrUpdateVmDisk(id: 0, input: {vm_id: $vm_id, size: $size, device_index: $device_index}) {
      id
    }
  }
`;


const CREATE_NETWORK = gql`
  mutation createOrUpdateNetwork($qos: Int!){
    createOrUpdateNetwork(input:{qos: $qos}) {
      id
    }
  }
`;

const CREATE_VM_NETWORK = gql`
  mutation createOrUpdateVmNetwork($vm_id: Int!, $firewall_id: Int!, $portRange: String!, $source: String!, $description: String!){
    createOrUpdateVmNetwork(id: 0, input: {vm_id: $vm_id, firewall_id: $firewall_id, portRange: $portRange, source: $source, description: $description}) {
      id
    }
  }
`;


const GET_NETWORK = gql`
  {
    network{
      qos
    }
}
`;


const GET_VMIMAGE = gql`
  {
    vmImage_collection{
      vmImages{
        id, title, thumb, size_bytes
      }
    }
  }
`;

const GET_CLUSTERS = gql`
query clusterHasFlavor_collection($flavor_id: Int!){
  clusterHasFlavor_collection(flavor_id: $flavor_id) {
    ClusterHasFlavor{
      id
      cluster{
        value:id
        label:title
      }
    }
  }
}
`;

const GET_FLAVORS = gql`
{
  flavors_collections{
    flavors{
      id
      title
      description
      ram
      vcpu
      is_custom
    }
  }
}
`;

const GET_FIREWALLS = gql`
query firewall_collection($vm_image_id: Int!){
  firewall_collection(vm_image_id:$vm_image_id) {
    firewalls{
      value:id
      label:title
    }
  }
}
`;

const GET_FIREWALL = gql`
query firewal($id: Int!){
  firewall(id: $id) {
    value:id
    label:title
    protocol
    portDefault
  }
}
`;





class VmSelectTrail extends Component {

  constructor() {
    super();

    this.state = {

      vm_title: "Minha VM",
      vm_cluster_id: 1,

      vm_image: "",
      vm_ram: 0,
      vm_vcpu: 0,
      step_trail: 1,
      pay_method: false,
      alertInfo: "",
      flavor_id: null,
      image_id: null,
      blueprint_id: null,
      ram: null,
      vcpu: null,

      disks: [100],

      firewall_keys: [1],
      firewall_Types: [],
      firewall_PortRanges: [],
      firewall_Sources: [],
      firewall_Descriptions: [],

      vm_qos: 0
    };
    
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      pay_method: true
    });
    
    /*
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
    */
  }

  
  onChange_cluster = (event, i) => {
    this.setState({vm_cluster_id: event.value});
  }
  
  onChange_title = (event) => {
    this.setState({vm_title: event.target.value});
  }
  
  onChange_ram = (event) => {
    this.setState({vm_ram: parseInt(event.target.value) * 1024});
  }
  
  onChange_vcpu = (event) => {
    console.log(event.target.value);
    this.setState({vm_vcpu: parseInt(event.target.value)});
  }

  onChange_qos = (event) => {
    this.setState({vm_qos: event.target.value});
  }

  onChange_storage = storage_key => (event) => {
    var disks = this.state.disks;
    disks[storage_key] = parseInt(event.target.value);
    this.setState({disks: disks});
  }

  onChange_firewall_Types = firewall_key => (event) => {
    if(event && event.value) {
      var firewall_Types = this.state.firewall_Types;
      firewall_Types[firewall_key] = parseInt(event.value);
      this.setState({firewall_Types: firewall_Types});
    }
  }
  onChange_firewall_PortRange = firewall_key => (event) => {
    var firewall_PortRanges = this.state.firewall_PortRanges;
    firewall_PortRanges[firewall_key] = event.target.value;
    this.setState({firewall_PortRanges: firewall_PortRanges});
  }
  onChange_firewall_Sources = firewall_key => (event) => {
    var firewall_Sources = this.state.firewall_Sources;
    firewall_Sources[firewall_key] = event.target.value;
    this.setState({firewall_Sources: firewall_Sources});
  }
  onChange_firewall_Descriptions = firewall_key => (event) => {
    var firewall_Descriptions = this.state.firewall_Descriptions;
    firewall_Descriptions[firewall_key] = event.target.value;
    this.setState({firewall_Descriptions: firewall_Descriptions});
  }
  
  saveVM = async () => {
    
      const create_vm = (await cloud_client.mutate({
        mutation: CREATE_VM,
        variables: {
          id: 0, 
          title: this.state.vm_title,
          cluster_id: parseInt(this.state.vm_cluster_id),
          vm_image_id: parseInt(this.state.vm_image.id),
          flavor_id: parseInt(this.state.flavor_id)
        }
      })).data.createOrUpdateVm;

  
      var create_vm_disk = false;

      // Save Disks
      this.state.disks.map(async (disk_size, disk_key) => {
        create_vm_disk = (await cloud_client.mutate({
          mutation: CREATE_VM_DISK,
          variables: {
            vm_id: create_vm.id, 
            size: disk_size,  
            device_index: disk_key
          }
        })).data.createOrUpdateVmDisk;
      });

      var create_network = (await cloud_client.mutate({
        mutation: CREATE_NETWORK,
        variables: {
          qos: this.state.vm_qos
        }
      }));

      // Save Vm Network
      this.state.firewall_keys.map(async (firewall_key) => {
        var firewall_id = (this.state.firewall_Types[firewall_key] ? this.state.firewall_Types[firewall_key] : false );
        var portRange = (this.state.firewall_PortRanges[firewall_key] ? this.state.firewall_PortRanges[firewall_key] : "");
        var source = (this.state.firewall_Sources[firewall_key] ? this.state.firewall_Sources[firewall_key] : "");
        var description = (this.state.firewall_Descriptions[firewall_key] ? this.state.firewall_Descriptions[firewall_key] : "");
        if(firewall_id) {
          var create_vm_network = (await cloud_client.mutate({
            mutation: CREATE_VM_NETWORK,
            variables: {
              vm_id: create_vm.id,
              firewall_id: firewall_id,
              portRange: portRange,
              source: source,
              description: description
            }
          }));
          }
      });

      this.setState({
        step_trail: 7
      });
      
      
  }
  
  render() {


    // Se estiver na fase do meio de pagamento
    if(this.state.pay_method) {
      return <PayMethod />
    }

    // Se estiver na fase de montar a VM


    if(this.state.flavor_id == null && this.props.match.params.flavor_id) {
      this.setState({ 
        flavor_id: this.props.match.params.flavor_id,
        ram: this.props.match.params.ram,
        vcpu: this.props.match.params.vcpu
      })
    }

    console.log("--------------------------------------");
    console.log(this.state);


    return (


        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col><br /> </Col>
              <div className="clearfix" />
            </Row>
          </Grid>

          <Grid fluid>
            
            {
              this.state.step_trail > 1
              ?
                  <a className="breadcrumb" onClick={() => {
                      this.setState({
                        step_trail: 1
                      });
                  }}>
                    1. Selecione a imagem
                  </a>
              :
                <span>
                  1. Selecione a imagem
                </span>

            }
            &nbsp;&nbsp; &#62; &nbsp;&nbsp;
            {
              this.state.step_trail > 2
              ?
                  <a className="breadcrumb" onClick={() => {
                      this.setState({
                        step_trail: 2
                      });
                  }}>
                    2. Selecione o tipo da instância
                  </a>
              :
                <span>
                  2. Selecione o tipo da instância
                </span>

            }
            &nbsp;&nbsp; &#62; &nbsp;&nbsp;
            {
              this.state.step_trail > 3
              ?
                  <a className="breadcrumb" onClick={() => {
                      this.setState({
                        step_trail: 3
                      });
                  }}>
                    3. Configurações da instância
                  </a>
              :
                <span>
                    3. Configurações da instância
                </span>

            }
            &nbsp;&nbsp; &#62; &nbsp;&nbsp;
            {
              this.state.step_trail > 4
              ?
                  <a className="breadcrumb" onClick={() => {
                      this.setState({
                        step_trail: 4
                      });
                  }}>
                    4. Adicionar Storage
                  </a>
              :
                <span>
                  4. Adicionar Storage
                </span>

            }
            &nbsp;&nbsp; &#62; &nbsp;&nbsp;
            {
              this.state.step_trail > 5
              ?
                  <a className="breadcrumb" onClick={() => {
                      this.setState({
                        step_trail: 5
                      });
                  }}>
                    5. Configurações de Rede
                  </a>
              :
                <span>
                  5. Configurações de Rede
                </span>

            }
            &nbsp;&nbsp; &#62; &nbsp;&nbsp;
            {
              this.state.step_trail > 6
              ?
                  <a className="breadcrumb" onClick={() => {
                      this.setState({
                        step_trail: 6
                      });
                  }}>
                    6. Resumo e confirmação
                  </a>
              :
                <span>
                  6. Resumo e confirmação
                </span>

            }
            
            <br /><br />
          </Grid>

            {
            // ####----------------------------- STEP 1 -----------------------------####
            this.state.step_trail === 1 &&
              <div>
              <Grid fluid>
                  <Card
                      title="Passo 1: Selecione a imagem"
                      content={
                        <div>
                          <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                          <Table striped hover> 
                            <tbody>
                            <Query
                                client={cloud_client}
                                query={GET_VMIMAGE}
                                >
                                {({ loading, error, data }) => {
                                  if (loading) return <div>Carregando...</div>;
                                  if (error) return <div>Erro :(</div>;

                                    return data.vmImage_collection.vmImages.map((vmImage) => (
                                      <tr key={vmImage.id}>
                                        <td width={200}>
                                          <img src={ubuntu_logo} height={50} />
                                        </td>
                                        <td>
                                          {vmImage.title}
                                        </td>
                                        <td>
                                          <div className="pull-right">
                                          <Button bsStyle="info" pullRight fill onClick={() => {
                                            this.setState({
                                              vm_image: vmImage,
                                              step_trail: 2
                                            })
                                          }}>
                                            Selecionar
                                          </Button>
                                          </div>
                                        </td>
                                      </tr>
                                ));
                                }}
                                </Query>
                                
                            </tbody>
                          </Table>
                        </div>
                    }/>

              </Grid>
              </div>
            }

            {
            // ####----------------------------- STEP 2 -----------------------------####
            this.state.step_trail === 2 &&
              <div>
                <Grid fluid>
                    <Card
                        title="Passo 2: Selecione o tipo da instância"
                        content={
                          <div>
                            <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                            <Table striped hover> 
                              <tbody>
                              <Query
                                client={cloud_client}
                                query={GET_FLAVORS}
                                >
                                {({ loading, error, data }) => {
                                  if (loading) return <div>Carregando...</div>;
                                  if (error) return <div>Erro :(</div>;
                                    return data.flavors_collections.flavors.map((flavor) => (
                                        <tr key={flavor.id}>
                                          <td>
                                            <Button className={"btn btn-box btn-info "+(this.state.flavor_id === flavor.id ? "btn-fill" : "")}  onClick={() => {
                                              this.setState({ 
                                                flavor_id: flavor.id,
                                                ram: flavor.ram / 1024,
                                                vcpu: flavor.vcpu,

                                              })
                                            }}> 
                                            </Button>
                                          </td>
                                          <td>
                                            {flavor.title}
                                          </td>

                                          {
                                            flavor.is_custom 
                                            ?
                                              <React.Fragment>
                                                <td>
                                                  <FormInputs
                                                      ncols={["col-md-12"]}
                                                      proprieties={[
                                                        {
                                                          onChange:this.onChange_ram,
                                                          name: "ram",
                                                          label: "Memória RAM (GB)",
                                                          type: "number",
                                                          bsClass: "form-control",
                                                          placeholder: "Memória RAM (GB)",
                                                          defaultValue: 1
                                                        }
                                                      ]}
                                                    />
                                                </td>
                                                <td>
                                                  <FormInputs
                                                      ncols={["col-md-12"]}
                                                      proprieties={[
                                                        {
                                                          onChange:this.onChange_vcpu,
                                                          name: "vcpu",
                                                          label: "VCPU",
                                                          type: "number",
                                                          bsClass: "form-control",
                                                          placeholder: "VCPU",
                                                          defaultValue: 1
                                                        }
                                                      ]}
                                                    />
                                                </td>
                                              </React.Fragment>
                                            :
                                              <React.Fragment>
                                                <td>
                                                  {flavor.ram / 1024} GB RAM
                                                </td>
                                                <td>
                                                  {flavor.vcpu} vCPU
                                                </td>
                                              </React.Fragment>
                                          }
                                          
                                        </tr>
                                ));
                                }}
                                </Query>
                              </tbody>
                            </Table>
                            <div className="bar_bottom">
                              {
                                this.state.flavor_id &&
                                <Button className="pull-right link btn btn-info btn-fill"  onClick={() => {
                                  this.setState({ 
                                    step_trail: 3
                                  })
                                }}> 
                                  Pŕoximo: Configure a instância
                                </Button>
                              }
                              
                              {
                                /*
                                this.state.flavor_id &&
                                <Button className="pull-right link btn btn-success btn-fill"  onClick={() => {
                                  this.setState({ 
                                    step_trail: 5
                                  })
                                }}> 
                                  Revisar e Iniciar
                                </Button>
                                */
                              }
                              <Button className="pull-right link btn btn-fill"  onClick={() => {
                                this.setState({ 
                                  step_trail: 1
                                })
                              }}> 
                                Voltar
                              </Button>
                              <a className="pull-right link btn btn-link" href="/">Cancelar</a>
                            </div>
                          </div>
                      }/>
                </Grid>
              </div>
            }

            {
            // ####----------------------------- STEP 3 -----------------------------####
            this.state.step_trail === 3 &&
              <div>
                <Grid fluid>
                    <Card
                    title="Passo 3: Configurações da instância"
                    content={
                      <div>
                        <FormGroup>
                        <ControlLabel>Zona de Disponibilidade</ControlLabel>
                        <div>
                          <Query
                              client={cloud_client}
                              query={GET_CLUSTERS}
                              variables={{flavor_id: this.state.flavor_id}}
                              >
                              {({ loading, error, data }) => {
                                if (loading) return <div>Carregando...</div>;
                                if (error) return <div>Erro :(</div>;

                                var options = [];
                                var defaultValues = null;
                                if(!data.clusterHasFlavor_collection) {
                                  return <div></div>;
                                }
                                data.clusterHasFlavor_collection.ClusterHasFlavor.map((ClusterHasFlavor) => {
                                  var cluster = ClusterHasFlavor.cluster;
                                  if(cluster !== null) {
                                    options.push(cluster); 
                                  }
                                  if(this.state.vm_cluster_id && this.state.cluster_id === cluster.id) {
                                    defaultValues = cluster;
                                  }
                                });

                                return (
                                  <Select
                                    onChange={this.onChange_cluster}
                                    name="cluster_id"
                                    options={options}
                                    className="basic-select"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    defaultValue={defaultValues}
                                  />
                                  );
                                }}
                          </Query>
                        </div>
                      </FormGroup>


                      <FormInputs
                        ncols={["col-md-12"]}
                        proprieties={[
                          {
                            onChange:this.onChange_title,
                            required: true,
                            name: "title",
                            label: "Nome da Instância",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Nome da VM",
                            defaultValue: this.state.vm_title
                          }
                        ]}
                      />
                      </div>
                    }/>
                </Grid>
                  <div className="bar_bottom">
                    <Button className="pull-right link btn btn-info btn-fill"  onClick={() => {
                      this.setState({ 
                        step_trail: 4
                      })
                    }}> 
                      Pŕoximo: Adicionar Storage
                    </Button>
                    {
                    /*
                    <Button className="pull-right link btn btn-success btn-fill"  onClick={() => {
                      this.setState({ 
                        step_trail: 5
                      })
                    }}> 
                      Revisar e Iniciar
                    </Button>
                    */
                    }
                    <Button className="pull-right link btn btn-fill"  onClick={() => {
                      this.setState({ 
                        step_trail: 2
                      })
                    }}> 
                      Voltar
                    </Button>
                    <a className="pull-right link btn btn-link" href="/">Cancelar</a>
                  </div>
              </div>
            }

            {
            // ####----------------------------- STEP 4 -----------------------------####
            this.state.step_trail === 4 &&
              <div>
                <Grid fluid>
                    <Card
                        title="Passo 4: Adicionar Storage"
                        content={
                          <div>
                            <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                            <Table striped hover> 
                            <thead>
                              <tr>
                                <th>Volume Type</th>
                                <th>Size (GiB)</th>
                                <th>Remover</th>
                              </tr>
                            </thead>
                              <tbody>
                             
                                  {
                                    this.state.disks.map((disk_size, disk_key) => (
                                      <tr key={disk_key}>
                                        <td>
                                          <span className="padding-cell">
                                          {disk_key === 0 ? "Root" : disk_key}
                                          </span>
                                        </td>
                                        <td>
                                        <FormInputs
                                          ncols={["col-md-12"]}
                                          proprieties={[
                                            {
                                              onChange:this.onChange_storage(disk_key),
                                              required: true,
                                              name: "size_default",
                                              label: "",
                                              type: "number",
                                              bsClass: "form-control",
                                              placeholder: "Size (GiB)",
                                              value: disk_size
                                            }
                                          ]}
                                        />
                                        </td>
                                        <td>
                                          <span className="padding-cell">
                                            {
                                            disk_key !== 0
                                              &&
                                              <a onClick={() => {
                                                var disks = this.state.disks;
                                                var disks_new = [];
                                                disks.map((item, i) => {
                                                  console.log(i+" - "+disk_key);
                                                  if(i !== disk_key) {
                                                    disks_new.push(item); 
                                                  }
                                                });
                                                this.setState({
                                                  disks: disks_new
                                                })
                                              }
                                              }>
                                                <i className={"pe-7s-close-circle icon-small color-red bold"} />
                                              </a>
                                            }
                                          
                                          </span>
                                        </td>
                                      </tr>
                                    ))
                                  }
                         
                              </tbody>
                            </Table>
                            <Button className="btn btn-info btn-fill"  onClick={() => {
                                var new_disks = this.state.disks;
                                new_disks.push(8); // Adiciona um novo disco com um valor padrão
                                this.setState({ 
                                  disks: new_disks
                                })
                              }}> 
                                Adicionar Novo Volume
                            </Button>
                            <div className="bar_bottom">
                              <Button className="pull-right link btn btn-info btn-fill"  onClick={() => {
                                this.setState({ 
                                  step_trail: 5 
                                })
                              }}> 
                                Pŕoximo: Configurações de Rede
                              </Button>
                              {
                              /*
                              <Button className="pull-right link btn btn-success btn-fill"  onClick={() => {
                                this.setState({ 
                                  step_trail: 6
                                })
                              }}> 
                                Revisar e Iniciar
                              </Button>
                              */
                              }
                              <Button className="pull-right link btn btn-fill"  onClick={() => {
                                this.setState({ 
                                  step_trail: 3
                                })
                              }}> 
                                Voltar
                              </Button>
                              <a className="pull-right link btn btn-link" href="/">Cancelar</a>
                            </div>
                          </div>
                      }/>
                </Grid>
              </div>
            }

            {
            // ####----------------------------- STEP 5 -----------------------------####
            this.state.step_trail === 5 &&
              <Query
                client={cloud_client}
                query={GET_FIREWALLS}
                variables={{vm_image_id: this.state.vm_image.id}}
                >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;

                    var firewall_collection = data.firewall_collection.firewalls;

                    return  (
                      <Query
                        client={cloud_client}
                        query={GET_NETWORK}
                        >
                        {({ loading, error, data }) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                            var network = data.network;

                            return  (
                                <div>
                                  <Grid fluid>
                                      <Card
                                      title="Passo 5: Configurações de Rede"
                                      content={
                                        <div>
                                            
                                          <FormInputs
                                            ncols={["col-md-12"]}
                                            proprieties={[
                                              {
                                                onChange:this.onChange_qos,
                                                name: "qos",
                                                label: "QOS - Largura de banda para todas instâncias em MB",
                                                type: "number",
                                                bsClass: "form-control",
                                                placeholder: "MB",
                                                defaultValue: this.state.vm_qos ? this.state.vm_qos : (network && network.qos ? network.qos : 1)
                                              }
                                            ]}
                                          />

                                          <Table striped hover>
                                              <thead>
                                                <tr>
                                                  <th>Type</th>
                                                  <th>Protocol</th>
                                                  <th>Port Range</th>
                                                  <th>Source</th>
                                                  <th>Description</th>
                                                  <th></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                              {

                                                this.state.firewall_keys.map((firewall_key) => (
                                                  <Query
                                                  client={cloud_client}
                                                  query={GET_FIREWALL}
                                                  variables={{id: this.state.firewall_Types[firewall_key] ? this.state.firewall_Types[firewall_key] : 0}}
                                                  >
                                                  {({ loading, error, data }) => {
                                                    if (loading) return <div>Carregando...</div>;
                                                    if (error) return <div>Erro :(</div>;

                                                      var firewall = data.firewall;

                                                      return  (
                                                        <tr key={firewall_key}>
                                                        <td>
                                                          <Select
                                                              onChange={this.onChange_firewall_Types(firewall_key)}
                                                              name="firewall_types"
                                                              options={firewall_collection}
                                                              className="basic-select"
                                                              classNamePrefix="select"
                                                              isClearable={false}
                                                              defaultValue={firewall ? firewall : []}
                                                            />
                                                        </td>
                                                        <td>
                                                          {firewall ? firewall.protocol : ""}
                                                        </td>
                                                        <td>
                                                        {
                                                        firewall && firewall.portDefault ? 
                                                        firewall.portDefault : 
                                                        <FormInputs
                                                          ncols={["col-md-12"]}
                                                          proprieties={[
                                                            {
                                                              onChange:this.onChange_firewall_PortRange(firewall_key),
                                                              required: true,
                                                              name: "port_range",
                                                              label: "",
                                                              type: "text",
                                                              bsClass: "form-control",
                                                              placeholder: "Port Range",
                                                              defaultValue: this.state.firewall_PortRanges[firewall_key]
                                                            }
                                                          ]}
                                                        />
                                                        }
                                                        </td>
                                                        <td>
                                                          <FormInputs
                                                              ncols={["col-md-12"]}
                                                              proprieties={[
                                                                {
                                                                  onChange:this.onChange_firewall_Sources(firewall_key),
                                                                  required: true,
                                                                  name: "source",
                                                                  label: "",
                                                                  type: "text",
                                                                  bsClass: "form-control",
                                                                  placeholder: "CIDR, IP or Security Group",
                                                                  defaultValue: this.state.firewall_Sources[firewall_key]
                                                                }
                                                              ]}
                                                            />
                                                        </td>
                                                        <td>
                                                          <FormInputs
                                                              ncols={["col-md-12"]}
                                                              proprieties={[
                                                                {
                                                                  onChange:this.onChange_firewall_Descriptions(firewall_key),
                                                                  required: true,
                                                                  name: "description",
                                                                  label: "",
                                                                  type: "text",
                                                                  bsClass: "form-control",
                                                                  placeholder: "e.g. SSH for Admin Desktop",
                                                                  defaultValue: this.state.firewall_Descriptions[firewall_key]
                                                                }
                                                              ]}
                                                            />
                                                        </td>
                                                        <td>
                                                          
                                                        </td>
                                                      </tr>
                                                      );
                                                    }}
                                                  </Query>
                                                ))
                                              }
                                              </tbody>
                                          </Table>

                                          
                                          <Button className="btn btn-info btn-fill"  onClick={() => {
                                          var new_firewall_keys = this.state.firewall_keys;
                                          var last_key = new_firewall_keys[new_firewall_keys.length - 1];
                                          var firewall_keys = this.state.firewall_keys;
                                          new_firewall_keys.push(last_key+1);
                                            this.setState({ 
                                              firewall_keys: firewall_keys
                                            })
                                          }}> 
                                            Add Rule
                                        </Button>

                                        </div>
                                      }/>
                                  </Grid>
                                    <div className="bar_bottom">
                                      {
                                        this.state.firewall_Types.length &&
                                        <Button className="pull-right link btn btn-success btn-fill"  onClick={() => {
                                          this.setState({ 
                                            step_trail: 6
                                          })
                                        }}> 
                                          Revisar e Iniciar
                                        </Button>
                                      }
                                      
                                      <Button className="pull-right link btn btn-fill"  onClick={() => {
                                        this.setState({ 
                                          step_trail: 4
                                        })
                                      }}> 
                                        Voltar
                                      </Button>
                                      <a className="pull-right link btn btn-link" href="/">Cancelar</a>
                                    </div>
                                </div>
                              );
                            }}
                          </Query>
                  );
                }}
              </Query>
            }
              
            {
            // ####----------------------------- STEP 6 -----------------------------####
            this.state.step_trail === 6 &&
              <div>
                <Grid fluid>
                    <Card
                    title="Passo 6: Resumo e confirmação"
                    content={
                      <div>


                        <h2>Sistema Operacional</h2>
                          <Button className="pull-right link btn btn-link"  onClick={() => {
                              this.setState({ 
                                step_trail: 1
                              })
                            }}> 
                              Editar Sistema Operacional
                          </Button>

                        
                        <p>
                          <strong>{this.state.vm_image.title}</strong>
                        </p>


                        <hr />
                        <h2>Tipo de instância</h2>
                        <Button className="pull-right link btn btn-link"  onClick={() => {
                              this.setState({ 
                                step_trail: 2
                              })
                            }}> 
                              Editar Tipo
                          </Button>
                        <p>
                        Memória RAM: {this.state.ram} GB
                        </p>
                        <p>
                        vCPUs: {this.state.vcpu}
                        </p>


                        <hr />
                        <h2>Discos</h2>
                        <Button className="pull-right link btn btn-link"  onClick={() => {
                            this.setState({ 
                              step_trail: 4
                            })
                          }}> 
                            Editar Discos
                        </Button>
                        <Table striped hover> 
                            <thead>
                              <tr>
                                <th>Volume</th>
                                <th>Size (GiB)</th>
                              </tr>
                            </thead>
                              <tbody>
                                {
                                  this.state.disks.map((disk_size, disk_key) => (
                                    <tr key={1}>
                                      <td>
                                        {disk_key === 0 ? "Root" : disk_key}
                                      </td>
                                      <td>
                                      {disk_size}
                                      </td>
                                    </tr>
                                  ))
                                }
                            </tbody>
                          </Table>


                        <hr />
                        <h2>Rede</h2>
                        <Button className="pull-right link btn btn-link"  onClick={() => {
                            this.setState({ 
                              step_trail: 5
                            })
                          }}> 
                            Editar Rede
                        </Button>
                        <Table striped hover> 
                              <tbody>
                                <tr key={1}>
                                  <td>
                                    <strong>QOS para todas instâncias</strong>
                                  </td>
                                  <td>
                                    {this.state.vm_qos} MB
                                  </td>
                                </tr>
                            </tbody>
                          </Table>

                          <Table striped hover> 
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Protocol</th>
                              <th>Port Range</th>
                              <th>Source</th>
                              <th>Description</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            this.state.firewall_keys.map((firewall_key) => (
                              <Query
                              client={cloud_client}
                              query={GET_FIREWALL}
                              variables={{id: this.state.firewall_Types[firewall_key] ? this.state.firewall_Types[firewall_key] : 0}}
                              >
                              {({ loading, error, data }) => {
                                if (loading) return <div>Carregando...</div>;
                                if (error) return <div>Erro :(</div>;

                                  var firewall = data.firewall;

                                  return  (
                                    <tr key={firewall_key}>
                                    <td>
                                      {firewall.label}
                                    </td>
                                    <td>
                                      {firewall && firewall.protocol ? firewall.protocol : ""}
                                    </td>
                                    <td>
                                    {
                                    firewall && firewall.portDefault ? 
                                      firewall.portDefault 
                                    : 
                                      this.state.firewall_PortRanges[firewall_key] ? this.state.firewall_PortRanges[firewall_key] : ""
                                    }
                                    </td>
                                    <td>
                                      {this.state.firewall_Sources[firewall_key] ? this.state.firewall_Sources[firewall_key] : ""}
                                    </td>
                                    <td>
                                      {this.state.firewall_Descriptions[firewall_key] ? this.state.firewall_Descriptions[firewall_key] : ""}
                                    </td>
                                    <td>
                                    </td>
                                  </tr>
                                  );
                                }}
                              </Query>
                            ))
                            }
                          </tbody>
                          </Table>

                          <div className="space"></div>



                          <div className="bar_bottom">
                            <Button className="pull-right link btn btn-success btn-fill"  onClick={() => {
                              this.saveVM();
                            }}> 
                              Executar
                            </Button>
                            <Button className="pull-right link btn btn-fill"  onClick={() => {
                              this.setState({ 
                                step_trail: 5
                              })
                            }}> 
                              Voltar
                            </Button>
                            <a className="pull-right link btn btn-link" href="/">Cancelar</a>
                          </div>

                      </div>
                    }/>
                </Grid>
              </div>
            }

            {
            // ####----------------------------- STEP 7 -----------------------------####
            this.state.step_trail === 7 &&

              <div>
                <Grid fluid>
                    <Card
                    title="Instância em processamento"
                    content={
                      <div>

                        <h2>Estamos criando a sua instância</h2>
                        <a href="/#/Vm/" className="btn btn-fill btn-success">Ir para Minhas Instâncias</a>

                      </div>
                    }/>
                </Grid>
              </div>
            }

          <div>
            
            
            </div>

        </div>

      
    );
  }
}

export default VmSelectTrail;
