import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel,
  Table
} from "react-bootstrap";

import { Navbar } from "react-bootstrap";


import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import InputMask from 'react-input-mask';

import { ProductCard } from "../../components/ProductCard/ProductCard.jsx";


import logo_thumb from "../../assets/img/logo-thumb.png";
import background1 from "../../assets/img/background1.png";

const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});


const GET_AUTH_USER = gql`
{
  get_authenticated_user {
    id
    account {
      id
      name
    }
  }
}
`;



// ######### Fazer o fluxo de recuperar a senha


class RegisterStep2 extends Component {
  

  render() {


    return (
      <div>
      <div className="content content-login">
      <Grid fluid>

      
      <Query
      client={account_client}
      fetchPolicy='no-cache'
      query={GET_AUTH_USER}
      variables={{dam_id:this.dam_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro. Atualize a página.</div>;
           

        var user = data.get_authenticated_user;
        return (

              <Row>
                <Col md={7}>
                  <h1>Bem-vindo à Movti</h1>
                  <strong>Agradecemos por se cadastrar na Movti.</strong>

                  <p>Agora você pode iniciar sua jornada de transformação digital</p>
                
                  <br /><br /><br /><br /><br /><br />
                </Col>
                <Col md={5}>
                <br /><br />
                  <p>
                    <a className="btn-full btn-fill btn btn-info" href="/">
                      Acesse o Console de Gerenciamento da Movti
                    </a>
                  </p>
                  <p>
                    <a className="btn-full btn-fill btn btn-info" href="/#/contact">
                      Entre em contato com o setor de vendas
                    </a>
                  </p>
                </Col>
              </Row>
                    

                         
                          
        );
        }}
        </Query>

        </Grid>
      </div>
      </div>
    );
  }
}

export default RegisterStep2;
