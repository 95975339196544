import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Table
} from "react-bootstrap";


import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';

import { ProductCard } from "../../components/ProductCard/ProductCard.jsx";

import PayMethod from "../../components/PayMethod/PayMethod.jsx";



import logo from "../../assets/img/logo-thumb.png";
import background1 from "../../assets/img/background1.png";

import windows_logo from "../../assets/img/windows-server-logo.png";
import ubuntu_logo from "../../assets/img/ubuntu_logo.png";
import centos_logo from "../../assets/img/centos-logo.png";
import mariadb_logo from "../../assets/img/mariadb-logo.png";
import sqlserver_logo from "../../assets/img/sqlserver-logo.png";
import lamp_logo from "../../assets/img/lamp-logo.png";


const restLink_vm = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
});
const cloud_client = new ApolloClient({
  link: restLink_vm,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateVm($id: Int!, $title: String!, $cluster_id: Int!, $description: String!, $memory_size_mib: Int!, $num_vcpus_per_socket: Int!, $num_sockets: Int!, $num_threads_per_core: Int!, $image_uuid: String!){
    createOrUpdateVm(id:$id,input:{title: $title, cluster_id:$cluster_id, description:$description, memory_size_mib:$memory_size_mib, num_vcpus_per_socket:$num_vcpus_per_socket, num_sockets: $num_sockets, num_threads_per_core: $num_threads_per_core, image_uuid: $image_uuid}) {
      id
    }
  }
`;

const GET_FLAVORS = gql`
{
  flavors_collections{
    flavors{
      id
      title
      description
      ram
      vcpu
    }
  }
}
`;

const GET_CLUSTERS = gql`
query clusterHasFlavor_collection($flavor_id: Int!){
  clusterHasFlavor_collection(flavor_id: $flavor_id) {
    ClusterHasFlavor{
      id
      cluster{
        value:id
        label:title
      }
    }
  }
}
`;

const GET_IMAGES = gql`
{
  vms_images_collections{
    vm_images{
      value:uuid
      label:name
    }
  }
}
`;

class VmSelectSize extends Component {

  constructor() {
    super();

    this.state = {
      pay_method: false,
      alertInfo: "",
      flavor_id: null,
      image_id: null,
      blueprint_id: null,
      ram: null,
      vcpu: null
    };
    
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      pay_method: true
    });
    
    /*
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
    */
  }

  
  render() {


    // Se estiver na fase do meio de pagamento
    if(this.state.pay_method) {
      return <PayMethod />
    }

    // Se estiver na fase de montar a VM


    if(this.state.flavor_id == null && this.props.match.params.flavor_id) {
      this.setState({ 
        flavor_id: this.props.match.params.flavor_id,
        ram: this.props.match.params.ram,
        vcpu: this.props.match.params.vcpu
      })
    } 

    return (

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateVm, { data }) => (

        <form 
        onSubmit={e => {
          this.handleSubmit(e);
          e.preventDefault();
          
          createOrUpdateVm({variables: { 
            id: 0, 
            title: e.target.elements.title.value,
            description: "",
            memory_size_mib: (e.target.elements.memory_size_mib.value * 1024),
            num_vcpus_per_socket: parseInt(e.target.elements.num_vcpus_per_socket.value),
            num_threads_per_core: 1,
            cluster_id: parseInt(e.target.elements.cluster_id.value) ,
            num_sockets: 1,
            image_uuid: "fd30848b-b6e9-4858-b112-bd35615fae04"
          } });
        }}
        >
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <a href="/#/VM-Select-SO">1. Selecione a imagem</a>
            &nbsp;&nbsp; &#62; &nbsp;&nbsp;
            <a href="/#/VM-Select-Size">2. Selecione o tipo da instância</a>
            &nbsp;&nbsp; &#62; &nbsp;&nbsp;
            <span>3. Configurações da instância</span>
            <br /><br />
          </Grid>
          <Grid fluid>
           
               <Card
                  title="Passo 2: Selecione o tipo da instância"
                  content={
                    <div>

                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />


                      <Table striped hover> 
                        <tbody>
                        <Query
                          client={cloud_client}
                          query={GET_FLAVORS}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              
                              return data.flavors_collections.flavors.map((flavor) => (
                                  <tr key={flavor.id}>
                                    <td>
                                      {flavor.description}
                                    </td>
                                    <td>
                                      {flavor.ram / 1024} GB RAM
                                    </td>
                                    <td>
                                      {flavor.vcpu} vCPU
                                    </td>
                                    <td>
                                      <div className="pull-right">
                                        <a className="btn-fill btn btn-info" href="/#/VM-Select-Config">
                                          Selecionar
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                            ));
                          }}
                          </Query>
                        </tbody>
                      </Table>
                    </div>
                }/>

          </Grid>
        </div>
        </form>
      )}
      </Mutation>

      
    );
  }
}

export default VmSelectSize;
