import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

const restLink_costCustom = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const costCustom_client = new ApolloClient({
  link: restLink_costCustom,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_COST_CUSTOM = gql`
  query getCostCustoms( $account_id: Int!, $limit: Int!, $page: Int!, $order: String!, $orderby: String!){
    costCustom_collection(account_id: $account_id, limit: $limit, page: $page, order: $order, orderby: $orderby){
      costCustoms{
        id
        product{
          id
          title
        }
        cost_format
      }
    }
  }
`;

const COUNT_COST_CUSTOMS = gql`query countCostCustoms( $account_id: Int!){
  costCustom_count(account_id:$account_id){
    count
  }
}
`;

const DELETE_COST_CUSTOM = gql`
  mutation deleteCostCustom($id: Int!){
    deleteCostCustom(id:$id) {
      id
    }
  }
`;




class CostCustomList extends Component {

  state = {
    search: '',
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC"
  }

  toggleOrder = (event) => {
    this.setState({
      order: (this.state.order == 'DESC'? 'ASC' : 'DESC')
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value
    });

  }

  render() {
    
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to={"/Account/"}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                VOLTAR
              </NavLink>
              <NavLink
                  to={'/CostCustom-new/'+this.props.match.params.account_id+'/'}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                Adicionar Valor Customizado 
              </NavLink>
              <Card
                title={"Valores Customizados"}
                costCustom="Gerenciar Valores Customizados"
                ctTableFullWidth
                ctTableResponsive
                content={

                  <Table striped hover>
                    <thead>
                      <tr>
                      <th colSpan={9}>
                            <form 
                            onSubmit={e => {
                              this.handleSubmit(e);
                              e.preventDefault();
                            }}
                            >
                                <Row>
                                  <Col md={6} lg={1}>
                                      <select name="orderby" className="form-control">
                                        <option value="id">ID</option>
                                      </select>
                                  </Col>
                                  <Col md={6} lg={2}>
                                      <select name="order" className="form-control">
                                        <option value="DESC">Decrescente</option>
                                        <option value="ASC" selected={this.state.order == "ASC"}>Crescente</option>
                                      </select>
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Ordenar
                                    </Button>
                                  </Col>
                                </Row>
                            </form>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Produto</th>
                        <th>Valor</th>
                        <th></th>
                        
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={costCustom_client}
                          query={GET_COST_CUSTOM}
                          variables={{
                            account_id: parseInt(this.props.match.params.account_id),
                            limit: this.state.limit, 
                            page: this.state.page, 
                            order: this.state.order, 
                            orderby: this.state.orderby
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.costCustom_collection) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                              return data.costCustom_collection.costCustoms.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.product.title}</td>
                                <td>R$ {item.cost_format}</td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('CostCustom-edit/'+this.props.match.params.account_id,item.id)}>Editar</a>
                                </td>
                                <td>
                                    <Button bsStyle="danger" onClick={() => {
                                          var r = confirmAlert({
                                          title: '',
                                          message: "Tem certeza que deseja excluir "+item.product.title,
                                          buttons: [
                                            {
                                              label: 'Sim',
                                              onClick: () => {
                                                // Delete item
                                                costCustom_client.mutate({
                                                  mutation: DELETE_COST_CUSTOM,
                                                  variables: {
                                                    id:item.id
                                                  }
                                                }).then(
                                                result => {
                                                    window.location.reload();
                                                });
                                              }
                                            },
                                            {
                                              label: 'Não'
                                            }
                                          ]
                                        });
                                      
                                    }}>
                                    Excluir
                                  </Button>
                                
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                        <Query
                          client={costCustom_client}
                          query={COUNT_COST_CUSTOMS}
                          variables={{
                            account_id: parseInt(this.props.match.params.account_id)
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;

                              var pagination_buttons = [];
                              var total_pages = data.costCustom_count.count/this.state.limit;
                              for(var page_num = 1; Math.ceil(total_pages) >= page_num; page_num++){
                                pagination_buttons.push(<spam><spam page_num={page_num} className={"btn_page "+(this.state.page==page_num ? 'btn_page_active' : '')} onClick={e => {this.setState({page: e.target.getAttribute('page_num')})}} >{page_num}</spam> </spam>);
                              }
                            return (
                              <tr key={1}>
                                <td colSpan={6}>
                                  {pagination_buttons}
                                </td>
                              </tr>
                            );
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CostCustomList;
