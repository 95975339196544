import React, { Component } from "react";
import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import { Chat } from "./Chat.jsx";

import chatbotImg from "../../assets/img/chatbot-icon.png";

const restLink_chat = new HttpLink({
  uri: "/api/v1/chat/",
  credentials: 'include'
 });
const chat_client = new ApolloClient({
 link: restLink_chat,
 cache: new InMemoryCache(),
});


const GET_CURRENT_CHAT = gql`
{
  currentChat{
    id
  }
}
`;


export class WidgetChatbot extends Component {

  constructor() {
    super();
    this.state = {
      enableChat: false,
    };
  }

  toogleEnableChat(){
      var enableChat = !this.state.enableChat;
      this.setState({
        enableChat,
      });
  }
  
  render() {

    return (
      <Query
          client={chat_client}
          query={GET_CURRENT_CHAT}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;

            return (
            <div id="pluzbot" className={this.state.enableChat ? "chat-enable" : ""}>
              <div id="pluzbot_chat">
                <Chat enable={this.state.enableChat} chat_id={data.currentChat.id} />
              </div>
              <img id="pluzbot_open" src={chatbotImg} onClick={() => this.toogleEnableChat()} />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default WidgetChatbot;
