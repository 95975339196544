import React, { Component } from "react";
import { Navbar } from "react-bootstrap";

import HeaderLinks from "./HeaderLinks.jsx";

import dashboardRoutes from "../../routes/dashboard.jsx";
import logo from "../../assets/img/logo-white.png";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';


const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
  });
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function() {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }
  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }
  render() {
    return (
      <Query
        client={account_client}
          query={gql`{
            get_authenticated_user{
              id
              first_name
            }
          }
          `}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Olá</div>;
          if (error) return <div>Erro :(</div>;

            var user = data.get_authenticated_user;
          
          if(!user.id) {
            window.location.replace("/#/login");
            return (
              <div></div>
            );
          }

          return  (
                <Navbar fluid className="navbar_blue">
                  <Navbar.Header>
                    <Navbar.Brand>
                    <div className="logo logo_header">
                      <a href="/"><img alt="logo" id="logo_top" src={logo} height="26px" /></a>
                    </div>
                    </Navbar.Brand>
                  </Navbar.Header>
                  <Navbar.Collapse>
                    <HeaderLinks user={user} />
                  </Navbar.Collapse>
                </Navbar>
          );
        }}
    </Query>
    );
  }
}

export default Header;
