import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";



import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
  });
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({
  uri: "/api/v1/cloud/",
  credentials: 'include'
  });
const cloud_client = new ApolloClient({
 link: restLink_cloud,
 cache: new InMemoryCache(),
});

const COUNT_VM = gql`
{
  vms_count{
    count
  }
}
`;


const COUNT_VM_BY_POWER_STATUS = gql`
  query vms_count($power_state: String!){
    vms_count(power_state: $power_state) {
      count
    }
  }`;

const GET_PRODUCTS = gql`
{
  accountHasProduct_collection {
    accountHasProducts{
      id
      num
      product{
        id
        title
      }
    }
  }
}
`;


const GET_FLAVORS = gql`
{
  flavors_collections{
    flavors{
      id
      title
      description
      ram
      vcpu
    }
  }
}
`;

class Home extends Component {


  render() {


    return (
      <div className="content">
        <Grid fluid>
        
            
          <Query
              client={account_client}
              fetchPolicy='no-cache'
                query={gql`{
                  get_authenticated_user{
                    id
                    name
                    uid
                    account {
                      id
                      ContaAzulCustomerId
                    }
                  }
                }
                `}
            >
              {({ loading, error, data }) => {
                if (loading) return <div>Olá</div>;
                if (error) return <div>Erro :(</div>;

                  var get_authenticated_user = data.get_authenticated_user;
                  
                return  (

                  <div>

                 
                  
                      <div>
                        
                        <Row>
                          <Col lg={12}>
                            <h3>Instâncias</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={3} sm={3}>
                            <Query
                              fetchPolicy='no-cache'
                              client={cloud_client}
                              query={COUNT_VM}
                              >
                              {({ loading, error, data }) => {
                                if (loading) return <div>Carregando...</div>;
                                if (error) return <div>Erro :(</div>;

                                  return (
                                    
                                      <StatsCard
                                        bigIcon={<i className="pe-7s-cloud text-info" />}
                                        statsText="Total"
                                        statsValue={data.vms_count.count}
                                        statsIcon={<i className="fa fa-refresh" />}
                                        statsIconText="Atualizado agora"
                                      />
                                  );
                                }
                              }
                              </Query>
                          </Col>
                          <Col lg={3} sm={3}>
                            <Query
                              fetchPolicy='no-cache'
                              client={cloud_client}
                              query={COUNT_VM_BY_POWER_STATUS}
                              variables={{power_state:"ON"}}
                              >
                              {({ loading, error, data }) => {
                                if (loading) return <div>Carregando...</div>;
                                if (error) return <div>Erro :(</div>;

                                  return (
                                    
                                      <StatsCard
                                        bigIcon={<i className="pe-7s-cloud text-success" />}
                                        statsText="Ligadas"
                                        statsValue={data.vms_count.count}
                                        statsIcon={<i className="fa fa-refresh" />}
                                        statsIconText="Atualizado agora"
                                      />
                                  );
                                }
                              }
                              </Query>
                          </Col>
                          <Col lg={3} sm={3}>
                            <Query
                              fetchPolicy='no-cache'
                              client={cloud_client}
                              query={COUNT_VM_BY_POWER_STATUS}
                              variables={{power_state:"PAUSED"}}
                              >
                              {({ loading, error, data }) => {
                                if (loading) return <div>Carregando...</div>;
                                if (error) return <div>Erro :(</div>;

                                  return (
                                    
                                      <StatsCard
                                        bigIcon={<i className="pe-7s-cloud text-warning" />}
                                        statsText="Pausadas"
                                        statsValue={data.vms_count.count}
                                        statsIcon={<i className="fa fa-refresh" />}
                                        statsIconText="Atualizado agora"
                                      />
                                  );
                                }
                              }
                              </Query>
                          </Col>
                          <Col lg={3} sm={3}>
                            <Query
                              fetchPolicy='no-cache'
                              client={cloud_client}
                              query={COUNT_VM_BY_POWER_STATUS}
                              variables={{power_state:"OFF"}}
                              >
                              {({ loading, error, data }) => {
                                if (loading) return <div>Carregando...</div>;
                                if (error) return <div>Erro :(</div>;

                                  return (
                                      <StatsCard
                                        bigIcon={<i className="pe-7s-cloud text-danger" />}
                                        statsText="Desligadas"
                                        statsValue={data.vms_count.count}
                                        statsIcon={<i className="fa fa-refresh" />}
                                        statsIconText="Atualizado agora"
                                      />
                                  );
                                }
                              }
                              </Query>
                          </Col>
                          
                        </Row>

                        <Row>
                          <Col md={12}>
                          <NavLink
                              to={'/Vm/'}
                              className="btn btn-info btn-fill" 
                              activeClassName=""
                            >
                            Gerenciar Instâncias
                          </NavLink>
                          </Col>
                        </Row>

                        
                        <Query
                              fetchPolicy='no-cache'
                              client={cloud_client}
                              query={GET_PRODUCTS}
                              >
                              {({ loading, error, data }) => {
                                if (loading) return <div>Carregando...</div>;
                                if (error) return <div>Erro :(</div>;

                                  if(!data.accountHasProduct_collection.accountHasProducts.length) {
                                    return <div></div>;
                                  }

                                  return  (
                                    <div>
                                      <Row>
                                        <Col lg={12}>
                                          <h3>Produtos</h3>
                                        </Col>
                                      </Row>
                                      <Row>
                                        {
                                          data.accountHasProduct_collection.accountHasProducts.map((item) => (
                                            <Col lg={3} sm={3}>
                                                  <StatsCard
                                                    bigIcon={<i className={(item.product.id == 5 ? "pe-7s-network" : (item.product.id == 8 ? "pe-7s-folder" : (item.product.id == 9 ? "pe-7s-share" : "pe-7s-science")))+" text-info"} />}
                                                    statsText={item.product.title}
                                                    statsValue={item.num}
                                                    statsIcon={<i className="fa fa-refresh" />}
                                                    statsIconText="Atualizado agora"
                                                  />
                                            </Col>
                                          ))
                                        }
                                      </Row>
                                  </div>  

                                );
                              }
                            }
                            </Query>


                       

                    </div>
                    
            </div>

            );
          }}
        </Query>
                  
            

            
          
        </Grid>
      </div>
    );
  }
}

export default Home;
