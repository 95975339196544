import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Alert
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

import InputMask from 'react-input-mask';


const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
  });
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createBoleto($address: String!, $city: String!, $state: String!, $streetComplement: String!, $streetNumber: String!, $zipcode: String!, $amountTotal: String!, $type: String!, $birthdate: String!, $document: String!, $email: String!, $name: String!, $phoneNumber: String!){
    createBoleto(input:{
      address: $address,
      city: $city,
      state: $state,
      streetComplement: $streetComplement,
      streetNumber: $streetNumber,
      zipcode: $zipcode,
      amountTotal: $amountTotal,
      type: $type,
      birthdate: $birthdate,
      document: $document,
      email: $email,
      name: $name,
      phoneNumber: $phoneNumber
    }){
      id
      boleto_url
      msg_error
    }
  }
`;

  

class BoletoItem extends Component {

  boleto_id;
  boleto_edit;
  parent_id;
  back_button;

  state = {
    alertInfo: "",
    cur_type: "company"
  };

  constructor() {
    super();

    
    this.start_is_blocked = true;
    
  }



  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }


  changeType = (e) => {
    this.setState(state => ({
      ...state,
      cur_type: e.value
    }));
  }




  
  render() {

    

    this.boleto_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.code = 0;

    console.log(this.boleto_id);

    return (

      <Query
              client={account_client}
              fetchPolicy='no-cache'
                query={gql`{
                  get_authenticated_user{
                    id
                    uid
                    mail
                    account {
                      id
                      name
                      cnpj
                      phone
                    }
                  }
                }
                `}
            >
              {({ loading, error, data }) => {
                if (loading) return <div>Olá</div>;
                if (error) return <div>Erro :(</div>;

                  var get_authenticated_user = data.get_authenticated_user;
                  
                return  (



      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateBoleto, { data }) => {
        
        var returnMutation = (data && data.createBoleto ? data.createBoleto : null);


        if(returnMutation && returnMutation.id && returnMutation.boleto_url) {
          return (
            <div className="content">
            <Grid fluid>
              <Row>
                <Col md={12}>
                
                  <Card
                    name="Conta"
                    content={
                      <div>
                          <AlertInfo ref="alertSave" alertInfo="Boleto Gerado com sucesso" />
                          <a
                              className="btn btn-info btn-fill"
                              target="_blank"
                              href={returnMutation.boleto_url}
                            >
                            Abrir Boleto
                          </a>
                      </div>
                    }
                    />
                    
                  </Col>
                  
                </Row>
              </Grid>
            </div>
          );
        }
        
      return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/payment-methods"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Conta"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateBoleto({variables: { 
                      address: e.target.elements.address.value,
                      city: e.target.elements.city.value,
                      state: e.target.elements.state.value,
                      streetComplement: e.target.elements.streetComplement.value,
                      streetNumber: e.target.elements.streetNumber.value,
                      zipcode: e.target.elements.cep.value,
                      amountTotal: e.target.elements.amountTotal.value,
                      type: this.state.cur_type,
                      birthdate: e.target.elements.birthdate.value,
                      document: (this.state.cur_type == "personal" ? e.target.elements.cpf.value : e.target.elements.cnpj.value),
                      email: e.target.elements.email.value,
                      name: e.target.elements.name.value,
                      phoneNumber: e.target.elements.phoneNumber.value
                    } });
                  }}
                  >

                            <div>
                              <strong>Dados para pagamento pré pago</strong>
                              <br /><br />
                            </div>

                            <FormGroup>
                              <ControlLabel>Valor*</ControlLabel>
                              <div>
                                  <Select
                                  name="amountTotal"
                                  options={[{'value':"500,00", 'label':"R$ 500,00"}, {'value':"1000,00", 'label':"R$ 1.000,00"}, {'value':"1500,00", 'label':"R$ 1.500,00"}]}
                                  className="basic-select"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  defaultValue={{'value':"500,00", 'label':"R$ 500,00"}}
                                  onChange={this.changeType}
                                />
                              </div>
                            </FormGroup>

                            <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "address",
                                    label: "Endereço*",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Endereço",
                                    defaultValue: (this.boleto_edit ? this.boleto_edit.address : "")
                                  }
                                ]}
                              />
                              <FormInputs
                                  ncols={["col-md-12"]}
                                  proprieties={[
                                    {
                                      name: "streetNumber",
                                      label: "Número*",
                                      bsClass: "form-control",
                                      placeholder: "Endereço",
                                      defaultValue: (this.boleto_edit ? this.boleto_edit.streetNumber : "")
                                    }
                                  ]}
                                />
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "streetComplement",
                                    label: "Complemento",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Complemento",
                                    defaultValue: (this.boleto_edit ? this.boleto_edit.streetComplement : "")
                                  }
                                ]}
                              />
                              <FormInputs
                                  ncols={["col-md-12"]}
                                  proprieties={[
                                    {
                                      name: "city",
                                      label: "Cidade*",
                                      type: "text",
                                      bsClass: "form-control",
                                      placeholder: "Cidade",
                                      defaultValue: (this.boleto_edit ? this.boleto_edit.city : "")
                                    }
                                  ]}
                                />
                               
                                  <FormGroup>
                                  <ControlLabel>Estado*</ControlLabel>
                                  <div>
                                      <Select
                                      name="state"
                                      options={[
                                        {"label": "Acre", "value": "AC"},
                                        {"label": "Alagoas", "value": "AL"},
                                        {"label": "Amapá", "value": "AP"},
                                        {"label": "Amazonas", "value": "AM"},
                                        {"label": "Bahia", "value": "BA"},
                                        {"label": "Ceará", "value": "CE"},
                                        {"label": "Distrito Federal", "value": "DF"},
                                        {"label": "Espírito Santo", "value": "ES"},
                                        {"label": "Goiás", "value": "GO"},
                                        {"label": "Maranhão", "value": "MA"},
                                        {"label": "Mato Grosso", "value": "MT"},
                                        {"label": "Mato Grosso do Sul", "value": "MS"},
                                        {"label": "Minas Gerais", "value": "MG"},
                                        {"label": "Pará", "value": "PA"},
                                        {"label": "Paraíba", "value": "PB"},
                                        {"label": "Paraná", "value": "PR"},
                                        {"label": "Pernambuco", "value": "PE"},
                                        {"label": "Piauí", "value": "PI"},
                                        {"label": "Rio de Janeiro", "value": "RJ"},
                                        {"label": "Rio Grande do Norte", "value": "RN"},
                                        {"label": "Rio Grande do Sul", "value": "RS"},
                                        {"label": "Rondônia", "value": "RO"},
                                        {"label": "Roraima", "value": "RR"},
                                        {"label": "Santa Catarina", "value": "SC"},
                                        {"label": "São Paulo", "value": "SP"},
                                        {"label": "Sergipe", "value": "SE"},
                                        {"label": "Tocantins", "value": "TO"}
                                      ]}
                                      className="basic-select"
                                      classNamePrefix="select"
                                      isClearable={true}
                                      //defaultValue={}
                                    />
                                  </div>
                                </FormGroup>

                                  <FormGroup>
                                    <ControlLabel>CEP*</ControlLabel>
                                    <div>
                                    <InputMask 
                                      name="cep"
                                      className="form-control"
                                      mask="99999-999" 
                                      //value={get_authenticated_user.uid} 
                                      //onChange={this.onChange_cpf} 
                                    />
                                    </div>
                                  </FormGroup>


                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "name",
                                    label: "Nome Completo ou Razão Social*",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Nome Completo",
                                    defaultValue: ( get_authenticated_user.account.name)
                                  }
                                ]}
                              />

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "email",
                                    label: "E-mail*",
                                    type: "mail",
                                    bsClass: "form-control",
                                    placeholder: "Nome Completo",
                                    defaultValue: (this.boleto_edit ? this.boleto_edit.email : get_authenticated_user.mail)
                                  }
                                ]}
                              />

                              <FormGroup>
                                <ControlLabel>Telefone*</ControlLabel>
                                <div>
                                <InputMask 
                                  name="phoneNumber"
                                  className="form-control"
                                  mask="(99) 9999-9999" 
                                  value={get_authenticated_user.account.phone} 
                                  //onChange={this.onChange_cellphone} 
                                />
                                </div>
                              </FormGroup>

                                <FormGroup>
                                  <ControlLabel>Nascimento</ControlLabel>
                                  <div>
                                  <InputMask 
                                    name="birthdate"
                                    className="form-control"
                                    mask="99/99/9999" 
                                    value={get_authenticated_user.account.phone} 
                                    //onChange={this.onChange_cellphone} 
                                  />
                                  </div>
                                </FormGroup>

                          
                            <FormGroup>
                              <ControlLabel>Tipo*</ControlLabel>
                              <div>
                                  <Select
                                  name="type"
                                  options={[{'value':"company", label:"Pessoa Jurídica"}, {'value':"personal", label:"Pessoa Física"}]}
                                  className="basic-select"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  defaultValue={{'value':"company", label:"Pessoa Jurídica"}}
                                  onChange={this.changeType}
                                />
                              </div>
                            </FormGroup>

                                {
                                  this.state.cur_type == "personal" 
                                  ?
                                  <FormGroup>
                                    <ControlLabel>CPF*</ControlLabel>
                                    <div>
                                    <InputMask 
                                      name="cpf"
                                      className="form-control"
                                      mask="999.999.999-99" 
                                      //value={get_authenticated_user.uid} 
                                      onChange={this.onChange_cpf} 
                                    />
                                    </div>
                                  </FormGroup>
                                  :
                                  <FormGroup>
                                    <ControlLabel>CNPJ*</ControlLabel>
                                    <div>
                                    <InputMask 
                                      name="cnpj"
                                      className="form-control"
                                      mask="99.999.999/9999-99" 
                                      value={get_authenticated_user.account.cnpj} 
                                      onChange={this.onChange_cnpj} 
                                    />
                                    </div>
                                  </FormGroup>
                                }
                            

                                  <Row>
                                    <Col md={8}>
                                      {
                                        returnMutation != null && returnMutation.msg_error && returnMutation.msg_error != null && returnMutation.msg_error != "" 
                                        &&
                                          <Alert  bsStyle="danger">
                                            {returnMutation.msg_error}
                                          </Alert>
                                      }
                                      {
                                        returnMutation != null && returnMutation.id 
                                        &&
                                        <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />

                                      }

                                    </Col>
                                    <Col md={4}>
                                    
                                          <Button bsStyle="info" pullRight fill type="submit">
                                            Salvar
                                          </Button>
                                    </Col>
                                  </Row>
                               

                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}}
      </Mutation>

        
          );
        }}
        </Query>
    );
  }
}

export default BoletoItem;
