import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Alert
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

import InputMask from 'react-input-mask';


const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
  });
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});

const restLink_cloud = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const cloud_client = new ApolloClient({
  link: restLink_cloud,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateCreditCard($id: Int!, $title: String!, $code: String!, $number: String!, $date: String!){
    createOrUpdateCreditCard(id:$id, input:{title:$title, code:$code, number:$number,  date:$date}){
      id
    }
  }
`;

const GET_CREDITCARD = gql`
  query getCreditCard($creditCard_id: Int!){
    creditCard(id:$creditCard_id){
      id, title, code, number, date
    }
  }`;


  

class CreditCardItem extends Component {

  creditCard_id;
  creditCard_edit;
  parent_id;
  back_button;

  state = {
    alertInfo: "",
    cur_type: "company",
    creditCard_number: null,
    creditCard_title: null,
    creditCard_code: null,
    creditCard_date: null,
    creditCard_token: null,
    creditCard_msg: null,
  };

  constructor() {
    super();

    
    this.start_is_blocked = true;
    
  }



  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }


  changeType = (e) => {
    this.setState(state => ({
      ...state,
      cur_type: e.value
    }));
  }


  generate_token() {
    /*
    window.EBANX.config.setMode('test'); // Set mode. production/test
    window.window.EBANX.config.setPublishableKey('xjYh5U3MQB_dU5_BazzT5tyOWHkkWnXQ0mwQ3c-AOYJU'); // Set your Publishable key. To identify your site to EBANX API you must start by providing your [publishable key]
    window.EBANX.config.setCountry('br'); // Set your checkout country (see: https://en.wikipedia.org/wiki/ISO_3166-1).

    var createTokenCallback = (ebanxResponse) => {
      if (ebanxResponse.data.hasOwnProperty('status')) {
        this.setState({
          creditCard_token: ebanxResponse.data.token,
          creditCard_msg: null
        });

      } else {
        var errorMessage = ebanxResponse.error.err.status_message || ebanxResponse.error.err.message;
        this.setState({
          creditCard_token: null,
          creditCard_msg: errorMessage
        });
      }
    }


    window.EBANX.card.createToken({
    card_number: this.state.creditCard_number,
    card_name: this.state.creditCard_title,
    card_due_date: this.state.creditCard_date,
    card_cvv: this.state.creditCard_code
    }, createTokenCallback);
    */

    // Excluir esse setState quando voltar com a integração
   this.setState({
      creditCard_token: "lkasjfdlkasjflkasjd",
      creditCard_msg: null
    });
  }

  onChange_creditCard_number = (event) => {
    this.setState({creditCard_number: event.target.value});
  }
  onChange_creditCard_title = (event) => {
    this.setState({creditCard_title: event.target.value});
  }
  onChange_creditCard_code = (event) => {
    this.setState({creditCard_code: event.target.value});
  }
  onChange_creditCard_date = (event) => {
    this.setState({creditCard_date: event.target.value});
  }

  
  render() {


    this.creditCard_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.code = 0;

    console.log(this.creditCard_id);

    return (

      <Query
              client={account_client}
              fetchPolicy='no-cache'
                query={gql`{
                  get_authenticated_user{
                    id
                    uid
                    account {
                      id
                      name
                      cnpj
                      phone
                    }
                  }
                }
                `}
            >
              {({ loading, error, data }) => {
                if (loading) return <div>Olá</div>;
                if (error) return <div>Erro :(</div>;

                  var get_authenticated_user = data.get_authenticated_user;
                  
                return  (


      <Query
      fetchPolicy='no-cache'
      client={cloud_client}
      query={GET_CREDITCARD}
      variables={{creditCard_id:this.creditCard_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) {console.log(error); return <div>Erro :(</div>}
          
          this.creditCard_edit = data.creditCard;

          if(this.creditCard_edit && this.creditCard_edit.code) {
            this.code = this.creditCard_edit.code;
          }


        return (

         

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateCreditCard, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/payment-methods"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Conta"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateCreditCard({variables: { 
                      id: this.creditCard_id, 
                      title: e.target.elements.title.value,
                      number: e.target.elements.number.value,
                      date: e.target.elements.date.value,
                      code: e.target.elements.code.value
                    } });
                  }}
                  >

                <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        onChange:this.onChange_creditCard_title,
                        name: "title",
                        label: "Nome impresso no cartão",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Nome impresso no cartão",
                        defaultValue: (this.state.creditCard_title ? this.state.creditCard_title : "")
                      }
                    ]}
                  />


                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        onChange:this.onChange_creditCard_number,
                        name: "number",
                        label: "Número do Cartão",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Número do Cartão",
                        defaultValue: (this.state.creditCard_number ? this.state.creditCard_number : "")
                      }
                    ]}
                  />

                  <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          onChange:this.onChange_creditCard_code,
                          name: "code",
                          label: "Código de Segurança",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Código de Segurança",
                          defaultValue: (this.state.creditCard_code ? this.state.creditCard_code : "")
                        }
                      ]}
                    />

                <FormGroup>
                    <ControlLabel>Vencimento</ControlLabel>
                    <div>
                    <InputMask 
                      name="date"
                      className="form-control"
                      mask="99/9999" 
                      value={this.state.creditCard_date} 
                      onChange={this.onChange_creditCard_date} 
                    />
                    </div>
                  </FormGroup>

                      {
                        this.state.creditCard_msg != null &&
                        <Alert  bsStyle="danger">
                           {this.state.creditCard_msg}
                        </Alert>
                      }
                  

                  {
                    this.state.creditCard_token == null 
                    ?
                      <Button bsStyle="info" fill onClick={() => {
                          this.generate_token()
                        }}>
                        Validar cartão
                      </Button>
                    :
                    <Alert  bsStyle="info">
                      Cartão validado com sucesso, preencha os dados abaixo.
                    </Alert>
                  }
                        
                            <hr />
                            <div>
                              <strong>Dados de cobrança</strong>
                            </div>

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "text",
                                    label: "Nome Completo",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Nome Completo",
                                    defaultValue: ( get_authenticated_user.account.name)
                                  }
                                ]}
                              />

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "mail",
                                    label: "E-mail",
                                    type: "mail",
                                    bsClass: "form-control",
                                    placeholder: "Nome Completo",
                                    defaultValue: (this.creditCard_edit ? this.creditCard_edit.name : "")
                                  }
                                ]}
                              />

                            <FormGroup>
                              <ControlLabel>Telefone</ControlLabel>
                              <div>
                              <InputMask 
                                name="cellphone"
                                className="form-control"
                                mask="(99) 9999-9999" 
                                value={get_authenticated_user.account.phone} 
                                //onChange={this.onChange_cellphone} 
                              />
                              </div>
                            </FormGroup>

                          

                            <FormGroup>
                              <ControlLabel>Tipo</ControlLabel>
                              <div>
                                  <Select
                                  name="type"
                                  options={[{'value':"company", label:"Pessoa Jurídica"}, {'value':"person", label:"Pessoa Física"}]}
                                  className="basic-select"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  defaultValue={{'value':"company", label:"Pessoa Jurídica"}}
                                  onChange={this.changeType}
                                />
                              </div>
                            </FormGroup>

                                {
                                  this.state.cur_type == "person" 
                                  ?
                                  <FormGroup>
                                    <ControlLabel>CPF</ControlLabel>
                                    <div>
                                    <InputMask 
                                      name="cpf"
                                      className="form-control"
                                      mask="999.999.999-99" 
                                      //value={get_authenticated_user.uid} 
                                      onChange={this.onChange_cpf} 
                                    />
                                    </div>
                                  </FormGroup>
                                  :
                                  <FormGroup>
                                    <ControlLabel>CNPJ</ControlLabel>
                                    <div>
                                    <InputMask 
                                      name="cnpj"
                                      className="form-control"
                                      mask="99.999.999/9999-99" 
                                      //value={get_authenticated_user.account.cnpj} 
                                      onChange={this.onChange_cnpj} 
                                    />
                                    </div>
                                  </FormGroup>
                                }
                            

                            {
                                this.state.creditCard_token != null 
                                ?
                                  <Row>
                                    <Col md={8}>
                                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                                    </Col>
                                    <Col md={4}>
                                    
                                          <Button bsStyle="info" pullRight fill type="submit">
                                            Salvar
                                          </Button>
                                    </Col>
                                  </Row>
                                :
                                  <div>
                                    <strong>Valide o cartão, para salvar</strong>
                                  </div>
                              }

                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

        
          );
        }}
        </Query>
      );
      }}
      </Query>
    );
  }
}

export default CreditCardItem;
