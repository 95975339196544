import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { confirmAlert } from 'react-confirm-alert'; 



class Support extends Component {

  render() {
    
    return (


                  <div>

                        <div className="content">
                          <Grid fluid>
                            <Row>
                              <Col md={12}>
                                <Card
                                  title={"Suporte"}
                                  ctTableFullWidth
                                  ctTableResponsive
                                  content={
                                    <Table striped hover>
                                      <tbody>
                                        <tr>
                                          <td>E-mail</td>
                                          <td>suporte-cloud@movti.com.br</td>
                                          <td>Segunda à sexta, 9hrs - 18hrs</td>
                                        </tr>
                                        <tr>
                                          <td>Telefone</td>
                                          <td>(xx) x xxxx-xxxx</td>
                                          <td>Plantão: 24hrs por dia, 7 dias da semana</td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  }
                                />
                              </Col>
                            </Row>
                          </Grid>
                        </div>
                  </div>
    );
  }
}

export default Support;
