import React, { Component } from "react";

export class ProductCard extends Component {
  render() {
    return (
      <div className="card card-user">
        <div className="image">
          <img src={this.props.bgImage} alt="..." />
        </div>
        <div className="content">
          
            {
              this.props.avatar != null ?
              <div className="author">
              <img
                className="avatar border-gray"
                src={this.props.avatar}
                alt="..."
              />
              {
                this.props.name != null &&
                <h4 className="title">
                  {this.props.name}
                </h4>
              }
              </div>
              :
              <div>
                {
                this.props.name != null &&
                <h4 className="title">
                  {this.props.name}
                </h4>
              }
              </div>
            }
              
              
          
          <p className="description text-center">{this.props.description}</p>
        </div>
        <hr />
        <div className="text-center">{this.props.socials}</div>
      </div>
    );
  }
}

export default ProductCard;
