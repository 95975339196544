import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import Select from 'react-select';
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";

import { confirmAlert } from 'react-confirm-alert'; 
import Button from "../../components/CustomButton/CustomButton.jsx";


const restLink_account = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'  
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});



const DELETE_ALL_VALUES = gql`
mutation deleteAllValues($account_field_id: Int!, $module_name: String!){
  deleteAllSegmentation(
    account_field_id: $account_field_id,
    module_name:$module_name
  ) {
      count
    }
  }
`;



class SegmentationImport extends Component {

  controllerItens = [];
  urlBack;

  constructor() {
    super();
     this.state = {
       defaultOptions : []
    }

  }

  render() {
    
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <a
                  href='javascript:history.go(-1)'
                  className="btn btn-info btn-fill"
                >
                VOLTAR
              </a>
              <NavLink
                  to={'/segmentation/'+this.props.match.params.module_name+"/"+this.props.match.params.id}
                  className="btn btn-success "
                  activeClassName=""
                >
                Segmentar por listas
              </NavLink>
              <NavLink
                  to={'/segmentation-import/'+this.props.match.params.module_name+"/"+this.props.match.params.id}
                  className="btn btn-success btn-fill"
                  activeClassName=""
                >
                Segmentar por importação
              </NavLink>

                  <Card
                    title={this.props.match.params.module_name+" - Segmentação"}
                    category="IMPORTANTE: Os arquivos de importação devem ser de texto (.txt), com cada item em uma linha, no arquivo deve conter apenas o que for importado."
                    ctTableFullWidth
                    ctTableResponsive
                  />

                  <Query
                    client={account_client}
                    query={gql`
                    {
                      get_account_fields(type: "import"){
                        accountfields{
                          id, field, type
                        }
                      }
                    }
                    `}
                  >
                    {({ loading, error, data }) => {

                      if (loading) return <tr><td>Carregando...</td></tr>;
                      if (error) return <tr><td>Erro :(</td></tr>;
                        
                              return data.get_account_fields.accountfields.map((account_field) => (

                                    <div key={account_field.id}>
                                        <Card
                                          title={account_field.field}
                                          category="Importação"
                                          ctTableFullWidth
                                          ctTableResponsive
                                          content={
                                            <div>
                                                        <Col md={12}>
                                                          <Button bsStyle="danger" onClick={() => {
                                                                confirmAlert({
                                                                title: '',
                                                                message: "Tem certeza que deseja excluir todos dentro de: "+account_field.field,
                                                                buttons: [
                                                                  {
                                                                    label: 'Sim',
                                                                    onClick: () => {
                                                                      console.log(account_field.id);
                                                                      console.log(this.props.match.params.module_name);
                                                                      account_client.mutate({
                                                                        mutation: DELETE_ALL_VALUES,
                                                                        variables: {
                                                                          account_field_id: account_field.id,
                                                                          module_name: this.props.match.params.module_name
                                                                        }
                                                                      }).then(
                                                                        result => {
                                                                          window.location.reload();
                                                                      });
                                                                    }
                                                                  },
                                                                  {
                                                                    label: 'Não'
                                                                  }
                                                                ]
                                                              });
                                                              
                                                            }}>
                                                            Apagar todos
                                                          </Button>
                                                        </Col>
                                                        <div className="clear"></div>
                                                        <hr />
                                                        
                                                        <Col md={12}>
                                                          <form target="_blank" action="/api/v1/account/import-segmentation" method="post" encType='multipart/form-data'>
                                                        
                                                          <input type="hidden" name="returnUrl" value={"/"+window.location.hash} />
                                                          <input type="hidden" name="id" value={this.props.match.params.id} />
                                                          <input type="hidden" name="module_name" value={this.props.match.params.module_name} />
                                                          <input type="hidden" name="account_field_id" value={account_field.id} />

                                                          <input
                                                          type="file"
                                                          name="file"
                                                          required
                                                          />

                                                          <Row>
                                                            <Col md={2}>
                                                              <Button bsStyle="info" pullRight fill type="submit">
                                                                Upload
                                                              </Button>
                                                            </Col>
                                                          </Row>
                                                            <div className="clearfix" />
                                                          </form>
                                                          </Col>

                                                        <Query
                                                          client={account_client}
                                                          variables={{account_field_id: account_field.id, module_name:this.props.match.params.module_name, item_id:this.props.match.params.id}}
                                                          query={gql`
                                                          query getItens($account_field_id: Int!, $module_name: String!, $item_id: Int!){
                                                            get_segmentations(account_field_id: $account_field_id, module_name:$module_name, item_id:$item_id){
                                                              segmentations{
                                                                account_field_value {
                                                                  value
                                                                  externalid
                                                                }
                                                              }
                                                            }
                                                          }
                                                          `}
                                                        >
                                                          {({ loading, error, data }) => {
                                
                                                            if (loading) return <tr><td>Carregando...</td></tr>;
                                                            if (error) return <tr><td>Erro :(</td></tr>;
                                
                                                              return data.get_segmentations.segmentations.map((item) => (

                                                                <Col md="2">
                                                                  <div><strong>{item.account_field_value.value}</strong></div>
                                                                  ID Externo: {item.account_field_value.externalid}
                                                                </Col>
                                                                  
                                                              ));
                                                            }}
                                                      </Query>
                                                <div className="clear"></div>
                                            </div>
                                          }
                                    />
                                    
                                </div>
                          ));
                         }}
                       </Query>

                     
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default SegmentationImport;
