import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Alert,
  Table
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';


const restLink_vm = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
});
const cloud_client = new ApolloClient({
  link: restLink_vm,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateVm($id: Int!, $flavor_id: Int!){
    createOrUpdateVm(id:$id,input:{flavor_id: $flavor_id}) {
      id
    }
  }
`;

const GET_VM = gql`
  query getVm($vm_id: Int!){
    vm(id:$vm_id){
      id
      title
      flavor{
        value:id
        label:title
      }
    }
  }
`;

const GET_FLAVORS = gql`
{
  flavors_collections{
    flavors{
      id
      title
      description
      ram
      vcpu
    }
  }
}
`;

class VmItem extends Component {

  vm_id;
  vm_edit;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      datePublish: new Date(),
      dateUnpublish: null
    };


    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    this.start_blockcontent = true;
    
    
  }

  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }

  handleCheckboxChange_blockcontent = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_blockcontent: !this.state.checkboxValue_blockcontent
    }));

  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    if(this.props.match.params.id) {
      this.vm_id = this.props.match.params.id;
    } else {
      this.vm_id = 0;
    }

    return (


      <Query
      client={cloud_client}
      query={GET_VM}
      variables={{vm_id:this.vm_id}}
      fetchPolicy='no-cache'
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.vm_edit = data.vm;

        return (

      <Mutation mutation={ADD_TODO} client={cloud_client}>
      {(createOrUpdateVm, { data }) => {
      
        console.log("-------------- MUTATION -----------------");
        console.log(data);

      return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
            
              <NavLink
                  to="/Vm"
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                VOLTAR
              </NavLink>
              <NotificationSystem ref="notificationSystem" style={style} />

            
              <Card
                title={"Instância: "+this.vm_edit.title}
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateVm({variables: { 
                      id: this.vm_id, 
                      flavor_id: parseInt(e.target.elements.flavor_id.value)
                    } });
                  }}
                  >

                <FormGroup>
                    <ControlLabel>Tipo de instância</ControlLabel>
                    <div>
                      <Query
                          client={cloud_client}
                          fetchPolicy='no-cache'
                          query={gql`{
                            flavors_collections{
                              flavors{
                                value:id
                                label:title
                              }
                            }
                          }`}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                            return (
                              <Select
                                name="flavor_id"
                                options={data.flavors_collections ? data.flavors_collections.flavors : []}
                                className="basic-select"
                                classNamePrefix="select"
                                isClearable={true}
                                defaultValue={this.vm_edit && this.vm_edit ? this.vm_edit.flavor : ""}
                              />
                              );
                            }}
                      </Query>
                    </div>
                  </FormGroup>

                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

            </Col>
            <Table striped hover> 
              <tbody>
              <Query
                client={cloud_client}
                query={GET_FLAVORS}
                fetchPolicy='no-cache'
                >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error) return <div>Erro :(</div>;
                    return data.flavors_collections.flavors.map((flavor) => (
                        <tr key={flavor.id}>
                          <td>
                            {flavor.title}
                          </td>
                          <td>
                            {flavor.ram / 1024} GB RAM
                          </td>
                          <td>
                            {flavor.vcpu} vCPU
                          </td>
                        </tr>
                ));
                }}
                </Query>
              </tbody>
            </Table>
            
          </Row>
        </Grid>
      </div>
      )}}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default VmItem;
