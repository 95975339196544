import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import logo from "../../assets/img/movti-logo.png";


const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const LOGIN = gql`
  query login($email: String!, $password: String!, $code: String!){
    login(email:$email, password:$password, code:$code){
      id
      token
      error_login
      error_login_msg
    }
  }
`;

class Login extends Component {
  
  state = {
    two_factor_authentication: false,
    code: "",
    email: null,
    password: null,
    msg: null,
    msg_type: "info"
  }


  login = async () => {
    const user = (await account_client.query({
      variables: {email: this.state.email, password:this.state.password, code:this.state.code},
      query: LOGIN,
      fetchPolicy: 'no-cache'
    })).data.login;
    if(user.token !== null) {
      document.cookie = "token_user="+user.token;// Grava o coookie
      this.setState({
        msg_type: "info",
        msg: "Login feito com sucesso, aguarde, você será redirecionado."
      });
      window.location.replace('/');
    } else {
      if(user.error_login === "2FA") { // Two-factor authentication 
        this.setState({
          two_factor_authentication: true,
          msg_type: "warning",
          msg: user.error_login_msg
        });
      }
      else {
        this.setState({
          msg_type: "warning",
          msg: "Verifique seu e-mail e senha, e tente novamente."
        });
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.login();
  }

  //onChange_email = email => this.setState({ email })

  onChange_email = (event) => {
    this.setState({email: event.target.value});
  }
  onChange_password = (event) => {
      this.setState({password: event.target.value});
  }
  onChange_code = (event) => {
    this.setState({code: event.target.value});
  }


  render() {

    return (

      
    <div>
      <div className="content content-login">
      <Grid fluid>

      <Row>
        
        <Col md={3}>
        </Col>
        <Col md={6}>
        <div className="login-box">
              <img className="logo" src={logo} />
              <h1>Fazer login</h1>
              <p className="text-center">Prosseguir para o Movti Cloud Platform</p>
                <form 
                onSubmit={e => {
                  this.handleSubmit(e);
                  e.preventDefault();
                }}
                >
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          onChange:this.onChange_email,
                          name: "email",
                          label: "E-mail",
                          type: "email",
                          bsClass: "form-control",
                          placeholder: "E-mail",
                          defaultValue: ""
                        }
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      proprieties={[
                        {
                          onChange:this.onChange_password,
                          name: "password",
                          label: "Senha",
                          type: "password",
                          bsClass: "form-control",
                          placeholder: "Senha",
                          defaultValue: ""
                        }
                      ]}
                    />
                    {
                      this.state.two_factor_authentication && 
                        <FormInputs
                          ncols={["col-md-12"]}
                          proprieties={[
                            {
                              onChange:this.onChange_code,
                              name: "code",
                              label: "Código enviado por e-mail",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "Código enviado por e-mail",
                              defaultValue: ""
                            }
                          ]}
                        />
                    }


                    <Button className="btn-right btn-fill btn btn-info pull-right" type="submit">
                      Próximo
                    </Button>


                    <Row>
                    {
                        this.state.msg &&
                        <Col md={12}>
                          <Alert bsStyle={this.state.msg_type}>
                            {this.state.msg}<br />
                            <a href="/#/recuperar-senha">Recuperar Senha</a>
                          </Alert>
                        </Col>
                      }
                    </Row>
                     
                    <div className="clear margin-top"></div>
                      {
                        <div className="text-center">
                          <a href="/#/registro">Criar uma conta</a>
                        </div>
                      }
                    
              </form>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
      </div>
    );
  }
}

export default Login;
