import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

const restLink_product = new HttpLink({ 
  uri: "/api/v1/cloud/",
  credentials: 'include'
 });
const product_client = new ApolloClient({
  link: restLink_product,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_PRODUCT = gql`
  query getProducts($search: String!, $limit: Int!, $page: Int!, $order: String!, $orderby: String!){
    product_collection(search: $search, limit: $limit, page: $page, order: $order, orderby: $orderby){
      products{
        id, title, cost_format, is_default
      }
    }
  }
`;

const COUNT_PRODUCTS = gql`query countProducts($search: String!){
  product_count(search:$search){
    count
  }
}
`;

const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: Int!){
    deleteProduct(id:$id) {
      id
    }
  }
`;


class ProductList extends Component {

  state = {
    search: '',
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC"
  }

  toggleOrder = (event) => {
    this.setState({
      order: (this.state.order == 'DESC'? 'ASC' : 'DESC')
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value
    });

  }

  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to={'/Product-new/'}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                Novo Produto 
              </NavLink>
              <Card
                title={"Produtos"}
                product="Gerenciar Produtos"
                ctTableFullWidth
                ctTableResponsive
                content={

                  <Table striped hover>
                    <thead>
                      <tr>
                      <th colSpan={9}>
                            <form 
                            onSubmit={e => {
                              this.handleSubmit(e);
                              e.preventDefault();
                            }}
                            >
                              <Row>
                                  <Col md={6} lg={3}>
                                      <input  type="text" name="search" className="form-control" />
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Buscar
                                    </Button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6} lg={1}>
                                      <select name="orderby" className="form-control">
                                        <option value="id">ID</option>
                                        <option value="titulo">Nome do Produto</option>
                                      </select>
                                  </Col>
                                  <Col md={6} lg={2}>
                                      <select name="order" className="form-control">
                                        <option value="DESC">Decrescente</option>
                                        <option value="ASC" selected={this.state.order == "ASC"}>Crescente</option>
                                      </select>
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Ordenar
                                    </Button>
                                  </Col>
                                </Row>
                            </form>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nome do Produto</th>
                        <th>Valor</th>
                        <th></th>
                        
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={product_client}
                          query={GET_PRODUCT}
                          variables={{
                            search: this.state.search, 
                            limit: this.state.limit, 
                            page: this.state.page, 
                            order: this.state.order, 
                            orderby: this.state.orderby
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.product_collection) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                              return data.product_collection.products.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>R$ {item.cost_format}</td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('Product-edit',item.id)}>Editar</a>
                                </td>
                                <td>
                                  {
                                    !item.is_default &&
                                    <Button bsStyle="danger" onClick={() => {
                                          var r = confirmAlert({
                                          title: '',
                                          message: "Tem certeza que deseja excluir "+item.title,
                                          buttons: [
                                            {
                                              label: 'Sim',
                                              onClick: () => {
                                                // Delete item
                                                product_client.mutate({
                                                  mutation: DELETE_PRODUCT,
                                                  variables: {
                                                    id:item.id
                                                  }
                                                }).then(
                                                result => {
                                                    window.location.reload();
                                                });
                                              }
                                            },
                                            {
                                              label: 'Não'
                                            }
                                          ]
                                        });
                                      
                                    }}>
                                    Excluir
                                  </Button>
                                }
                                
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                        <Query
                          client={product_client}
                          query={COUNT_PRODUCTS}
                          variables={{
                            search: this.state.search
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;

                              var pagination_buttons = [];
                              var total_pages = data.product_count.count/this.state.limit;
                              for(var page_num = 1; Math.ceil(total_pages) >= page_num; page_num++){
                                pagination_buttons.push(<spam><spam page_num={page_num} className={"btn_page "+(this.state.page==page_num ? 'btn_page_active' : '')} onClick={e => {this.setState({page: e.target.getAttribute('page_num')})}} >{page_num}</spam> </spam>);
                              }
                            return (
                              <tr key={1}>
                                <td colSpan={6}>
                                  {pagination_buttons}
                                </td>
                              </tr>
                            );
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ProductList;
