import React from "react";
//import ReactDOM from "react-dom";

import { HashRouter, Route, Switch } from "react-router-dom";

import indexRoutes from "./routes/index.jsx";

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { render } from "react-dom";
import gql from "graphql-tag";

import getCookie from "./components/Cookie/Cookie.jsx";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/pluz.css";


const account_client = new ApolloClient({
  uri: "/api/v1/account/"
});


function go_to_login() {
  account_client.query({
      query: gql`{
        account_auth{
            login_url
        }
      }`
  })
  .then(
    result => {
      window.location.replace(result.data.account_auth.login_url);
      if(result.data.account_auth.login_url == "/#/login" || result.data.account_auth.login_url == "/#/registro" || result.data.account_auth.login_url == "/#/recuperar-senha") {
        window.location.reload();
      }
    }
  );
}


function go_to_complete_register() {
  window.location.replace("/#/registro-passo-2");
  window.location.reload();
}

function go_to_confirm_account() {
    window.location.replace("/#/registro-passo-4");
    window.location.reload();
}

const auth_uri = "#/auth/";

//if(window.location.hash.indexOf(bot_uri) === 0) {
//  const token_user = window.location.hash.replace(auth_uri, "");
//  document.cookie = "token_user="+token_user;// Grava o coookie
//} else 
if(window.location.hash.indexOf(auth_uri) === 0){// Verifica se está na página de auth
  const token_user = window.location.hash.replace(auth_uri, "");
  document.cookie = "token_user="+token_user;// Grava o coookie
  try {
      if(window.self !== window.top){
        window.location.replace(window.location.protocol+"//"+window.location.host+"/#/pluzbot/");// Envia para o both
      } else {
        window.location.replace(window.location.protocol+"//"+window.location.host);// Envia para raiz
      }
  } catch (e) {
    window.location.replace(window.location.protocol+"//"+window.location.host);// Envia para raiz
  }
  
}
  
const token_user = getCookie("token_user");

if(window.location.hash.indexOf("#/login") === 0 || window.location.hash.indexOf("#/registro") === 0 || window.location.hash.indexOf("#/recuperar-senha") === 0){// Verifica se está na página de login
  const App = () => (
        <ApolloProvider client={account_client}>
          <HashRouter>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return <Route to={prop.path} component={prop.component} key={key} />;
              })}
            </Switch>
          </HashRouter>
        </ApolloProvider>
  );
  render(<App />, document.getElementById("root"));
}
else if(token_user) {

  account_client.query({
      variables:{token:token_user},
      query: gql`
      query get_user($token: String!) {
        get_user_by_token(token: $token){
            id
            account {
              id
              require_complete_registration
              require_confirm_account
            }
        }
    }`
  })
  .then(
    result => {
      if(result.data.get_user_by_token) {

        if(result.data.get_user_by_token.account.require_complete_registration == 1) {
          go_to_complete_register();
        }
        else if(result.data.get_user_by_token.account.require_confirm_account == 1) {
          go_to_confirm_account();
        }
        else {
          const App = () => (
            <ApolloProvider client={account_client}>
              <HashRouter>
                <Switch>
                  {indexRoutes.map((prop, key) => {
                    return <Route to={prop.path} component={prop.component} key={key} />;
                  })}
                </Switch>
              </HashRouter>
            </ApolloProvider>
          );
          render(<App />, document.getElementById("root"));
        }
        
      } else {
        go_to_login();
      }
    }
  );
} else {
  go_to_login();
}

